import React, { useState, useEffect } from "react";
import "./TwinkleAnimation.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Accessory, AccessoryState } from "../../../data/wall/Walls";
import { convertHSVToHEX, convertHEXToHSB } from "../../../utils/ColorUtil";
import Typography from "../../common/Typography";
import ColorPicker from "../../colorpicker/ColorPicker";
import Button from "../../common/Button";

export interface Color {
  hue: number;
  saturation: number;
  brightness: number;
}

interface TwinkleAnimationProps {
  accessories: Accessory[];
  onUpdate(animation: AccessoryState): void;
}

type Props = WithTranslation & TwinkleAnimationProps;

const TwinkleAnimation: React.FC<Props> = (props) => {
  const { accessories, onUpdate, t } = props;

  const [color, setColor] = useState<Color>({
    hue: 58,
    saturation: 1,
    brightness: 1,
  });
  const [speed, setSpeed] = useState<number>(4);
  const [density, setDensity] = useState<number>(60);

  // Initial value setup (if animation values already set for the accessory)
  useEffect(() => {
    if (
      accessories[0].state?.media?.config?.animationColors &&
      accessories[0].state?.media?.config?.animationColors.length > 0
    ) {
      var colorInput = convertHEXToHSB(
        accessories[0].state.media?.config.animationColors[0]
      );
      console.log(
        "Setting initial color for animation: (%s, %s, %s)",
        colorInput.h,
        colorInput.s,
        colorInput.b
      );
      handleColorChange(colorInput.h!, colorInput.s, colorInput.b);
    }
    if (accessories[0].state?.media?.config?.animationSpeed) {
      console.log(
        "Setting initial speed for animation: %s",
        accessories[0].state?.media?.config?.animationSpeed
      );
      setSpeed(accessories[0].state?.media?.config?.animationSpeed);
    }
    if (accessories[0].state?.media?.config?.animationDensity) {
      console.log(
        "Setting initial intensity for animation: %s",
        accessories[0].state?.media?.config?.animationDensity
      );
      setDensity(accessories[0].state?.media?.config?.animationDensity);

      //Update the config with initial values
      updateConfig();
    }
  }, [accessories]);

  // Update the config whenever one of the elements in the animation changes
  useEffect(() => {
    updateConfig();
  }, [color, density, speed]);

  function handleColorChange(
    hue: number,
    saturation: number,
    brightness: number
  ) {
    setColor({
      ...color,
      hue: hue,
      saturation: saturation,
      brightness: brightness,
    });
  }

  const handleSpeedChange = (_speed: number) => {
    setSpeed(_speed);
  };

  const handleDensityChange = (_density: number) => {
    setDensity(_density);
  };

  const updateConfig = () => {
    const newConfig = {
      status: "ON",
      media: {
        type: "ANIMATION_TWINKLE",
        config: {
          animationDensity: density,
          animationSpeed: speed,
          brightness: color.brightness,
          animationColors: [
            convertHSVToHEX(color.hue, color.saturation, color.brightness),
          ],
        },
      },
    };
    onUpdate(newConfig);
  };

  return (
    <div id="twinkleAnimation" className="twinkleContainer">
      <div id="colorAnimation">
        <div className="colorHeading">
          <Typography variant="heading1" color="white96">
            {t("animation-color")}
          </Typography>
        </div>
        <div className="colorPicker">
          <ColorPicker
            color={color}
            colorChangeCallback={handleColorChange}
            style="full"
          />
        </div>
      </div>
      <div id="animationSpeed">
        <div className="speedHeading">
          <Typography variant="heading1" color="white96">
            {t("animation-speed")}
          </Typography>
        </div>
        <div className="speedSelector">
          <Button
            color="elevation01"
            className={`speedButton ${speed === 2 ? "activeButton" : ""}`}
            onClick={() => handleSpeedChange(2)}
          >
            <div className="speed">
              <Typography
                variant="body1"
                color="white96"
                className={`${speed === 2 ? "activeButtonTxt" : "none"}`}
              >
                {t("animation-speed-slow")}
              </Typography>
            </div>
          </Button>
          <Button
            color="elevation01"
            className={`speedButton ${speed === 4 ? "activeButton" : ""}`}
            onClick={() => handleSpeedChange(4)}
          >
            <div className="speed">
              <Typography
                variant="body1"
                color="white96"
                className={`${speed === 4 ? "activeButtonTxt" : "none"}`}
              >
                {t("animation-speed-medium")}
              </Typography>
            </div>
          </Button>
          <Button
            color="elevation01"
            className={`speedButton ${speed === 8 ? "activeButton" : ""}`}
            onClick={() => handleSpeedChange(8)}
          >
            <div className="speed">
              <Typography
                variant="body1"
                color="white96"
                className={`${speed === 8 ? "activeButtonTxt" : "none"}`}
              >
                {t("animation-speed-fast")}
              </Typography>
            </div>
          </Button>
        </div>
      </div>
      <div id="animationIntensity">
        <div className="intensityHeading">
          <Typography variant="heading1" color="white96">
            {t("animation-intensity")}
          </Typography>
        </div>
        <div className="intensitySelector">
          <Button
            color="elevation01"
            className={`intensityButton ${
              density === 40 ? "activeButton" : ""
            }`}
            onClick={() => handleDensityChange(40)}
          >
            <div className="intensity">
              <Typography
                variant="body1"
                color="white96"
                className={`${density === 40 ? "activeButtonTxt" : "none"}`}
              >
                {t("animation-intensity-low")}
              </Typography>
            </div>
          </Button>
          <Button
            color="elevation01"
            className={`intensityButton ${
              density === 60 ? "activeButton" : ""
            }`}
            onClick={() => handleDensityChange(60)}
          >
            <div className="intensity">
              <Typography
                variant="body1"
                color="white96"
                className={`${density === 60 ? "activeButtonTxt" : "none"}`}
              >
                {t("animation-intensity-medium")}
              </Typography>
            </div>
          </Button>
          <Button
            color="elevation01"
            className={`intensityButton ${
              density === 100 ? "activeButton" : ""
            }`}
            onClick={() => handleDensityChange(100)}
          >
            <div className="intensity">
              <Typography
                variant="body1"
                color="white96"
                className={`${density === 100 ? "activeButtonTxt" : "none"}`}
              >
                {t("animation-intensity-high")}
              </Typography>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(TwinkleAnimation);
