import React, { useState, useEffect } from "react";
import "./WebsiteStream.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Accessory, AccessoryState } from "../../../data/wall/Walls";
import { convertHSVToHEX } from "../../../utils/ColorUtil";
import Typography from "../../common/Typography";
import { IonButton, IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { add } from "ionicons/icons";
import websiteLibrary from "../../../assets/images/website_library.svg";
import { close } from "ionicons/icons";
import Alert from "../../common/Alert";

export interface Color {
  hue: number;
  saturation: number;
  brightness: number;
}

interface WebsiteStreamProps {
  accessories: Accessory[];
  onUpdate(media: AccessoryState): void;
}

type Props = WithTranslation & WebsiteStreamProps;

const WebsiteStream: React.FC<Props> = (props) => {
  const { accessories, onUpdate, t } = props;
  const [selectedWebsite, setSelectedWebsite] = useState<any>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showWebAlert, setShowWebAlert] = useState(false);
  const [erroMessage, setErroMessage] = useState("");
  //
  const [streamImageUri, setStreamImageUri] = useState<string>();

  // Initial value setup (if stream values already set for the accessory)
  useEffect(() => {
    if (accessories[0].state?.media?.type === "WEBSITE") {
      setStreamImageUri(accessories[0].state?.media?.config?.uri);
    }
    //Update the config with initial values
    updateConfig();
  }, [accessories]);

  // Update the config whenever one of the elements in the stream changes
  useEffect(() => {
    setStreamImageUri(selectedWebsite);
    updateConfig();
  }, [selectedWebsite]);

  const updateConfig = () => {
    //if (selectedWebsite) setStreamImageUri(selectedWebsite);
    const newConfig = {
      status: selectedWebsite ? "ON" : "OFF",
      media: {
        type: selectedWebsite ? "WEBSITE" : "OFF",
        config: {
          uri: streamImageUri ? streamImageUri : selectedWebsite,
        },
      },
    };
    onUpdate(newConfig);
  };

  const handleSelectWebsite = () => {
    setErroMessage("");
    setShowWebAlert(true);
  };

  const validateURL = (strUrl: string) => {
    /* var pattern = new RegExp(
      "^(?:(?:(?:https?|ftp):)?//)(?:S+(?::S*)?@)?(?:(?!(?:10|127)(?:.d{1,3}){3})(?!(?:169.254|192.168)(?:.d{1,3}){2})(?!172.(?:1[6-9]|2d|3[0-1])(?:.d{1,3}){2})(?:[1-9]d?|1dd|2[01]d|22[0-3])(?:.(?:1?d{1,2}|2[0-4]d|25[0-5])){2}(?:.(?:[1-9]d?|1dd|2[0-4]d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:.(?:[a-z\u00a1-\uffff]{2,})))(?::d{2,5})?(?:[/?#]S*)?$",
      "i"
    );
    return !!pattern.test(strUrl); */
    var regexQuery =
      "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    var url = new RegExp(regexQuery, "i");
    return url.test(strUrl);
  };

  const handleWebsiteUrl = async (selectedMedia: any) => {
    if (validateURL(selectedMedia.text_name)) {
      setSelectedWebsite(selectedMedia.text_name);
      setErroMessage("");
    } else {
      setSelectedWebsite(null);
      setErroMessage(t("wrong-website-url"));
    }

    setShowWebAlert(false);
  };

  const handleRemoveStream = () => {
    setSelectedWebsite(null);
  };

  return (
    <div id="webStreamMedia" className="streamContainer">
      {erroMessage !== "" ? (
        <div className="websiteError">
          <Typography variant="body2" color="critical">
            {erroMessage}
          </Typography>
        </div>
      ) : (
        <div></div>
      )}
      <div className="streamSelectorBox">
        {selectedWebsite ? (
          <div className="viewStreamContainer">
            <div className="closeButtonContainer">
              <IonFab
                onClick={() => setShowAlert(true)}
                vertical="top"
                horizontal="end"
              >
                <IonFabButton size="small">
                  <IonIcon icon={close} />
                </IonFabButton>
              </IonFab>
            </div>
            <div className="streamIconContainer">
              <IonButton
                color="transparent"
                className="createStream addStreamMarkPosition ion-no-padding"
                onClick={handleSelectWebsite}
              >
                <img
                  src={websiteLibrary}
                  alt="website"
                  className="streamImage"
                ></img>
              </IonButton>
            </div>
            <div className="streamMetaInfo">
              <div className="streamMetaName">
                <Typography
                  variant="body1"
                  color="white96"
                  className="streamName"
                >
                  {selectedWebsite}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className="addStreamContainer">
            <div className="addMark">
              <IonButton
                color="transparent"
                className="createStream addStreamMarkPosition ion-no-padding"
                onClick={handleSelectWebsite}
              >
                <IonIcon icon={add} className="addStreamIcon" />
              </IonButton>
            </div>
            <div className="addStreamText">
              <Typography
                variant="body2"
                color="white64"
                className="addWebsiteText"
              >
                {t("add-website")}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <Alert
        displayAlert={showWebAlert}
        dismissAlert={setShowWebAlert}
        actionHandler={handleWebsiteUrl}
        actionLabel="Done"
        alertHeader={t("website-url-header")}
        alertMessage={t("website-url-message")}
        showInput={true}
      ></Alert>
      <Alert
        displayAlert={showAlert}
        dismissAlert={setShowAlert}
        actionHandler={handleRemoveStream}
        actionLabel="Remove"
        alertHeader={t("remove-stream-website")}
        alertMessage={t("remove-stream-website-alert")}
      ></Alert>
    </div>
  );
};

export default withTranslation()(WebsiteStream);
