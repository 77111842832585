import {
  GET_SCENES_REQUEST,
  GET_SCENES_FAILURE,
  GET_SCENES_SUCCESS,
  GET_SCENE_REQUEST,
  GET_SCENE_FAILURE,
  GET_SCENE_SUCCESS,
  CONTROL_SCENE_REQUEST,
  CONTROL_SCENE_FAILURE,
  CONTROL_SCENE_SUCCESS,
  CREATE_SCENE_REQUEST,
  CREATE_SCENE_FAILURE,
  CREATE_SCENE_SUCCESS,
  DELETE_SCENE_REQUEST,
  DELETE_SCENE_FAILURE,
  DELETE_SCENE_SUCCESS,
  UPDATE_SCENE_REQUEST,
  UPDATE_SCENE_FAILURE,
  UPDATE_SCENE_SUCCESS,
} from "./SceneActions";
import { Config } from "../wall/Walls";

export interface AccessoryType {
  id: number;
  name: string;
  image_url: string;
  category: string;
}

export interface SceneAccessoryConfig {
  scene_id?: number;
  accessory_id: number;
  group_id?: string;
  id?: number;
  accessory_type: string;
  config: Config;
}

export interface SceneAutomationSchedule {
  id?: number;
  action: string;
  active: boolean;
  cron: string;
  last_execution?: string;
  scene_id?: number;
}

/*
Following interface is used for getting the scenes
It does not return just cron expression but other properties as well
*/
export interface Scene {
  id: number;
  name: string;
  customer_id: number;
  scene_accessories_config: SceneAccessoryConfig[];
  scene_schedules?: SceneAutomationSchedule[];
}

/*
Following interface is used for saving or updating the scene
The scene_schedules property accepts the cron expression as a string array
*/
export interface SceneReduced {
  id: number;
  name: string;
  customer_id: number;
  scene_accessories_config: SceneAccessoryConfig[];
  scene_schedules?: string[];
}

interface State {
  loading: boolean;
  items: Scene[];
  invalid: { [sceneId: number]: boolean };
}

const initialStateScenes: State = {
  loading: false, // marks that the scenes are currently loading
  items: [],
  invalid: {},
};

export const scenes = (state: State = initialStateScenes, action: any) => {
  switch (action.type) {
    case GET_SCENES_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GET_SCENES_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GET_SCENES_SUCCESS:
      var scenes: Scene[] = action.response.data;
      // enhance devices with UI state
      return Object.assign({}, state, {
        items: scenes,
        loading: false,
        invalid: {},
      });
    case GET_SCENE_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GET_SCENE_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GET_SCENE_SUCCESS:
      var newScene: Scene = action.response.data;
      // replace existing scene if possible
      return Object.assign({}, state, {
        items: state.items.map((s) => {
          if (newScene.id === s.id) {
            return newScene;
          }
          return s;
        }),
        invalid: { [newScene.id]: false },
        loading: false,
      });
    case CONTROL_SCENE_REQUEST:
      return Object.assign({}, state, { loading: true });
    case CONTROL_SCENE_FAILURE:
      return Object.assign({}, state, { loading: false });
    case CONTROL_SCENE_SUCCESS:
      const sceneID = action.sceneId;
      return Object.assign({}, state, {
        invalid: Object.assign({}, state.invalid, { [sceneID]: true }),
        loading: false,
      });
    case CREATE_SCENE_REQUEST:
      return Object.assign({}, state, { loading: true });
    case CREATE_SCENE_FAILURE:
      return Object.assign({}, state, { loading: false });
    case CREATE_SCENE_SUCCESS:
      var createdScene: Scene = action.response.data;
      // replace existing scene if possible
      return Object.assign({}, state, {
        items: [...state.items, createdScene],
        invalid: { [createdScene.id]: false },
        loading: false,
      });
    case DELETE_SCENE_REQUEST:
      return Object.assign({}, state, { loading: true });
    case DELETE_SCENE_FAILURE:
      return Object.assign({}, state, { loading: false });
    case DELETE_SCENE_SUCCESS:
      const sceneId = action.sceneId;
      return Object.assign({}, state, {
        items: state.items.filter((s) => {
          if (sceneId !== s.id) {
            return s;
          } else {
            return false;
          }
        }),
        loading: false,
      });
    case UPDATE_SCENE_REQUEST:
      return Object.assign({}, state, { loading: true });
    case UPDATE_SCENE_FAILURE:
      return Object.assign({}, state, { loading: false });
    case UPDATE_SCENE_SUCCESS:
      var updatedScene: Scene = action.response.data;
      // replace existing scene if possible
      return Object.assign({}, state, {
        items: state.items.map((s) => {
          if (updatedScene.id === s.id) {
            return updatedScene;
          }
          return s;
        }),
        invalid: { [updatedScene.id]: false },
        loading: false,
      });
    default:
      return state;
  }
};
