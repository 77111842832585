import React, { useState, useEffect } from "react";
import "./CreateEditScene.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonContent,
  IonPage,
} from "@ionic/react";
import Toolbar, { ToolbarContents } from "../../components/common/Toolbar";
import Button from "../../components/common/Button";
import { Wall, Accessory } from "../../data/wall/Walls";
import {
  Scene,
  SceneReduced,
  SceneAccessoryConfig,
} from "../../data/scene/Scenes";
import { connect } from "react-redux";
import { Config } from "../../data/wall/Walls";
import CreateSceneAutomation from "../../components/createscene/CreateSceneAutomation";
import CreateSceneAccessories from "../../components/createscene/CreateSceneAccessories";
import CreateSceneInput from "../../components/createscene/CreateSceneInput";
import Alert from "../../components/common/Alert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  createScene,
  updateScene,
  controlSceneAndUpdateWall,
  deleteScene,
} from "../../data/scene/SceneActions";
import Spinner from "../../components/common/Spinner";
interface SceneViewProps
  extends RouteComponentProps<{ id: string; wall_id: string }> {}

const mapStateToProps = (state: any) => ({
  walls: state.walls.items,
  scenes: state.scenes.items,
  scenesLoading: state.scenes.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  create_scene: (sceneReduced: SceneReduced, wallId: number) =>
    dispatch(createScene(sceneReduced, wallId)),
  update_scene: (sceneReduced: SceneReduced, wallId: number) =>
    dispatch(updateScene(sceneReduced, wallId)),
  control_scene: (id: number, wallId: number) =>
    dispatch(controlSceneAndUpdateWall(id, wallId)),
  delete_scene: (id: number) => dispatch(deleteScene(id)),
});

type Props = WithTranslation &
  SceneViewProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const CreateEditScene: React.FC<Props> = (props) => {
  const {
    walls,
    scenes,
    scenesLoading,
    create_scene,
    update_scene,
    control_scene,
    delete_scene,
    t,
    match,
  } = props;
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [cronExpression, setCronExpression] = useState("");
  const [newSceneName, setNewSceneName] = useState("");
  const [filteredScenes, setFilteredScenes] = useState([]);
  const [accessories, setAccessories] = useState<Accessory[]>([]);
  const history = useHistory();

  /*
    The following piece is getting the wall from the list of available walls.
    This is needed because to show the accessories of the wall on the create/edit
    page. This wall object is needed in the following 'newSceneState' object
  */
  var wall = walls.find(
    (wall: Wall) => String(wall.id) === match.params.wall_id
  );

  /*
    Initialize the scene. If in edit mode, the scene gets overwritten with the 
    scene in edit in the initialization useEffect
  */
  const newScenAccessoryConfig: SceneAccessoryConfig[] = [];
  const newSceneState = {
    id: 0,
    name: "",
    customer_id: wall?.customer_id,
    scene_accessories_config: newScenAccessoryConfig,
  };
  const [scene, setScene] = useState<Scene>(newSceneState);

  /*
    Inititalization of dialog by loading/controlling the scene (if in edit mode)
  */
  useEffect(() => {
    if (!isInitialized) {
      console.log("Initializing scene");

      // If in edit mode, id is set
      if (isEditMode()) {
        let scene = scenes.find((s: Scene) => String(s.id) === match.params.id);
        if (scene) {
          control_scene(Number(match.params.id), Number(match.params.wall_id));
          setScene(scene);
          setNewSceneName(scene.name);
          if (scene.scene_schedules && scene.scene_schedules.length > 0) {
            setCronExpression(scene.scene_schedules[0].cron);
          }
        } else {
          console.log("No scene with this id found for the user.");
        }
      }
      getScenesAssociatedWithThisWall();
      setIsInitialized(true);
    }
  }, [match.params]);

  useEffect(() => {
    console.log("Wall and accessories updated");

    // If in edit mode, id is set
    if (isEditMode()) {
      let scene = scenes.find((s: Scene) => String(s.id) === match.params.id);
      if (scene) {
        setAccessoriessWithreplacedGroupIds(scene, wall?.accessories);
      }
    } else {
      // In create mode just copy the accesssories of the wall
      setAccessories(JSON.parse(JSON.stringify(wall?.accessories)));
    }
  }, [wall]);

  /*
    Checks if the component is in edit mode
  */
  const isEditMode = () => {
    if (match.params.id) return true;
    return false;
  };

  /*
    Replaces the group_ids of all accessories with the group_ids defined in the scene
  */
  const setAccessoriessWithreplacedGroupIds = (
    scene: Scene,
    accessories: Accessory[]
  ) => {
    let newAccessories = JSON.parse(JSON.stringify(accessories));
    for (let accessory of newAccessories) {
      let sceneAccessoryConfig = scene.scene_accessories_config.find(
        (config) => config.accessory_id === accessory.id
      );
      if (sceneAccessoryConfig)
        accessory.group_id = sceneAccessoryConfig.group_id;
    }
    setAccessories(newAccessories);
  };

  /*
    We need to get the scenes associated with this wall
    We need it to check if user is trying to create/edit
    a scene with same name. The filteredScenes will be
    used in checkDuplicateName()
  */
  const getScenesAssociatedWithThisWall = () => {
    if (scenes && scenes.length > 0) {
      let wallAccessoryIds: number[] = [];
      if (accessories) {
        accessories.map((acc: Accessory) => {
          wallAccessoryIds.push(acc.id);
          return acc;
        });
      }
      //To show scenes associated with this wall only
      setFilteredScenes(
        scenes.filter((scene: Scene) => {
          let matchFound = false;
          scene.scene_accessories_config?.forEach(
            (sac: SceneAccessoryConfig) => {
              if (wallAccessoryIds.includes(+sac.accessory_id)) {
                matchFound = true;
              }
            }
          );
          return matchFound;
        })
      );
    }
  };

  /*
    Handling a change in the scene name input field
  */
  const handleSceneNameChange = (name: string) => {
    if (name && name.trim().length > 0) {
      setNewSceneName(name);
    }
    if (name.trim().length > 0) {
      setError("");
    }
  };

  /*
    Get the updated value from accessories and set it on scene accessory configs
  */
  const getUpdatedSceneAccessoryConfig = () => {
    let color: Config = { hue: 58, saturation: 1, brightness: 0 }; // Default predefined color
    let sceneAccessoryConfig: SceneAccessoryConfig[] = [];

    accessories.map((acc: any) => {
      let groupId = undefined;
      if (acc.group_id) groupId = String(acc.group_id);
      return sceneAccessoryConfig.push({
        accessory_id: acc.id,
        id: acc.id,
        group_id: groupId,
        accessory_type: acc.state?.media?.type
          ? String(acc.state?.media?.type)
          : acc.type.category === "STREAM"
          ? "OFF"
          : "COLOR",
        config: acc.state?.media?.config
          ? acc.state?.media?.config
          : acc.type.category === "STREAM"
          ? { uri: acc.type.image_url }
          : color,
      });
    });
    return sceneAccessoryConfig;
  };

  //Check if the name of new scene or modified name of existing scene is same as current available scenes of the wall in context
  const checkDuplicateName = (checkSceneName: string) => {
    const duplicate_scene = filteredScenes.find(
      (scene: Scene) =>
        scene.name.toLowerCase() === checkSceneName.toLowerCase()
    );
    return duplicate_scene ? true : false;
  };

  // Save the new scene or update the current scene
  const handleSaveNewScene = () => {
    if (newSceneName.trim().length === 0) {
      setError("provide-scene-name");
    } else if (newSceneName.trim().length > 45) {
      //If name is more than 45 then it will get truncated in database so this check is made here
      setError("scene-name-length");
    } else if (scene.id === 0 && checkDuplicateName(newSceneName.trim())) {
      setError("duplicate-scene-name");
    } else {
      scene.scene_accessories_config = getUpdatedSceneAccessoryConfig();
      let sceneReduced = createSceneReduced(
        scene,
        newSceneName,
        cronExpression
      );

      //Create new scene or update existing scene
      if (isEditMode()) {
        update_scene(sceneReduced, wall.id);
      } else {
        create_scene(sceneReduced, wall.id);
      }
      history.goBack();
    }
  };

  /*
    Creates a new SceneReduced object which is necessary for saving a scene
   */
  const createSceneReduced = (
    scene: Scene,
    sceneName: string,
    cronExpression: string
  ) => {
    let sceneReduced: SceneReduced = {
      id: scene.id,
      name: sceneName,
      customer_id: scene.customer_id,
      scene_accessories_config: scene.scene_accessories_config,
    };
    if (cronExpression.length > 0) {
      sceneReduced.scene_schedules = [cronExpression]; //Set the new scene schedule if it is available
    } else {
      sceneReduced.scene_schedules = []; //Remove the new scene schedule if it is removed from UI
    }
    return sceneReduced;
  };

  //Delete the selected scene
  const handleDeleteScene = () => {
    delete_scene(+scene.id);
    history.goBack();
  };

  // Set the cron expression for the scene
  const handleSetCronExpression = (cron_expression: string) => {
    setCronExpression(cron_expression);
  };

  //Cancel the scene edit / create operation
  const handleCancelAction = () => {
    history.goBack();
  };

  const toolbarContents: ToolbarContents = {
    left: {
      on_click: () => handleCancelAction(),
      label: t("cancel"),
      show_icon: false,
    },
    middle: {
      label: t("scene-configuration"),
      show_image: false,
    },
    right: {
      on_click: () => handleSaveNewScene(),
      label: t("done"),
      show_icon: false,
    },
  };

  return (
    <IonPage id="createScene">
      <IonHeader translucent className="ion-no-border">
        <Toolbar contents={toolbarContents} />
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          {scenesLoading ? (
            <Spinner className="createEditSceneSpinner" />
          ) : (
            <div className="createEditSceneDiv">
              <CreateSceneInput
                error={error}
                sceneName={newSceneName}
                setSceneName={handleSceneNameChange}
              />
              <CreateSceneAutomation
                scene={scene}
                onChangeAutomation={handleSetCronExpression}
              />
              <CreateSceneAccessories accessories={accessories} />
              {isEditMode() ? (
                <IonRow>
                  <IonCol className="buttonDeleteColumn">
                    <Button
                      className="buttonDelete"
                      color="transparent"
                      variant="primary"
                      onClick={() => setShowAlert(true)}
                    >
                      {t("delete-scene")}
                    </Button>
                  </IonCol>
                  <Alert
                    displayAlert={showAlert}
                    dismissAlert={setShowAlert}
                    actionHandler={handleDeleteScene}
                    actionLabel="Delete"
                    alertHeader={t("delete-scene-alert-header")}
                    alertMessage={t("delete-scene-alert-message")}
                  ></Alert>
                </IonRow>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateEditScene)
);
