import React from "react";
import {
  IonCol,
  IonCard,
  IonCardContent,
  IonRow,
  IonCardTitle,
  IonButton,
} from "@ionic/react";
import { Wall } from "../../data/wall/Walls";
import "./WallListItem.scss";
import Typography from "../../components/common/Typography";
import WallSampleImage from "../../assets/images/wallsample.png";
import BaseWallImage from "../../assets/images/basewall.png";
import { withTranslation, WithTranslation } from "react-i18next";
import Media from "react-media";

interface WallListItemProps {
  wall: Wall;
}

type Props = WithTranslation & WallListItemProps;

const WallListItem: React.FC<Props> = (props) => {
  const { wall, t } = props;
  const numberOfAccessories = wall.accessories ? wall.accessories.length : 0;
  function provideWallColorStyle(wallState: string) {
    let wallColor = wallState === "ON" ? wall.wall_color : "transparent";
    return {
      backgroundColor: wallColor,
    };
  }

  function showWallsOnMobile() {
    return (
      <IonButton
        color="transparent"
        routerLink={`/wall/${wall.id}`}
        className="wallListItemButton"
      >
        <div className="wallListItem">
          <div className="wallInfo">
            <div className="wallName">
              <Typography variant="heading2" color="white96">
                {wall.name}
              </Typography>
            </div>
            <div className="wallAccessories">
              <Typography variant="body2" color="white64">
                {numberOfAccessories} {t("accessories")}
              </Typography>
            </div>
            <div>
              {wall.state === "ON" ? (
                <div className="wallState wallStateOn">
                  <Typography
                    variant="body2"
                    className={`wallStatus ${
                      wall.partial_state ? "partialStateTxt" : "on"
                    }`}
                  >
                    {t("on")}
                  </Typography>
                </div>
              ) : wall.state === "OFF" ? (
                <div className="wallState wallStateOff">
                  <Typography variant="body2" className="wallStatus off">
                    {t("off")}
                  </Typography>
                </div>
              ) : (
                <div className="wallState wallStateOffline">
                  <Typography variant="body2" className="wallStatus offline">
                    {t("disconnected")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div id="wallImageSmallDevice">
            <img src={BaseWallImage} alt="Sample wall" />
            <div
              className="overlay"
              style={provideWallColorStyle(`${wall.state}`)}
            ></div>
          </div>
        </div>
      </IonButton>
    );
  }

  function showWallsOnDesktop() {
    return (
      <IonCol size="12" size-md="3" className="wallCardCol">
        <IonCard className="card" routerLink={`/wall/${wall.id}`}>
          <IonCardContent className="cardContent">
            <img
              src={WallSampleImage}
              alt="Sample wall"
              className="wallImage"
            />
            <IonCardTitle className="cardTitle">{wall.name}</IonCardTitle>
            <IonRow className="ion-justify-content-between">
              <Typography className="cardSubtitle">
                {numberOfAccessories} {t("accessories")}
              </Typography>

              {wall.state === "ON" ? (
                <div className="wallState wallStateOn">
                  <Typography
                    variant="body2"
                    className={`wallStatus ${
                      wall.partial_state ? "partialStateTxt" : "on"
                    }`}
                  >
                    {t("on")}
                  </Typography>
                </div>
              ) : wall.state === "OFF" ? (
                <div className="wallState wallStateOff">
                  <Typography variant="body2" className="wallStatus off">
                    {t("off")}
                  </Typography>
                </div>
              ) : (
                <div className="wallState wallStateOffline">
                  <Typography variant="body2" className="wallStatus offline">
                    {t("disconnected")}
                  </Typography>
                </div>
              )}
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonCol>
    );
  }

  return (
    <Media queries={{ small: "(max-width: 599px)" }}>
      {(matches) =>
        matches.small ? showWallsOnMobile() : showWallsOnDesktop()
      }
    </Media>
  );
};

export default withTranslation()(WallListItem);
