import React, { useState, useEffect } from "react";
import "./SceneSchedule.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { SceneAutomationSchedule } from "../../data/scene/Scenes";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonHeader,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonDatetime,
} from "@ionic/react";
import Typography from "../common/Typography";
import { weekdays, cronToTime, toCron } from "../../utils/DateTime";
import Toolbar, { ToolbarContents } from "../common/Toolbar";
import Button from "../common/Button";
interface SceneScheduleProps {
  sceneSchedule: SceneAutomationSchedule;
  onCreateOrUpdateSceneSchedule(sceneSchedule: SceneAutomationSchedule): void;
  onCancelSceneSchedule(close: boolean): void;
  onDeleteSceneSchedule(): void;
}

type Props = WithTranslation & SceneScheduleProps;

const SceneSchedule: React.FC<Props> = (props) => {
  const {
    sceneSchedule,
    onCreateOrUpdateSceneSchedule,
    onCancelSceneSchedule,
    onDeleteSceneSchedule,
    t,
  } = props;
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [selectedTime, setSelectedTime] = useState("09:00");
  const [error, setError] = useState(false);

  /*
    By default the weekdays are selected (Checked in UI)
  */
  const setWeekdays = () => {
    //By default all days are selected
    if (sceneSchedule.active) {
      weekdays.map((item: any) =>
        setCheckedItems(checkedItems.set(item.value, true))
      );
    } else {
      let tmpCheckedItems = new Map();
      weekdays.map((item, any) => {
        tmpCheckedItems.set(item.value, true);
      });
      setCheckedItems(tmpCheckedItems);
    }
  };

  /*
    If the scene has schedule, then get the cron details and
    check the previously saved days and uncheck the rest for displaying it in UI
  */
  const presetSceneSchedulDays = (sceneAutomation: SceneAutomationSchedule) => {
    let cronTime = cronToTime(sceneAutomation.cron);
    setSelectedTime(cronTime);
    let cronData = sceneAutomation.cron.split(" ");
    let cronDays = cronData[4];
    if (cronDays !== "*") {
      //set selected days as true and rest false
      let cronDaysArr = cronDays.split(",");
      let tmpCheckedItems = new Map(checkedItems);
      checkedItems.forEach((value, key) => {
        if (!cronDaysArr.includes(key)) {
          tmpCheckedItems.set(key, false);
        }
      });
      setCheckedItems(tmpCheckedItems);
    } else {
      let tmpCheckedItems = new Map();
      weekdays.map((item, any) => {
        tmpCheckedItems.set(item.value, true);
      });
      setCheckedItems(tmpCheckedItems);
    }
  };

  /*
  In the following useEffect(),
  1. First we get the weekdays and set the checkedItems map.
  By default we set all days as selected days by setting the value to True

  2. If we have scene schedule, then we extract the previously selected cron days and only set them
  and rest of the others are set unselected. This is used in UI to show the selected days.
   */
  useEffect(() => {
    setWeekdays();
    if (sceneSchedule.active) {
      presetSceneSchedulDays(sceneSchedule);
    }
  }, []);

  //Save the selected schedule
  const saveSceneSchedule = () => {
    let selectedHour = parseInt(selectedTime.split(":")[0]);
    let selectedMin = parseInt(selectedTime.split(":")[1]);
    let days = getCheckedDays();
    let cron = toCron(selectedMin, selectedHour, days);
    if (days.length > 0) {
      sceneSchedule.cron = cron;
      sceneSchedule.action = "ON";
      sceneSchedule.active = true;
      onCreateOrUpdateSceneSchedule(sceneSchedule);
    } else {
      setError(true);
    }
  };

  // Get all days which are checked as a plain array (of day keys)
  const getCheckedDays = () => {
    let daysFiltered = Array.from(checkedItems).filter(([key, value]) => {
      if (value) {
        return key;
      } else {
        return false;
      }
    });
    let days = daysFiltered.map((d) => {
      return d[0];
    });
    return days;
  };

  //If the scene schedule modal is canceled
  const cancelSceneSchedule = () => {
    onCancelSceneSchedule(false);
  };

  //invoked whenever a day selected or unselected
  const handleDaySelection = (e: any) => {
    const item = e.detail.value;
    const isChecked = e.detail.checked;
    setCheckedItems(checkedItems.set(item, isChecked));
    setError(false);
  };

  //Delete the selected scene schedule
  const deleteSceneSchedule = () => {
    onDeleteSceneSchedule();
  };

  const toolbarContents: ToolbarContents = {
    left: {
      on_click: () => cancelSceneSchedule(),
      label: t("cancel"),
      show_icon: false,
    },
    middle: {
      label: t("time-automation"),
      show_image: false,
    },
    right: {
      on_click: () => saveSceneSchedule(),
      label: t("done"),
      show_icon: false,
    },
  };

  return (
    <div id="createSceneSchedule">
      <IonHeader translucent className="ion-no-border">
        <Toolbar contents={toolbarContents} />
      </IonHeader>
      <div className="modalContent">
        <IonGrid className="ion-no-padding">
          {error ? (
            <Typography variant="caption1" color="critical">
              {t("no-day-error")}
            </Typography>
          ) : (
            <div></div>
          )}
          <IonRow className="ion-no-padding">
            <IonCol className="ion-no-padding">
              <Typography
                variant="heading2"
                color="white64"
                className="spaceTop scheduleSpaceTop scheduleSpaceLeft"
              >
                {t("schedule-when")}
              </Typography>
            </IonCol>
          </IonRow>
          <IonRow className="ion-no-padding scheduleStartTimeRow">
            <IonCol>
              <Typography
                variant="body1"
                color="white96"
                className="scheduleSpaceLeft"
              >
                {t("schedule-start-time")}
              </Typography>
            </IonCol>
            <IonCol className="ion-no-padding scheduleStartTimeCol  ion-padding-end">
              <IonDatetime
                display-format="HH:mm"
                picker-format="HH:mm"
                value={selectedTime}
                onIonChange={(e) => setSelectedTime(e.detail.value!)}
                className="ionDateTime"
              ></IonDatetime>
            </IonCol>
          </IonRow>
          <IonRow className="ion-no-padding">
            <IonCol className="ion-no-padding">
              <Typography
                variant="heading2"
                color="white64"
                className="repeatTopSpace repeatBottomSpace scheduleSpaceTop scheduleSpaceLeft"
              >
                {t("schedule-repeat")}
              </Typography>
            </IonCol>
          </IonRow>
          <IonRow className="ion-no-padding">
            <IonCol className="ion-no-padding">
              <IonList className="weekdayItemList ion-no-padding">
                {weekdays.map((item: any) => (
                  <IonItem key={item.key} className="ion-no-padding weekdayItem no-ripple">
                    <IonLabel className="weekdayLabel scheduleSpaceBottom">
                      {t(item.name)}
                    </IonLabel>
                    <IonCheckbox
                      slot="end"
                      value={item.value}
                      checked={checkedItems.get(item.value)}
                      onIonChange={(e) => handleDaySelection(e)}
                      className="weekdayCheckbox"
                    />
                  </IonItem>
                ))}
              </IonList>
            </IonCol>
          </IonRow>
          {sceneSchedule.active ? (
            <IonRow>
              <IonCol className="buttonDeleteColumn">
                <Button
                  className="buttonDelete"
                  color="transparent"
                  variant="primary"
                  onClick={() => deleteSceneSchedule()}
                >
                  {t("delete-automation")}
                </Button>
              </IonCol>
            </IonRow>
          ) : (
            <div></div>
          )}
        </IonGrid>
      </div>
    </div>
  );
};

export default withTranslation()(SceneSchedule);
