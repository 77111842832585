import React, { useState, useEffect } from "react";
import "./ColorTransitionAnimation.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Accessory, AccessoryState } from "../../../data/wall/Walls";
import { convertHSVToHEX, convertHEXToHSB } from "../../../utils/ColorUtil";
import Typography from "../../common/Typography";
import ColorPicker from "../../colorpicker/ColorPicker";
import { IonInput } from "@ionic/react";

export interface Color {
  hue: number;
  saturation: number;
  brightness: number;
}

interface ColorTransitionAnimationProps {
  accessories: Accessory[];
  onUpdate(animation: AccessoryState): void;
}

type Props = WithTranslation & ColorTransitionAnimationProps;

const ColorTransitionAnimation: React.FC<Props> = (props) => {
  const { accessories, onUpdate, t } = props;

  const [color1, setColor1] = useState<Color>({
    hue: 100,
    saturation: 1,
    brightness: 1,
  });
  const [color2, setColor2] = useState<Color>({
    hue: 285,
    saturation: 1,
    brightness: 1,
  });
  const [transitionTime, setTransitionTime] = useState<number>(2);

  // Initial value setup (if animation values already set for the accessory)
  useEffect(() => {
    if (
      accessories[0].state?.media?.config?.animationColors &&
      accessories[0].state?.media?.config?.animationColors.length > 0
    ) {
      var color1Input = convertHEXToHSB(
        accessories[0].state.media?.config.animationColors[0]
      );
      console.log(
        "Setting initial color for animation: (%s, %s, %s)",
        color1Input.h,
        color1Input.s,
        color1Input.b
      );
      handleColor1Change(color1Input.h!, color1Input.s, color1Input.b);
      if (accessories[0].state?.media?.config?.animationColors.length > 1) {
        var color2Input = convertHEXToHSB(
          accessories[0].state.media?.config.animationColors[1]
        );
        console.log(
          "Setting initial color for animation: (%s, %s, %s)",
          color2Input.h,
          color2Input.s,
          color2Input.b
        );
        handleColor2Change(color2Input.h!, color2Input.s, color2Input.b);
      }
    }
    if (accessories[0].state?.media?.config?.animationTransitionTime) {
      console.log(
        "Setting initial transition time for animation: %s",
        accessories[0].state?.media?.config?.animationTransitionTime
      );
      setTransitionTime(
        accessories[0].state?.media?.config?.animationTransitionTime
      );
    }
    //Update the config with initial values
    updateConfig();
  }, [accessories]);

  // Update the config whenever one of the elements in the animation changes
  useEffect(() => {
    updateConfig();
  }, [color1, color2, transitionTime]);

  function handleColor1Change(
    hue: number,
    saturation: number,
    brightness: number
  ) {
    setColor1({
      ...color1,
      hue: hue,
      saturation: saturation,
      brightness: brightness,
    });
  }
  function handleColor2Change(
    hue: number,
    saturation: number,
    brightness: number
  ) {
    setColor2({
      ...color2,
      hue: hue,
      saturation: saturation,
      brightness: brightness,
    });
  }

  const handleTransitionTimeChange = (transitionTime: number) => {
    setTransitionTime(transitionTime);
  };

  const updateConfig = () => {
    const newConfig = {
      status: "ON",
      media: {
        type: "ANIMATION_PULSE_COLOR",
        config: {
          animationTransitionTime: transitionTime,
          animationColors: [
            convertHSVToHEX(color1.hue, color1.saturation, color1.brightness),
            convertHSVToHEX(color2.hue, color2.saturation, color2.brightness),
          ],
        },
      },
    };
    onUpdate(newConfig);
  };

  const handleEnterKey = (e: any) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <div id="colorTransitionAnimation" className="colorTransitionContainer">
      <div id="colorTransitionAnimation">
        <div className="colorHeading">
          <Typography variant="heading1" color="white96">
            {t("animation-color-transition")}
          </Typography>
        </div>
        <div className="colorPicker">
          <ColorPicker
            color={color1}
            colorChangeCallback={handleColor1Change}
            style="full"
          />
        </div>
        <div className="colorPicker">
          <ColorPicker
            color={color2}
            colorChangeCallback={handleColor2Change}
            style="full"
          />
        </div>
      </div>
      <div id="animationDuration">
        <div className="intervalHeading">
          <Typography variant="heading1" color="white96">
            {t("animation-interval")}
          </Typography>
        </div>
        <div className="durationSelector">
          <div className="durationTitle">
            <Typography variant="body1" color="white96">
              {t("animation-duration")}
            </Typography>
          </div>
          <div className="durationNumber">
            <IonInput
              inputmode="decimal"
              type="number"
              min={"0"}
              className="durationField"
              placeholder={"2"}
              value={transitionTime}
              onIonChange={(e) => handleTransitionTimeChange(+e.detail.value!)}
              onKeyDown={(e) => handleEnterKey(e)}
              required
              pattern="\d*"
            ></IonInput>
          </div>
          <div className="durationms">
            <Typography variant="body1" color="white64">
              sec
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ColorTransitionAnimation);
