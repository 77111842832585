import React, { useState, useEffect } from "react";
import "./CreateEditStream.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { IonHeader, IonIcon, IonModal } from "@ionic/react";
import Typography from "../../common/Typography";
import Toolbar, { ToolbarContents } from "../../common/Toolbar";
import { Accessory } from "../../../data/wall/Walls";
import dummyImage from "../../../assets/images/dummy_image.svg";
import { squareOutline } from "ionicons/icons";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { connect, ConnectedProps } from "react-redux";
import { controllAccessoryAndUpdateWall } from "../../../data/wall/WallActions";
import { AccessoryState, Wall } from "../../../data/wall/Walls";
import StreamType from "./StreamTypes";
import Button from "../../common/Button";
import { StreamTypesEnum } from "./StreamTypes";
import VideoStream from "./VideoStream";
import ImageStream from "./ImageStream";
import WebsiteStream from "./WebsiteStream";
//import WebsiteStream from "./WebsiteStream";

interface CreateEditStreamProps {
  accessories: Accessory[];
  closeModal(): void;
}

const mapState = (state: any) => ({
  walls: state.walls.items,
});

const mapDispatch = (dispatch: any) => ({
  controlAccesssory: (
    wallId: number,
    accessoryIds: number[],
    state: AccessoryState
  ) => {
    dispatch(controllAccessoryAndUpdateWall(wallId, accessoryIds, state));
  },
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithTranslation & PropsFromRedux & CreateEditStreamProps;

const CreateEditStream: React.FC<Props> = (props) => {
  const { accessories, closeModal, controlAccesssory, walls, t } = props;
  const [error] = useState(false);
  const [popupTitle] = useState("");
  const [streamTypeModalOpen, setStreamTypeModalOpen] = useState(false);
  const [
    selectedStreamType,
    setSelectedStreamType,
  ] = useState<StreamTypesEnum>();
  const [stream, setStream] = useState<AccessoryState>(
    accessories[0].state!
  );

  var wall = walls.find((wall: Wall) => wall.id === accessories[0].wall_id);

  function getAccessoryIds(accessories: Accessory[]) {
    var accessoryIds: number[] = [];
    accessories.forEach((accessory: Accessory) => {
      accessoryIds.push(accessory.id);
    });
    return accessoryIds;
  }

  const handleStreamSave = () => {
    console.log("Saving stream");
    controlAccesssory(wall.id, getAccessoryIds(accessories), stream!);
    closeModal();
  };

  const handleUpdate = (stream: AccessoryState) => {
    setStream(stream);
  };

  useEffect(() => {}, []);

  const toolbarContents: ToolbarContents = {
    left: {
      on_click: () => closeModal(),
      label: t("cancel"),
      show_icon: false,
    },
    middle: {
      label: popupTitle,
      show_image: false,
    },
    right: {
      on_click: () => handleStreamSave(),
      label: t("done"),
      show_icon: false,
    },
  };

  const openStreamTypeModal = () => {
    setStreamTypeModalOpen(true);
  };

  const handleStreamTypeChange = (selected_stream_type: StreamTypesEnum) => {
    setStreamTypeModalOpen(false);
    setSelectedStreamType(selected_stream_type);
  };

  return (
    <div id="createEditStream">
      <IonHeader translucent className="ion-no-border">
        <Toolbar contents={toolbarContents} />
      </IonHeader>
      <div className="modalContent">
        <div className="container">
          <div className="errorRow">
            {error ? (
              <Typography variant="caption1" color="critical">
                {t("stream-error")}
              </Typography>
            ) : (
              <div></div>
            )}
          </div>
          <div className="streamHeader">
            <div className="streamHeaderTxt">
              <Typography variant="largeTitle" color="white96">
                {t("stream-heading")}
              </Typography>
            </div>
            <div className="streamHeaderIcon">
              {accessories[0].type.image_url ? (
                <img
                  src={accessories[0].type.image_url}
                  alt={accessories[0].name}
                />
              ) : accessories[0].type.category === "MOTOR" ? (
                <IonIcon
                  size="large"
                  icon={squareOutline}
                  className="accessoryIcon"
                />
              ) : (
                <img src={dummyImage} alt={accessories[0].name} />
              )}
            </div>
          </div>
          <Button
            className="streamTypeButton"
            color="elevation03"
            onClick={openStreamTypeModal}
          >
            <div className="streamType">
              <div className="streamTypeTxt">
                {selectedStreamType ? (
                  <Typography variant="body1" color="white96">
                    {t("stream-type-" + selectedStreamType)}
                  </Typography>
                ) : (
                  <Typography variant="body1" color="white96">
                    {t("select-stream-type")}
                  </Typography>
                )}
              </div>
              <div className="streamTypeArrowDiv">
                <ArrowForwardIos className="streamTypeArrow" />
              </div>
            </div>
          </Button>
        </div>
        {selectedStreamType ? (
          selectedStreamType === StreamTypesEnum.VIDEO ? (
            <VideoStream accessories={accessories} onUpdate={handleUpdate} />
          ) : selectedStreamType === StreamTypesEnum.IMAGE ? (
            <ImageStream
              accessories={accessories}
              onUpdate={handleUpdate}
            />) : selectedStreamType === StreamTypesEnum.WEBSITE ? (
            <WebsiteStream
              accessories={accessories}
              onUpdate={handleUpdate}
            />
          ) : (
            <div></div>
          )
        ) : (
          <div className="streamMsg">
            <Typography variant="body1" color="white32">
              {t("stream-info")}
            </Typography>
          </div>
        )}

        <IonModal
          cssClass="streamTypeModal"
          isOpen={streamTypeModalOpen}
          onDidDismiss={() => setStreamTypeModalOpen(false)}
        >
          <StreamType
            closeModal={() => setStreamTypeModalOpen(false)}
            onStreamTypeChange={handleStreamTypeChange}
          />
        </IonModal>
      </div>
    </div>
  );
};

export default withTranslation()(connector(CreateEditStream));
