import React from "react";
import "./SceneListHeader.scss";
import { IonLabel, IonRow, IonCol, IonButton } from "@ionic/react";
import Typography from "../common/Typography";
import { WithTranslation, withTranslation } from "react-i18next";

interface SceneListHeaderProps {
  sceneListSize: number;
  wallid: number;
}
type Props = WithTranslation & SceneListHeaderProps;

const SceneListHeader: React.FC<Props> = (props) => {
  const { sceneListSize, wallid, t } = props;
  return (
    <IonRow className="ion-no-padding sceneHeaderRow">
      <IonCol className="ion-no-padding sceneHeader" size="4">
        <IonLabel>
          <Typography
            variant="heading1"
            color="white96"
            className="sceneNameTitle"
          >
            {t("scene-name-title")}
          </Typography>
        </IonLabel>
      </IonCol>
      {sceneListSize === 0 ? (
        <IonCol></IonCol>
      ) : (
        <IonCol className="ion-no-padding createNewSceneTitleCol sceneHeaderRow">
          <IonLabel>
            <IonButton
              color="transparent"
              routerLink={`/wall/${wallid}/scene`}
              className="ion-no-padding createNewSceneTitleButton"
            >
              <Typography variant="body1" className="createNewSceneTitle">
                {t("create-new-scene-title")}
              </Typography>
            </IonButton>
          </IonLabel>
        </IonCol>
      )}
    </IonRow>
  );
};

export default withTranslation()(SceneListHeader);
