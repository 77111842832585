import { TFunction } from "i18next";
import { DateTime } from "luxon";

export const weekdays = [
  { name: "Monday", key: "monday", value: "MON" },
  { name: "Tuesday", key: "tuesday", value: "TUE" },
  { name: "Wednesday", key: "wednesday", value: "WED" },
  { name: "Thursday", key: "thursday", value: "THU" },
  { name: "Friday", key: "friday", value: "FRI" },
  { name: "Saturday", key: "saturday", value: "SAT" },
  { name: "Sunday", key: "sunday", value: "SUN" },
];

// Transforms a cron expression to local timezone HH:mm representation as string
export const cronToTime = (cron: String) => {
  let cronData = cron.split(" ");
  let cronHour = parseInt(cronData[1]);
  let cronMinute = parseInt(cronData[0]);

  // Get local date time from the cron (which is in UTC)
  var now = DateTime.local();
  var utcdt = DateTime.utc(now.year, now.month, now.day, cronHour, cronMinute);
  var localdt = utcdt.toLocal();
  let hour = pad(localdt.hour);
  let min = pad(localdt.minute);
  return `${hour}:${min}`;
};

export const cronToTimeAndDay = (cron: String, t: TFunction) => {
  if (cron && cron.trim().length > 0 && cron.trim() !== "* * * * *") {
    let cronData = cron.split(" ");
    let cronDays = cronData[4];
    if (cronDays === "*") {
      cronDays = t("every-day");
    } else {
      let cronDaysArr = cronDays.split(",");
      cronDaysArr.forEach((name, index) => (cronDaysArr[index] = `${t(name)}`));
      cronDays = cronDaysArr.join(",");
    }
    return `${cronToTime(cron)} ${cronDays}`;
  } else {
    return "";
  }
};

// Transforms time to a cron expression
export const toCron = (minutes: number, hours: number, days: Array<String>) => {
  var now = DateTime.local();
  var utcDT = DateTime.local(
    now.year,
    now.month,
    now.day,
    hours,
    minutes
  ).toUTC();
  var utcHours = utcDT.hour;
  var utcminutes = utcDT.minute;

  // Create the cron expression for the days
  var cronDays = "*";
  if (days.length < 7) {
    cronDays = "";
    days.forEach((d) => {
      cronDays += d + ",";
    });
    cronDays = cronDays.slice(0, -1);
  }
  return `${utcminutes} ${utcHours} * * ${cronDays}`;
};

// Padding with leading "0" to get double digit hours and minutes
function pad(n: number) {
  return n < 10 ? "0" + n : n;
}
