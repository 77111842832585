import React from "react";
import "./StreamTypes.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { IonHeader } from "@ionic/react";
import Typography from "../../common/Typography";
import Toolbar, { ToolbarContents } from "../../common/Toolbar";
import Button from "../../common/Button";
import videoLibrary from "../../../assets/images/video_library.svg";
import imageLibrary from "../../../assets/images/image_library.svg";
import websiteLibrary from "../../../assets/images/website_library.svg";

export enum StreamTypesEnum {
  VIDEO = "video",
  IMAGE = "image",
  WEBSITE = "website",
}

interface StreamTypesProps {
  onStreamTypeChange(selectedType: StreamTypesEnum): void;
  closeModal(): void;
}

type Props = WithTranslation & StreamTypesProps;

const StreamTypes: React.FC<Props> = (props) => {
  const { onStreamTypeChange, closeModal, t } = props;

  const toolbarContents: ToolbarContents = {
    left: {
      show_icon: true,
      on_click: () => closeModal(),
    },
    middle: {
      label: t("select-stream-type"),
      show_image: false,
    },
  };

  return (
    <div id="streamTypes">
      <IonHeader translucent className="ion-no-border">
        <Toolbar contents={toolbarContents} />
      </IonHeader>
      <div className="modalContent">
        <div className="streamTypeContainer">
          {Object.values(StreamTypesEnum).map((type: any) => (
            <Button
              className="streamTypeButton"
              color="elevation01"
              key={type}
              onClick={() => onStreamTypeChange(type)}
            >
              <div className="streamTypeDiv">
                <div>
                  {type === StreamTypesEnum.VIDEO ? (
                    <img
                      src={videoLibrary}
                      alt={type}
                      className="streamTypeImage videoImageFilter"
                    ></img>
                  ) : type === StreamTypesEnum.IMAGE ? (
                    <img
                      src={imageLibrary}
                      alt={type}
                      className="streamTypeImage"
                    ></img>
                  ) : type === StreamTypesEnum.WEBSITE ? (
                    <img
                      src={websiteLibrary}
                      alt={type}
                      className="streamTypeImage"
                    ></img>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="streamTypeDivTxt">
                  <Typography variant="body1" color="white64">
                    {t("stream-type-" + type)}
                  </Typography>
                </div>
              </div>
            </Button>
          ))}
        </div>
        {/* <div className="streamTypeMsg">
          <Typography variant="caption1" color="white64">
            {t("stream-type-message")}
          </Typography>
        </div> */}
      </div>
    </div>
  );
};

export default withTranslation()(StreamTypes);
