import { CALL_API } from "../../common/api";

export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";

export const getCustomer = (id: string) => ({
  [CALL_API]: {
    types: [GET_CUSTOMER_REQUEST, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILURE],
    apiName: "MagnwallAPI",
    path: "/customer/" + id,
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});
