import React from "react";
import { Accessory } from "../../data/wall/Walls";
import "./AccessoryListItemTypes.scss";
import { connect, ConnectedProps } from "react-redux";
import Typography from "../common/Typography";
import { withTranslation, WithTranslation } from "react-i18next";
import disconnectedImg from "../../assets/images/disconnected.svg";
import Tooltip from "@material-ui/core/Tooltip";

interface AccessoryListItemTypes {
  accessories: Accessory[];
}

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithTranslation & PropsFromRedux & AccessoryListItemTypes;

const AccessoryListItemTypes: React.FC<Props> = (props) => {
  const { accessories, t } = props;

  /**
   * Creates a list of images for the accessories aligned next to each other.
   * On normal screen we allow max 3 accessories images to be shown. On mobile just one.
   * @param accessories Input accessories
   */
  function createGroupedImages(accessories: Accessory[], mobile: boolean) {
    let accessoryCount = accessories.length;
    let maxDisplayedAccessories = 3;
    if (mobile) {
      maxDisplayedAccessories = 1;
    }
    let displayAccessories = accessories.slice(0, maxDisplayedAccessories);
    let anyAccDisconnecte = displayAccessories.filter(
      (acc: Accessory) => acc?.state?.status === "DISCONNECTED"
    );
    let isAnyAccDisconnected = false;
    if (anyAccDisconnecte.length > 0) isAnyAccDisconnected = true;

    let groupedImages = displayAccessories.map((accessory: Accessory) =>
      accessory.type.image_url ? (
        <div
          key={accessory.id}
          className={`imageContainer ${
            accessory?.state?.status === "DISCONNECTED"
              ? "imageContainerDisconnected"
              : ""
          }`}
        >
          <img
            key={accessory.id}
            src={accessory.type.image_url}
            alt={accessory.name}
          />
        </div>
      ) : accessory.type.category === "MOTOR" ? (
        <div
          key={accessory.id}
          className={`numberCircle ${
            accessory?.state?.status === "DISCONNECTED"
              ? "imageContainerDisconnected"
              : ""
          }`}
        >
          <Typography variant="body1" color="white96">
            M
          </Typography>
        </div>
      ) : (
        <div
          key={accessory.id}
          className={`numberCircle ${
            accessory?.state?.status === "DISCONNECTED"
              ? "imageContainerDisconnected"
              : ""
          }`}
        >
          <Typography variant="body1" color="white96">
            D
          </Typography>
        </div>
      )
    );
    if (mobile) {
      if (accessoryCount > 1) {
        // On mobile if more than 1 accessory, just show the number
        groupedImages = [
          <div
            key="1a"
            className={`numberCircle ${
              isAnyAccDisconnected
                ? "numberCircleDisconnected"
                : "numberCircleConnected"
            }`}
          >
            <Typography variant="body1" color="white96">
              {accessoryCount}
            </Typography>
          </div>,
        ];
      }
    } else {
      if (accessoryCount > 3) {
        groupedImages = groupedImages.concat(
          <div
            key="1b"
            className={`numberCircle ${
              isAnyAccDisconnected
                ? "numberCircleDisconnected"
                : "numberCircleConnected"
            }`}
          >
            <Typography variant="body1" color="white96">
              +{accessoryCount - 3}
            </Typography>
          </div>
        );
      }
    }
    if (isAnyAccDisconnected) {
      groupedImages = groupedImages.concat(
        <Tooltip
          title={`${t("disconnected")}`}
          placement="right"
          enterTouchDelay={1}
          arrow
        >
          <div key="1c" className="disconnectedImage">
            <img src={disconnectedImg} alt={t("disconnected")} />
          </div>
        </Tooltip>
      );
    }

    // reversing the elements is important to stack the elements in the right order (compare css row-reverse flexbox)
    return groupedImages.reverse();
  }

  return (
    <div id="accessoryListItemTypes">
      <div className="accessoryListItemTypesContainer">
        {createGroupedImages(accessories, false)}
      </div>
    </div>
  );
};

export default withTranslation()(connector(AccessoryListItemTypes));
