/**
 * Convert the color from Hue, Saturation and brightness to RGB
 *
 * @param {number} h
 * @param {number} s
 * @param {number} v
 *
 * @returns Object of RGB values
 */
export function convertHSVToRGB(h, s, v) {
  var r, g, b, i, f, p, q, t;
  if (arguments.length === 1) {
    s = h.s;
    v = h.v;
    h = h.h;
  }
  h = h / 360;
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
    default:
      r = v;
      g = p;
      b = q;
      break;
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}


/**
 * Convert the individual R, G, B to its corresponding HEX value
 *
 * @param {number} c
 *
 * @returns The hex value of the color component
 */
function convertColorComponentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}


/**
 * Convert the color from RGB value to HEX
 *
 * @param {number} r
 * @param {number} g
 * @param {number} b
 *
 * @returns Hex value of the RGB color values
 */
export function convertRGBToHEX(r, g, b) {
  return "#" + convertColorComponentToHex(r) + convertColorComponentToHex(g) + convertColorComponentToHex(b);
}


/**
 * Convert the color from hex value to RGB
 *
 * @param {String} hex
 *
 * @returns Object containing RGB values of color
 */
export function convertHEXToRGB(hex) {
  var r, g, b;
  if (hex.charAt(0) === "#") {
    hex = hex.substr(1);
  }
  r = hex.charAt(0) + "" + hex.charAt(1);
  g = hex.charAt(2) + "" + hex.charAt(3);
  b = hex.charAt(4) + "" + hex.charAt(5);
  r = parseInt(r, 16);
  g = parseInt(g, 16);
  b = parseInt(b, 16);
  return {
    r,
    g,
    b
  };
}


/**
 * Convert the color from HEX value to Hue, Saturation and brightness
 *
 * @param {String} hex
 *
 * @returns Object containing HSB values of RGB color
 */
export function convertHEXToHSB(hex) {
  const rgb = convertHEXToRGB(hex);
  return convertRGBToHSB(rgb.r, rgb.g, rgb.b);
}


/**
 * Convert color from Hue, saturation and brightness values to hex.
 *
 * @param {number} h
 * @param {number} s
 * @param {number} v
 *
 * @returns Object containing Hex value of HSV color

 */
export function convertHSVToHEX(h = 0, s = 0, v = 0) {
  const rgb = convertHSVToRGB(h, s, v);
  return convertRGBToHEX(rgb.r, rgb.g, rgb.b);
}


/**
 * Convert color from RGB values to Hue, saturation and brightness.
 *
 * @param {number} r
 * @param {number} g
 * @param {number} b
 *
 * @returns Object containing RGB value of HSB color *
 */
export function convertRGBToHSB(r, g, b) {
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b),
    d = max - min,
    h,
    s = max === 0 ? 0 : d / max,
    v = max / 255;

  switch (max) {
    case min:
      h = 0;
      break;
    case r:
      h = g - b + d * (g < b ? 6 : 0);
      h /= 6 * d;
      break;
    case g:
      h = b - r + d * 2;
      h /= 6 * d;
      break;
    case b:
      h = r - g + d * 4;
      h /= 6 * d;
      break;
    default:
      h = 0
      break;
  }

  return {
    h: h * 360,
    s: s,
    b: v,
  };
}

/**
 * Convert the color from degree(angle) and saturation on color wheel to RGB
 *
 * @param {number} h
 * @param {number} s
 * @param {number} v
 *
 * @returns RGB values from the HSV values from selection on the wheel
 */
export function convertDegreeToRGB(h, s, v) {
  var r, g, b;
  h /= 360;
  s /= 100;
  v /= 100;

  if (s === 0) {
    r = g = b = v;
  } else {
    var hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    var q = v < 0.5 ? v * (1 + s) : v + s - v * s;
    var p = 2 * v - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  r = Math.round((r * 255 + Number.EPSILON) * 100) / 100;
  g = Math.round((g * 255 + Number.EPSILON) * 100) / 100;
  b = Math.round((b * 255 + Number.EPSILON) * 100) / 100;

  return {
    red: r,
    green: g,
    blue: b,
  };
}

/**
 * Get the color of the wall based on wall id
 *
 * @param {number} id
 *
 * @returns color value
 */
export function getWallColor(id) {
  var colorArray = ["rgba(0, 244, 193, 1)", "rgba(252, 46, 132, 1)", "rgba(168, 81, 255, 1)", "rgba(255, 240, 56, 1)", "rgba(66, 119, 255, 1)", "rgba(255, 130, 60, 1)", "rgba(251, 66, 255, 1)", "rgba(47, 205, 255, 1)", "rgba(255, 57, 57, 1)", "rgba(133, 251, 77, 1)"];
  var indx = +id % 10;
  return colorArray[indx];
}

// Limit of brightness at which the light will be turned off
export const BRIGHTNESS_OFF_LIMIT = 0.03;