import React, { useState, useEffect } from "react";
import { IonHeader, IonPage, IonItem, IonToggle, IonIcon } from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";
import "./WallView.scss";
import { controlAndUpdateWall, getWall } from "../../data/wall/WallActions";
import { connect } from "react-redux";
import { Wall } from "../../data/wall/Walls";
import AccessoryList from "../../components/accessorylist/AccessoryList";
import Typography from "../../components/common/Typography";
import WallSampleImage from "../../assets/images/wallsample.png";
import { WithTranslation, withTranslation } from "react-i18next";
import Toolbar, { ToolbarContents } from "../../components/common/Toolbar";
import SceneList from "../../components/scenelist/SceneList";
import Spinner from "../../components/common/Spinner";
import { getScenes } from "../../data/scene/SceneActions";
import { alertCircleOutline } from "ionicons/icons";
import { IonContent } from "@ionic/react";
import MailTo from "../../components/common/MailTo";
import { getMedias } from "../../data/mediamanagement/MediaActions";

interface WallViewProps extends RouteComponentProps<{ id: string }> {}

const mapStateToProps = (state: any) => ({
  walls: state.walls.items,
  wallLoading: state.walls.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  controlWall: (id: string, action: string) =>
    dispatch(controlAndUpdateWall(id, action)),
  getWall: (id: string) => dispatch(getWall(id)),
  getScenes: () => dispatch(getScenes()),
  getMedias: (accIdList: string[]) => {
    console.log(accIdList);
    dispatch(getMedias(accIdList));
  },
});

type Props = WithTranslation &
  WallViewProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const WallView: React.FC<Props> = (props) => {
  const { walls, match, controlWall, getScenes, getWall, getMedias, t } = props;
  let { wallLoading } = props;
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const SMALL_DEVICE_WIDTH = 599;
  const [wallOn, setWallOn] = useState(false);
  var wall = walls.find((wall: Wall) => String(wall.id) === match.params.id);

  const [wallHeaderColorStyle, setWallHeaderColorStyle] = useState<any>({
    backgroundColor: "rgba(28, 30, 30, 0.64)",
    opacity: "1",
  });
  const numberOfAccessories =
    wall && wall.accessories ? wall.accessories.length : 0;

  function checkIfAccessoryIsOn() {
    var accessoriesOn = false;
    for (let a of wall.accessories) {
      if (
        a.state &&
        (((a.state?.media?.type === "COLOR" ||
          a.state?.media?.type === "DIMMER") &&
          a.state?.media?.config.brightness > 0) ||
          a.state?.media?.type === "ON" ||
          a.state?.media?.type?.startsWith("ANIMATION_") ||
          a.state?.media?.type === "VIDEO" ||
          a.state?.media?.type === "IMAGE" ||
          a.state?.media?.type === "WEBSITE")
      ) {
        accessoriesOn = true;
      }
    }
    return accessoriesOn;
  }

  function checkWallOn() {
    return (
      checkIfAccessoryIsOn() ||
      (wall && checkIfAccessoryIsOn() && wall.state === "ON")
    );
  }

  useEffect(() => {
    setWallOn(checkWallOn());
    setWallHeaderColorStyle(getWallHeaderColorStyle());
    getMediaInfo();
    console.log("Wall changed, update the wall on switch: " + wallOn);
  }, [wall]);

  useEffect(() => {
    setWallHeaderColorStyle(getWallHeaderColorStyle());
    console.log("Wall On?: " + wallOn);
  }, [wallOn]);

  const getMediaInfo = () => {
    let accList = [];
    for (let a of wall.accessories) {
      if (a.type.category === "STREAM") {
        console.log(a);
        accList.push(a.easyvis_device_id);
      }
    }
    getMedias(accList);
  };

  const updateDimensions = () => {
    setWinWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const refresh = (e: CustomEvent) => {
    refreshWall();
    e.detail.complete();
  };

  function refreshWall() {
    if (wall && wall.id) {
      console.log("Refresh Wall " + String(wall.id));
      getWall(String(wall.id));
      getScenes();
    }
  }

  function toggleWallStatus(status: boolean) {
    if (checkWallOn() !== status) {
      if (status) {
        setWallOn(true);
        controlWall(match.params.id, "on");
      } else {
        setWallOn(false);
        controlWall(match.params.id, "off");
      }
    }
  }

  /*
    The following method is used when the scene is controlled (de-selected)
    If the scene is de-selected then we need to switch the wall OFF
   */
  const afterSceneControl = (status: boolean) => {
    toggleWallStatus(status);
  };

  const toolbarContents: ToolbarContents = {
    left: {
      show_icon: true,
      router_link: "/home",
    },
  };

  function getWallHeaderColorStyle() {
    let wallColor =
      (wall.state === "ON" || wallOn) && winWidth <= SMALL_DEVICE_WIDTH
        ? wall.wall_color
        : "rgba(28, 30, 30, 0.64)";

    let colorOpacity =
      (wall.state === "ON" || wallOn) && winWidth <= SMALL_DEVICE_WIDTH
        ? "0.1"
        : "1";

    return {
      "--wallHeaderColor": wallColor,
      "--colorOpacity": colorOpacity,
    };
  }

  function getWallHeader() {
    return (
      <div className="wallHeader">
        <div>
          <img
            src={WallSampleImage}
            alt="Sample wall"
            className={`${
              winWidth <= SMALL_DEVICE_WIDTH ? "hide" : "wallImage"
            }`}
          />
        </div>
        <div className="wallHeaderTitleSubtitle">
          <div className="wallHeaderTitle">
            <Typography variant="title2" color="white96">
              {wall.name}
            </Typography>
          </div>
          <div className="wallHeaderSubtitle">
            <Typography
              variant="body2"
              color="white64"
              className="accessoryTypography"
            >
              Wall &#183; {numberOfAccessories} {t("accessories")}
            </Typography>
          </div>
        </div>
        <div className="wallSwitchDiv">
          <IonItem
            detail={false}
            lines="full"
            className={`wallSwitch ${
              winWidth <= SMALL_DEVICE_WIDTH ? "" : "wallSwitchRightMargin"
            }`}
          >
            <Typography
              variant="body1"
              color="white64"
              className={`${
                winWidth <= SMALL_DEVICE_WIDTH ? "hide" : "wallSwitchTypography"
              }`}
            >
              Wall {t("on")}
            </Typography>
            <IonToggle
              checked={wallOn}
              mode="ios"
              onIonChange={(e) => toggleWallStatus(e.detail.checked)}
              className="ionToggleSwitch"
              disabled={wall.state === "DISCONNECTED"}
            />
          </IonItem>
        </div>
      </div>
    );
  }

  return (
    <IonPage id="wallView" style={wallHeaderColorStyle}>
      <IonHeader className="ion-no-border ionHeader">
        <Toolbar variant="toolbar" contents={toolbarContents} />
      </IonHeader>
      <IonContent>
        <div className="wallViewRoot">
          <div className="wallViewContent">
            <div className="wallHeaderDiv">
              <div>{getWallHeader()}</div>
              {winWidth <= SMALL_DEVICE_WIDTH ? (
                <div
                  className={`wallColorLayer ${
                    wall.state === "ON" || wallOn
                      ? "wallOnColorLayer"
                      : "wallOffColorLayer"
                  }`}
                >
                  <div style={{ opacity: "0" }}>{getWallHeader()}</div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {wall.state === "DISCONNECTED" ? (
              <div className="offlineMessageDiv">
                <div className="offlineMessageIconDiv">
                  <IonIcon
                    className="offlineMessageIcon"
                    icon={alertCircleOutline}
                  />
                </div>
                <div className="offlineShortMessage">
                  <Typography variant="heading1" color="critical">
                    {t("no-connection")}
                  </Typography>
                </div>
                <div className="offlineMessage">
                  <Typography variant="body1" color="white64">
                    {t("wall-is-disconnected")}
                  </Typography>
                </div>
                <div>
                  <MailTo
                    email="info@magnwall.com"
                    subject="Report your problem"
                  >
                    {t("more-report-problem")}
                  </MailTo>
                </div>
              </div>
            ) : wallLoading ? (
              <Spinner className="wallSpinner" />
            ) : (
              <div className="wallViewContentData">
                <div className="sceneList">
                  <SceneList wall={wall} onSceneControl={afterSceneControl} />
                </div>
                <div className="wallViewAccessory">
                  <div>
                    <Typography
                      variant="heading1"
                      color="white96"
                      className="wallAccessoryTitle"
                    >
                      {t("accessories")}
                    </Typography>
                  </div>
                  <div>
                    {wall ? (
                      <AccessoryList
                        accessories={wall.accessories}
                        showGrouping={true}
                        showAnimation={true}
                      />
                    ) : (
                      <div>{t("wall-noAccessories")}</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(WallView)
);
