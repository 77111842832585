import React, { useState, useEffect } from "react";
import { Accessory, AccessoryState } from "../../data/wall/Walls";
import "./AccessoryListItemStream.scss";
import { controllAccessoryAndUpdateWall } from "../../data/wall/WallActions";
import { connect, ConnectedProps } from "react-redux";
import Typography from "../common/Typography";
import { withTranslation, WithTranslation } from "react-i18next";
import { IonButton, IonIcon, IonItem } from "@ionic/react";
import { close } from "ionicons/icons";
import stream_placeholder from "../../assets/images/stream_placeholder.svg";
import { Media } from "../../data/mediamanagement/Medias";
import Alert from "../common/Alert";
interface AccessoryListItemProps {
  accessories: Accessory[];
  openStreamModal(value: boolean): void;
}

const mapState = (state: any) => ({
  medias: state.medias.items,
});

const mapDispatch = (dispatch: any) => ({
  controlAccesssory: (
    wallId: number,
    accessoryIds: number[],
    state: AccessoryState
  ) => {
    dispatch(controllAccessoryAndUpdateWall(wallId, accessoryIds, state));
  },
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithTranslation & PropsFromRedux & AccessoryListItemProps;

const AccessoryListItemStream: React.FC<Props> = (props) => {
  const { accessories, controlAccesssory, openStreamModal, medias, t } = props;
  const [showDisconnectedError, setShowDisconnectedError] = useState(false);
  const newState = {
    status: "DISCONNECTED",
    media: {
      type: "OFF",
      config: {
        uri: accessories[0]?.type?.image_url,
      },
    },
  };
  useEffect(() => {
    if (
      accessories[0]?.state?.status === "DISCONNECTED" &&
      accessories[0]?.state?.media?.type?.endsWith("ERROR")
    ) {
      Object.assign(accessories[0].state, newState); //To reset the accessory config
      setShowDisconnectedError(true);
    }
  });

  function getMediaName(mediaUri: any) {
    let filteredMedia = medias.filter((media: Media) => media.url === mediaUri);
    return filteredMedia && filteredMedia[0] ? filteredMedia[0].name : null;
  }

  function getLocalMediaName(mediaUri: any) {
    const mediaId = mediaUri.substring(7);
    let filteredMedia = medias.filter((media: Media) => media.id === mediaId);
    return filteredMedia && filteredMedia[0] ? filteredMedia[0].name : null;
  }

  function generateStreamName() {
    let accessory = accessories[0]; // Assuming that all accessories are of same type
    let mediaName =
      accessory.state?.media?.type === "WEBSITE"
        ? accessory?.state?.media?.config?.uri
        : accessory?.state?.media?.config?.uri?.startsWith("file://")
        ? getLocalMediaName(accessory?.state?.media?.config?.uri)
        : getMediaName(accessory?.state?.media?.config?.uri);
    if (accessory.state?.media?.type === "VIDEO") {
      return mediaName ? mediaName : t("stream-type-video");
    } else if (accessory.state?.media?.type === "IMAGE") {
      return mediaName ? mediaName : t("stream-type-image");
    } else if (accessory.state?.media?.type === "WEBSITE") {
      return mediaName ? mediaName : t("stream-type-website");
    } else return t("stream-text");
  }

  function getStreamType() {
    let accessory = accessories[0]; // Assuming that all accessories are of same type
    if (accessory.state?.media?.type === "VIDEO") return t("stream-type-video");
    else if (accessory.state?.media?.type === "IMAGE")
      return t("stream-type-image");
    else if (accessory.state?.media?.type === "WEBSITE")
      return t("stream-type-website");
    else return t("stream-text");
  }

  function getAccessoryIds(accessories: Accessory[]) {
    var accessoryIds: number[] = [];
    accessories.forEach((accessory: Accessory) => {
      accessoryIds.push(accessory.id);
    });
    return accessoryIds;
  }

  function handleEndStream() {
    console.log("Ending stream by turning accessory off");
    const newState = {
      status:
        accessories[0]?.state?.status === "DISCONNECTED"
          ? "DISCONNECTED"
          : "OFF",
      media: {
        type: "OFF",
        config: {
          uri: accessories[0]?.type?.image_url,
        },
      },
    };
    controlAccesssory(
      accessories[0].wall_id,
      getAccessoryIds(accessories),
      newState
    );
  }

  function handleStreamChange() {
    openStreamModal(true);
  }

  const handleOk = () => {};

  return (
    <div
      id="accessoryListItemStream"
      className="accessoryListColorStreamContainer"
    >
      <IonItem
        lines="none"
        className="accessoryListItemStreamContainer ion-no-padding"
      >
        <IonButton
          className="accessoryListItemStreamEndStreamText ion-no-padding"
          onClick={(e) => handleStreamChange()}
          fill="clear"
        >
          {accessories[0]?.state?.status === "DISCONNECTED" ||
          accessories[0]?.state?.status === "OFF" ? (
            <div className="">
              <img
                src={stream_placeholder}
                alt=""
                className="streamImage"
              ></img>
            </div>
          ) : (
            <div className="streamText">
              <div className="streamTextHeading">
                <Typography variant="caption1" color="white64">
                  {getStreamType()}
                </Typography>
              </div>
              <div className="streamType">
                <Typography
                  variant="body1"
                  color="white96"
                  className="streamName"
                >
                  {generateStreamName()}
                </Typography>
              </div>
            </div>
          )}
        </IonButton>
        {accessories[0]?.state?.status === "DISCONNECTED" ||
        accessories[0]?.state?.status === "OFF" ? (
          <div></div>
        ) : (
          <IonButton
            onClick={(e) => handleEndStream()}
            className="accessoryListItemStreamEndStreamButton"
            fill="clear"
          >
            <IonIcon icon={close} className="endStreamIcon" />
          </IonButton>
        )}
      </IonItem>
      <Alert
        displayAlert={showDisconnectedError}
        dismissAlert={setShowDisconnectedError}
        actionHandler={handleOk}
        hideCancelLabel={true}
        actionLabel="Ok"
        alertHeader={t("stream-error")}
        alertMessage={t("stream-type-error")}
      ></Alert>
    </div>
  );
};

export default withTranslation()(connector(AccessoryListItemStream));
