import React, { useState } from "react";
import {
  IonButton,
  IonModal,
  IonIcon,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import "./ColorPicker.scss";
import { BRIGHTNESS_OFF_LIMIT, convertHSVToHEX } from "../../utils/ColorUtil";
import ColorWheel from "./ColorWheel";
import { close } from "ionicons/icons";
import { Accessory, Config } from "../../data/wall/Walls";
import Typography from "../common/Typography";
import { withTranslation, WithTranslation } from "react-i18next";

interface AccessoryListProps {
  colorChangeCallback(
    hue: number,
    saturation: number,
    brightness: number
  ): void;
  color: Config;
  style?: string;
  displayBrightness?: string;
  accessory?: Accessory;
  showBrightnessBox?: boolean;
  showBrightnessBoxIcon?: boolean;
}
type Props = WithTranslation & AccessoryListProps;

const ColorPicker: React.FC<Props> = (props) => {
  const {
    colorChangeCallback,
    color,
    style,
    displayBrightness,
    accessory,
    showBrightnessBox,
    showBrightnessBoxIcon,
    t,
  } = props;
  const showColorWheel = displayBrightness ? false : true;
  const [colorModalOpen, setColorModalOpen] = useState(false);

  function provideColorCircleStyle(color: string) {
    return {
      height: "32px",
      width: "32px",
      backgroundColor: color,
      borderRadius: "50%",
      display: "inline-block",
    };
  }

  function provideColorLargeStyle(color: string) {
    return {
      height: "100%",
      width: "100%",
      backgroundColor: color,
      borderRadius: "8px",
      display: "inline-block",
    };
  }

  const clickCircle = () => {
    setColorModalOpen(true);
  };

  const changeColor = (color: any) => {
    colorChangeCallback(color.hue, color.saturation, color.brightness);
  };

  function BrightnessBox({ ...props }) {
    return (
      <div className="brightnessBox">
        {props.showBrightnessBoxIcon ? <div className="sliderIcon" /> : <div />}
        <div className="brightnessValue">
          <Typography variant="body1" color="white96">
            {color.brightness! < BRIGHTNESS_OFF_LIMIT
              ? t("off")
              : Math.round(color.brightness! * 100) + "%"}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div id="colorPicker" className="colorPickerContainer">
      <IonButton
        className={`${
          showColorWheel ? "colorCircleButton" : "brightnessButton"
        }`}
        onClick={clickCircle}
      >
        {style === "full" ? (
          showColorWheel ? (
            <span
              style={
                color.brightness
                  ? provideColorLargeStyle(
                      convertHSVToHEX(color.hue, color.saturation, 1)
                    )
                  : provideColorLargeStyle(convertHSVToHEX(180, 0.01, 0.2))
              }
            >
              {showBrightnessBox ? (
                <BrightnessBox showBrightnessBoxIcon={showBrightnessBoxIcon} />
              ) : (
                <div></div>
              )}
            </span>
          ) : (
            <div className="pickerText">
              <div className="pickerTextHeading">
                <Typography variant="caption1" color="white64">
                  {t("text-control")}
                </Typography>
              </div>
              <div className="pickerType">
                <Typography variant="body1" color="white96">
                  {displayBrightness}
                </Typography>
              </div>
              {showBrightnessBox ? (
                <BrightnessBox showBrightnessBoxIcon={showBrightnessBoxIcon} />
              ) : (
                <div></div>
              )}
            </div>
          )
        ) : (
          <span
            style={provideColorCircleStyle(
              convertHSVToHEX(color.hue, color.saturation, 1)
            )}
          />
        )}
      </IonButton>
      <IonModal
        isOpen={colorModalOpen}
        cssClass={`${showColorWheel ? "colorModal" : "brightnessModal"}`}
        swipeToClose={true}
        onDidDismiss={() => setColorModalOpen(false)}
        animated
        backdrop-dismiss
        showBackdrop
      >
        <div className="colorModalHorizontalBar"></div>
        <IonFab
          onClick={() => setColorModalOpen(false)}
          vertical="top"
          horizontal="end"
        >
          <IonFabButton size="small">
            <IonIcon icon={close} />
          </IonFabButton>
        </IonFab>
        <div className="colorModalContent">
          <ColorWheel
            displayBrightness={
              showColorWheel ? t("text-brightness") : displayBrightness
            }
            accessory={accessory}
            showColorWheel={showColorWheel}
            color={color}
            onChangeComplete={changeColor}
          />
        </div>
      </IonModal>
    </div>
  );
};

export default withTranslation()(ColorPicker);
