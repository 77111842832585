import React, { useState } from "react";
import "./AccessoryMenu.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import MoreHorizontal from "../../assets/images/more_horiz-white.svg";
import {
  IonPopover,
  IonList,
  IonItem,
  IonModal,
  IonActionSheet,
} from "@ionic/react";
import Typography from "../common/Typography";
import CreateEditGroup from "./group/CreateEditGroup";
import { connect } from "react-redux";
import { Accessory, Wall } from "../../data/wall/Walls";
import { groupAccessories } from "../../data/wall/WallActions";
import Alert from "../common/Alert";
import { close, duplicate, copy } from "ionicons/icons";
import animation from "../../assets/images/animation.svg";
import change_group from "../../assets/images/change_group.svg";
import Media from "react-media";
import Tooltip from "@material-ui/core/Tooltip";

interface AccessoryProps {
  group_id?: number;
  accessories: Accessory[];
  showGrouping: boolean;
  showAnimation: boolean;
  setAnimationModalOpen(value: boolean): void;
}
const mapStateToProps = (state: any) => ({
  walls: state.walls.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  groupAccessories: (
    wallId: number,
    accessoryIds: number[],
    group_id: string
  ) => {
    dispatch(groupAccessories(wallId, accessoryIds, group_id));
  },
});

type Props = WithTranslation &
  AccessoryProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AccessoryMenu: React.FC<Props> = (props) => {
  const {
    walls,
    group_id,
    accessories,
    showGrouping,
    showAnimation,
    groupAccessories,
    setAnimationModalOpen,
    t,
  } = props;
  var wall = walls.find((wall: Wall) => wall.id === accessories[0].wall_id);
  const toolTip =
    showGrouping || animationAllowed()
      ? ""
      : t("accessory-menu-disabled-tooltip");
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [groupSelected, setGroupSelected] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  /**
   * Just allow animations if all accessories are a color type accessory
   */
  function animationAllowed() {
    let allowed = true;
    accessories.forEach((a) => {
      if (a.type.category !== "RGB") {
        allowed = false;
      }
    });
    return allowed;
  }

  const handleGroupSave = (accessoryIds: number[], groupId: any) => {
    setGroupModalOpen(false);
    groupAccessories(wall.id, accessoryIds, groupId);
  };

  const handleCancelGrouping = () => {
    setGroupModalOpen(false);
  };

  const handleAddRemoveAccessories = () => {
    setGroupSelected(true);
    setGroupModalOpen(true);
  };

  const handleUngroupAccessoriesAlert = () => {
    setShowPopover({ open: false, event: undefined });
    setShowAlert(true);
  };

  const handleUngroupAccessories = () => {
    setShowAlert(false);
    if (group_id)
      groupAccessories(wall.id, [accessories[0].id], String(group_id));
  };

  const handleGroupWithOtherAccessories = () => {
    setGroupModalOpen(true);
  };

  const handleAnimation = () => {
    setAnimationModalOpen(true);
    setShowPopover({ open: false, event: undefined });
  };

  function showMenuOnMobile() {
    var button_array: any = [
      {
        text: "Cancel",
        icon: close,
        role: "cancel",
        handler: () => {
          console.log("Cancel clicked");
        },
      },
    ];
    if (showGrouping) {
      if (group_id) {
        button_array.push({
          text: `${t("add-remove-accessories")}`,
          icon: change_group,
          handler: () => {
            handleAddRemoveAccessories();
          },
        });
        button_array.push({
          text: `${t("ungroup-accessories")}`,
          icon: copy,
          handler: () => {
            handleUngroupAccessoriesAlert();
          },
        });
      } else {
        button_array.push({
          text: `${t("group-with-other-accessories")}`,
          icon: duplicate,
          handler: () => {
            handleGroupWithOtherAccessories();
          },
        });
      }
    }
    if (showAnimation && animationAllowed()) {
      button_array.push({
        text: `${t("configure-animation")}`,
        icon: animation,
        handler: () => {
          handleAnimation();
        },
      });
    }

    return (
      <IonActionSheet
        isOpen={showPopover.open}
        onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
        buttons={button_array}
        cssClass="accessoryMenuButtonMobile"
      ></IonActionSheet>
    );
  }

  function showMenuOnDesktop() {
    return (
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
      >
        <IonList className="popoverMenu">
          {showGrouping ? (
            <div>
              {group_id ? (
                <div>
                  <IonItem button onClick={handleAddRemoveAccessories}>
                    <Typography variant="body2" color="white96">
                      {t("add-remove-accessories")}
                    </Typography>
                  </IonItem>
                  <IonItem button onClick={handleUngroupAccessoriesAlert}>
                    <Typography variant="body2" color="white96">
                      {t("ungroup-accessories")}
                    </Typography>
                  </IonItem>
                </div>
              ) : (
                <IonItem button onClick={handleGroupWithOtherAccessories}>
                  <Typography variant="body2" color="white96">
                    {t("group-with-other-accessories")}
                  </Typography>
                </IonItem>
              )}
            </div>
          ) : null}
          {showAnimation && animationAllowed() ? (
            <IonItem button onClick={handleAnimation}>
              <Typography variant="body2" color="white96">
                {t("configure-animation")}
              </Typography>
            </IonItem>
          ) : null}
        </IonList>
      </IonPopover>
    );
  }

  const getCssClassName = () => {
    if (showGrouping || animationAllowed()) {
      return "groupMoreIcon";
    } else {
      return "groupMoreIconDisabled";
    }
  };

  const handleOnClick = (e: any) => {
    if (showGrouping || animationAllowed()) {
      setShowPopover({ open: true, event: e.nativeEvent });
    }
  };

  return (
    <div id="groupAccessory">
      <Tooltip title={toolTip} placement="top" enterTouchDelay={1} arrow>
        <img
          src={MoreHorizontal}
          alt="more"
          className={getCssClassName()}
          onClick={(e) => handleOnClick(e)}
        ></img>
      </Tooltip>
      <Media queries={{ small: "(max-width: 599px)" }}>
        {(matches) =>
          matches.small ? showMenuOnMobile() : showMenuOnDesktop()
        }
      </Media>
      <IonModal
        cssClass="groupModal"
        isOpen={groupModalOpen}
        onWillPresent={() => setShowPopover({ open: false, event: undefined })}
        onDidDismiss={() => setGroupModalOpen(false)}
      >
        <CreateEditGroup
          wall={wall}
          selectedAccessory={accessories[0]}
          onSave={handleGroupSave}
          onCancel={handleCancelGrouping}
          isGroupSelected={groupSelected}
        />
      </IonModal>
      <Alert
        displayAlert={showAlert}
        dismissAlert={setShowAlert}
        actionHandler={handleUngroupAccessories}
        actionLabel="Ok"
        alertHeader={t("ungroup-accessories")}
        alertMessage={t("ungroup-accessories-alert")}
      ></Alert>
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AccessoryMenu)
);
