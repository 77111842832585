import {
  GET_MEDIAS_REQUEST,
  GET_MEDIAS_FAILURE,
  GET_MEDIAS_SUCCESS,
  GET_MEDIAS_FOR_LOCAL_REQUEST,
  DELETE_MEDIA_REQUEST,
  DELETE_MEDIA_FAILURE,
  DELETE_MEDIA_SUCCESS,
} from "./MediaActions";

export interface Media {
  id: string;
  name: string;
  url: string;
  customer_id: string;
  content_type: string;
  size: number;
  on_local_easyvis_device: [{ device_id: string; state: string }];
}

interface State {
  loading: boolean;
  items: Media[];
  invalid: { [mediaId: number]: boolean };
}

const initialStateMedia: State = {
  loading: false,
  items: [],
  invalid: {},
};

export const medias = (state: State = initialStateMedia, action: any) => {
  switch (action.type) {
    case GET_MEDIAS_FOR_LOCAL_REQUEST:
      return Object.assign({}, state, { loading: false });
    case GET_MEDIAS_REQUEST:
      return Object.assign({}, state, { loading: true });

    case GET_MEDIAS_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GET_MEDIAS_SUCCESS:
      var all_media: Media[] = action.response.data;
      // enhance with UI state
      console.log("medias", action.response.data);

      return Object.assign({}, state, {
        items: all_media,
        loading: false,
        invalid: {},
      });

    case DELETE_MEDIA_REQUEST:
      return Object.assign({}, state, { loading: true });
    case DELETE_MEDIA_FAILURE:
      return Object.assign({}, state, { loading: false });
    case DELETE_MEDIA_SUCCESS:
      const mediaId = action.mediaId;
      return Object.assign({}, state, {
        items: state.items.filter((media) => {
          if (mediaId !== media.id) {
            return media;
          } else {
            return false;
          }
        }),
        loading: false,
      });

    default:
      return state;
  }
};
