import React from "react";
import "./Alert.scss";
import { IonAlert } from "@ionic/react";

interface AlertProps {
  displayAlert: boolean;
  dismissAlert: (show: boolean) => void;
  actionHandler: (e: any) => void;
  alertHeader: string;
  alertMessage: string;
  actionLabel: string;
  hideCancelLabel?: boolean;
  showInput?: boolean;
}

const Alert: React.FC<AlertProps> = (props) => {
  const {
    displayAlert,
    dismissAlert,
    actionHandler,
    hideCancelLabel,
    actionLabel,
    alertHeader,
    alertMessage,
    showInput,
  } = props;

  var button_array: any = [];
  if (!hideCancelLabel) {
    button_array.push({
      text: "Cancel",
      role: "cancel",
      cssClass: "cancelButton",
    });
  }
  button_array.push({
    text: `${actionLabel}`,
    cssClass: "actionButton",
    handler: (e: any) => {
      actionHandler(e);
    },
  });

  let input_array: any = [];
  if (showInput) {
    input_array.push({
      name: "text_name",
      value: "",
      type: "url",
      placeholder: "Ex: (http://www.example.com)",
    });
  }

  return (
    <IonAlert
      isOpen={displayAlert}
      onDidDismiss={() => dismissAlert(false)}
      cssClass="alert_dialog"
      header={alertHeader}
      message={alertMessage}
      buttons={button_array}
      inputs={input_array}
    />
  );
};

export default Alert;
