import React, { useRef, useEffect } from "react";
import "./Slider.scss";
import { throttle } from "lodash";

interface SliderProps extends React.HTMLAttributes<HTMLIonButtonElement> {
  min: number;
  max: number;
  value: number;
  debounce: number;
  onChange: (event: any) => any;
  sliderColor?: string;
}

const Slider: React.FC<SliderProps> = (props) => {
  const { min, max, value, debounce, onChange, sliderColor } = props;
  const onChangeCall = (e: any) => onChange(e);
  const onChangeThrottle = throttle(onChangeCall, debounce);
  const inputRangeRef: any = useRef(null);

  const presetInputRange = () => {
    const inputRangeObj = inputRangeRef.current!;
    let mousedown = false;
    inputRangeObj.value = value;

    //Mouse Down event
    inputRangeObj.onmousedown = (e: any) => {
      mousedown = true;
    };
    //Touch start event
    inputRangeObj.ontouchstart = (e: any) => {
      mousedown = true;
    };

    //Mouse Move event
    inputRangeObj.onmousemove = (e: any) => {
      if (!mousedown) return false;
      onChangeThrottle(inputRangeObj.value);
    };
    //Touch move event
    inputRangeObj.ontouchmove = (e: any) => {
      if (!mousedown) return false;
      onChangeThrottle(inputRangeObj.value);
    };

    //Mouse Up event
    inputRangeObj.onmouseup = (e: any) => {
      mousedown = false;
      onChangeCall(inputRangeObj.value);
    };
    //Touch end event
    inputRangeObj.ontouchend = (e: any) => {
      mousedown = false;
      onChangeCall(inputRangeObj.value);
    };

    //Keyboard arrow key up event
    /*
    key: "ArrowLeft" keyCode: 37
    key: "ArrowUp" keyCode: 38
    key: "ArrowRight" keyCode: 39
    key: "ArrowDown" keyCode: 40
    */
    inputRangeObj.onkeyup = (e: any) => {
      if (
        e.keyCode === 37 ||
        e.keyCode === 38 ||
        e.keyCode === 39 ||
        e.keyCode === 40
      ) {
        const updatedValue = inputRangeObj.value;
        onChangeThrottle(updatedValue);
      }
    };
  };

  useEffect(() => {
    presetInputRange();
  }, []); //[] means the presetInputRange() will be executed only once

  useEffect(() => {
    inputRangeRef.current!.value = value;
  }, [value]); // means the presetInputRange() will be executed when the value is changed

  return (
    <div className="slidecontainer">
      <input
        ref={inputRangeRef}
        type="range"
        min={min}
        max={max}
        defaultValue={value}
        className="slider"
        id="myRange"
        style={{
          background: `linear-gradient(to right, ${sliderColor} ${value}%, #0E1010 0px)`,
        }}
      />
    </div>
  );
};

export default Slider;
