import React from "react";
import "./AnimationTypes.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { IonHeader } from "@ionic/react";
import Typography from "../../common/Typography";
import Toolbar, { ToolbarContents } from "../../common/Toolbar";
import Button from "../../common/Button";
import color_transition from "../../../assets/images/color_transition.svg";
import pulse from "../../../assets/images/pulse.svg";
import twinkle from "../../../assets/images/twinkle.svg";

export enum AnimationTypesEnum {
  PULSE = "pulse",
  COLOR_TRANSITION = "color-transition",
  TWINKLE = "twinkle",
}

interface AnimationTypesProps {
  onAnimationTypeChange(selectedType: AnimationTypesEnum): void;
  closeModal(): void;
}

type Props = WithTranslation & AnimationTypesProps;

const AnimationTypes: React.FC<Props> = (props) => {
  const { onAnimationTypeChange, closeModal, t } = props;

  const toolbarContents: ToolbarContents = {
    left: {
      show_icon: true,
      on_click: () => closeModal(),
    },
    middle: {
      label: t("select-animation-type"),
      show_image: false,
    },
  };

  return (
    <div id="animationTypes">
      <IonHeader translucent className="ion-no-border">
        <Toolbar contents={toolbarContents} />
      </IonHeader>
      <div className="modalContent">
        <div className="animationTypeContainer">
          {Object.values(AnimationTypesEnum).map((type: any) => (
            <Button
              className="animationTypeButton"
              color="elevation01"
              key={type}
              onClick={() => onAnimationTypeChange(type)}
            >
              <div className="animationTypeDiv">
                <div>
                  {type === AnimationTypesEnum.PULSE ? (
                    <img src={pulse} alt={type}></img>
                  ) : type === AnimationTypesEnum.COLOR_TRANSITION ? (
                    <img src={color_transition} alt={type}></img>
                  ) : (
                    <img src={twinkle} alt={type}></img>
                  )}
                </div>
                <div className="animationTypeDivTxt">
                  <Typography variant="body1" color="white64" className="animationTypeTxt">
                    {t("animation-type-" + type)}
                  </Typography>
                </div>
              </div>
            </Button>
          ))}
        </div>
        <div className="animationTypeMsg">
          <Typography variant="caption1" color="white64">
            {t("animation-type-message")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AnimationTypes);
