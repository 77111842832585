import React, { ReactNode } from "react";
import "./MailTo.scss";

interface MailToProps {
  email: String;
  subject: any;
  children: ReactNode;
}

const MailTo: React.FC<MailToProps> = (props) => {
  const { email, subject, children } = props;
  return (
    <a
      className="reportProblem"
      target="_blank"
      rel="noopener noreferrer"
      href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""}`}
    >
      {children}
    </a>
  );
};

export default MailTo;
