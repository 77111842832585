import React from "react";
import { SignIn } from "aws-amplify-react";
import { IonPage, IonContent, IonItem, IonInput, IonLabel, IonRow, IonGrid } from "@ionic/react";
import "./SignInMagnWall.scss";
import Typography from "../../components/common/Typography";
import Button from "../../components/common/Button";
import { withTranslation } from "react-i18next";

/**
 * Note that we used .js instead of .tsx because of the flexibility to extend from amplify. 
 * Otherwise we would have trouble with the properties and state
 */
class SignInMagnWall extends SignIn {

  state = {
    errorMessage: null
  }

  error(err) {
    this.setState({errorMessage: err.message});
  }

  clickSignIn = (e) => {
    this.setState({errorMessage: null});

    // This manual check is required due to a bug in amplify, compare:
    // https://github.com/aws-amplify/amplify-js/issues/5623
    if (!this.inputs.password) {
      this.setState({errorMessage: "Password cannot be empty"});
    } else {
      this.signIn(e);
    }
  }

  showComponent(theme) {
    return (
      <IonPage id="login-page">
        <IonContent>
          <div className="content">
            <IonGrid>
              <IonRow>
                <Typography variant="title1">Login</Typography>
              </IonRow>
              <IonRow className="emailRow">
                <IonItem className="ionItem">
                  <IonLabel position="stacked">Email</IonLabel>
                  <IonInput
                    inputMode="email"
                    type="email"
                    key="username"
                    name="username"
                    placeholder={this.props.t("auth-enterEmail")}
                    onIonChange={this.handleInputChange}
                    className = "ionInput emailInput"
                  ></IonInput>
                </IonItem>
              </IonRow>
              { this.state.errorMessage && this.state.errorMessage.includes("exist") ?
                  <IonRow>
                    <Typography variant="caption1" color="critical" className="loginAccountError">{this.props.t("login-account-does-not-exist")}</Typography>
                  </IonRow>
                : this.state.errorMessage && this.state.errorMessage.includes("Username cannot be empty") ?
                  <IonRow>
                    <Typography variant="caption1" color="critical" className="loginEmptyError">{this.state.errorMessage}</Typography>
                  </IonRow>
                : null }
              <IonRow>
                <IonItem className = "ionItem">
                  <IonLabel position="stacked">{this.props.t("password")}</IonLabel>
                  <IonInput
                    key="password"
                    type="password"
                    name="password"
                    placeholder={this.props.t("auth-enterPassword")}
                    onIonChange={this.handleInputChange}
                    className = "ionInput pwdInput"
                  ></IonInput>
                </IonItem>
              </IonRow >
              { this.state.errorMessage && this.state.errorMessage.includes("Password cannot be empty")?
                  <IonRow>
                    <Typography variant="caption1" color="critical"  className="passwordEmptyError">{this.state.errorMessage}</Typography>
                  </IonRow>
                : this.state.errorMessage && !this.state.errorMessage.includes("exist") && !this.state.errorMessage.includes("Username cannot be empty") && !this.state.errorMessage.includes("Password cannot be empty")?
                  <IonRow>
                    <Typography variant="caption1" color="critical"  className="errorRow">{this.state.errorMessage}{this.props.t("login-error")}</Typography>
                  </IonRow>
                : null }
              <IonRow className="submitRow">
                <Button className="buttonSubmit" variant="primary" onClick={this.clickSignIn}>{this.props.t("login")}</Button>
              </IonRow>
              <IonRow>
                <Button className="buttonForgotPassword" color="transparent" variant="primary" onClick={() => this.changeState("forgotPassword")}>{this.props.t("forgot-password")}</Button>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withTranslation()(SignInMagnWall);
