import React, { useState, useEffect } from "react";
import "./ImageStream.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Accessory, AccessoryState } from "../../../data/wall/Walls";
import Typography from "../../common/Typography";
import {
  IonButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
} from "@ionic/react";
import { add } from "ionicons/icons";
import Media from "../../mediamanagement/Media";
import { close } from "ionicons/icons";
import Alert from "../../common/Alert";
//import imageLibrary from "../../../assets/images/image_library.svg";

interface ImageStreamProps {
  accessories: Accessory[];
  onUpdate(stream: AccessoryState): void;
}

type Props = WithTranslation & ImageStreamProps;

const ImageStream: React.FC<Props> = (props) => {
  const { accessories, onUpdate, t } = props;
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState<any>();
  const [showAlert, setShowAlert] = useState(false);

  // Initial value setup (if stream values already set for the accessory)
  useEffect(() => {
    if (accessories[0].state?.media?.type === "IMAGE") {
      setSelectedImage(accessories[0].state?.media?.config?.uri);
    }
    //Update the config with initial values
    updateConfig();
  }, [accessories]);

  // Update the config whenever one of the elements in the stream changes
  useEffect(() => {
    updateConfig();
  }, [selectedImage]);

  const updateConfig = () => {
    const newConfig = {
      status: selectedImage ? "ON" : "OFF",
      media: {
        type: selectedImage ? "IMAGE" : "OFF",
        config: {
          uri: selectedImage ? selectedImageUrl : "",
        },
      },
    };
    onUpdate(newConfig);
  };

  const handleSelectImage = () => {
    setImageModalOpen(true);
  };

  const handleSelectedMedia = (selectedMedia: any, local: boolean) => {
    const imageUrl = local ? "file://" + selectedMedia?.id : selectedMedia?.url;
    setSelectedImageUrl(imageUrl);
    setSelectedImage(selectedMedia);
    setImageModalOpen(false);
  };

  const handleRemoveStream = () => {
    setSelectedImage(null);
    setSelectedImageUrl(null);
  };

  return (
    <div id="imageStreamMedia" className="streamContainer">
      {selectedImage ? (
        <div className="streamImageBox">
          <div className="viewStreamContainer">
            <div className="closeButtonContainer">
              <IonFab
                onClick={() => setShowAlert(true)}
                vertical="top"
                horizontal="end"
              >
                <IonFabButton size="small">
                  <IonIcon icon={close} />
                </IonFabButton>
              </IonFab>
            </div>
            <div className="streamIconContainer">
              <IonButton
                color="transparent"
                className="createStream addStreamMarkPosition ion-no-padding"
                onClick={handleSelectImage}
              >
                <img src={selectedImage?.url} alt=""></img>
              </IonButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="streamSelectorBox">
          <div className="addStreamContainer">
            <div className="addMark">
              <IonButton
                color="transparent"
                className="createStream addStreamMarkPosition ion-no-padding"
                onClick={handleSelectImage}
              >
                <IonIcon icon={add} className="addStreamIcon" />
              </IonButton>
            </div>
            <div className="addStreamText">
              <Typography
                variant="body2"
                color="white64"
                className="addImageText"
              >
                {t("add-image")}
              </Typography>
            </div>
          </div>
        </div>
      )}
      <IonModal
        cssClass="mediaModal"
        isOpen={imageModalOpen}
        onDidDismiss={() => setImageModalOpen(false)}
      >
        <Media
          mediaType="image"
          closeModal={() => setImageModalOpen(false)}
          onSelect={handleSelectedMedia}
          accessory={accessories[0]}
        />
      </IonModal>
      <Alert
        displayAlert={showAlert}
        dismissAlert={setShowAlert}
        actionHandler={handleRemoveStream}
        actionLabel="Remove"
        alertHeader={t("remove-stream")}
        alertMessage={t("remove-stream-alert")}
      ></Alert>
    </div>
  );
};

export default withTranslation()(ImageStream);
