import React from "react";
import "./Spinner.scss";
import { IonSpinner } from "@ionic/react";

interface SpinnerProps extends React.HTMLAttributes<HTMLIonSpinnerElement> {}

const Spinner: React.FC<SpinnerProps> = (props) => {
  const { ...attributes } = props;

  return <IonSpinner className={"spinner"} {...attributes} />;
};

export default Spinner;
