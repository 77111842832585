import React, { useState, useEffect } from "react";
import "./MediaListItem.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Media } from "../../../data/mediamanagement/Medias";
import Typography from "../../common/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import videoLibrary from "../../../assets/images/video_library.svg";
import MoreHorizontal from "../../../assets/images/more_horiz-white.svg";
import {
  IonActionSheet,
  IonIcon,
  IonButton,
  IonItem,
  IonList,
  IonPopover,
  IonRow,
  IonCol,
} from "@ionic/react";
import { close, remove } from "ionicons/icons";
import MediaQuery from "react-media";
import Alert from "../../common/Alert";
import delete_icon from "../../../assets/images/delete_icon.svg";
import folder from "../../../assets/images/folder.svg";
import cloud from "../../../assets/images/cloud.svg";
import download from "../../../assets/images/download.svg";
import { Accessory } from "../../../data/wall/Walls";
import Spinner from "../../common/Spinner";

interface MediaListItemProps {
  media: Media;
  local: boolean;
  onDelete(selectedMediaId: string, deviceId: string): void;
  onSelect(selectedMedia: Media, local: boolean): void;
  onRemoveFromDevice(selectedMediaId: string, deviceId: string): void;
  onDownloadToDevice(mediaId: string, deviceId: string): void;
  accessory: Accessory;
}

type Props = WithTranslation & MediaListItemProps;

const MediaListItem: React.FC<Props> = (props) => {
  const {
    media,
    local,
    onDelete,
    onRemoveFromDevice,
    onDownloadToDevice,
    onSelect,
    accessory,
    t,
  } = props;
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [removeFromDevice, setRemoveFromDevice] = useState(false);
  const [downloadToDevice, setDownloadToDevice] = useState(false);
  const [mediaDownloading, setMediaDownloading] = useState(false);
  const [accessoryDisconnected, setAccessoryDisconnected] = useState(false);

  const toolTip = mediaDownloading ? "" : "More";

  const mediaDownloadingToolTip = mediaDownloading
    ? t("media-downloading-tooltip")
    : "";

  const handleMoreClick = (e: any) => {
    if (!mediaDownloading) {
      setShowPopover({ open: true, event: e.nativeEvent });
    }
  };

  const handleDeleteMedia = () => {
    onDelete(media.id, accessory.easyvis_device_id);
  };

  const handleDeleteAlert = () => {
    setDownloadToDevice(false);
    setShowPopover({ open: false, event: undefined });
    setShowAlert(true);
  };

  const handleRemoveFromDeviceAlert = () => {
    setRemoveFromDevice(true);
    setShowPopover({ open: false, event: undefined });
    setShowAlert(true);
  };

  const handleRemoveFromDevice = () => {
    onRemoveFromDevice(media.id, accessory.easyvis_device_id);
    if (accessory.state?.status === "DISCONNECTED") {
      setAccessoryDisconnected(true);
      setShowPopover({ open: false, event: undefined });
      setShowAlert(true);
    }
  };

  const handleDownloadToDeviceAlert = () => {
    setDownloadToDevice(true);
    setShowPopover({ open: false, event: undefined });
    setShowAlert(true);
  };

  const handleDownloadToDevice = () => {
    onDownloadToDevice(media.id, accessory.easyvis_device_id);
    if (accessory.state?.status === "DISCONNECTED") {
      setAccessoryDisconnected(true);
      setShowPopover({ open: false, event: undefined });
      setShowAlert(true);
    }
  };

  const handleOk = () => {};

  const getCssClassName = () => {
    if (mediaDownloading) {
      return "mediaMoreIconDisabled";
    } else {
      return "mediaMoreIcon";
    }
  };
  useEffect(() => {
    if (
      media.on_local_easyvis_device.length &&
      accessory.state?.status !== "DISCONNECTED"
    ) {
      const localInfo = media.on_local_easyvis_device.find(
        (item) => item.device_id === accessory.easyvis_device_id
      );
      if (localInfo) {
        if (localInfo.state === "downloading") {
          setMediaDownloading(true);
        } else if (localInfo.state === "downloaded") {
          setMediaDownloading(false);
        }
      }
    }
  }, [media]);

  useEffect(() => {
    const abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  });

  /**
   * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
   *
   * @param {Int} num The number of bytes.
   */
  const readableBytes = (bytes: number) => {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0,
      n = bytes || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  };

  function showMenuOnMobile() {
    var button_array: any = [
      {
        text: "Cancel",
        icon: close,
        role: "cancel",
      },
      {
        text: t("remove-permanently"),
        icon: delete_icon,
        handler: () => {
          setDownloadToDevice(false);
          setRemoveFromDevice(false);
          setShowAlert(true);
          console.log("clicked");
        },
      },
    ];
    if (!local) {
      button_array.splice(0, 0, {
        text: t("download-to-device"),
        icon: download,
        handler: () => {
          handleDownloadToDeviceAlert();
        },
      });
    } else {
      button_array.splice(0, 0, {
        text: t("remove-from-device"),
        icon: remove,
        handler: () => {
          handleRemoveFromDeviceAlert();
        },
      });
    }
    return (
      <IonActionSheet
        isOpen={showPopover.open}
        onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
        buttons={button_array}
        cssClass="mediaMenuButtonMobile"
      ></IonActionSheet>
    );
  }

  function showMenuOnDesktop() {
    return (
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
      >
        <IonList>
          {!local ? (
            <IonItem button onClick={handleDownloadToDeviceAlert}>
              <Typography variant="body2" color="white96">
                {t("download-to-device")}
              </Typography>
            </IonItem>
          ) : (
            <IonItem button onClick={handleRemoveFromDeviceAlert}>
              <Typography variant="body2" color="white96">
                {t("remove-from-device")}
              </Typography>
            </IonItem>
          )}
          <IonItem button onClick={handleDeleteAlert}>
            <Typography variant="body2" color="white96">
              {t("remove-permanently")}
            </Typography>
          </IonItem>
        </IonList>
      </IonPopover>
    );
  }

  const handleSelectMedia = () => {
    onSelect(media, local);
  };

  return (
    <Tooltip
      title={mediaDownloadingToolTip}
      placement="top"
      enterTouchDelay={1}
      arrow
    >
      <div className="mediaSection">
        <div className="media">
          <IonRow>
            <IonCol>
              <IonButton
                mode="ios"
                className="ion-no-padding mediaButton"
                color="transparent"
                onClick={handleSelectMedia}
                disabled={mediaDownloading}
              >
                <div className="mediaSectionIcon">
                  <img
                    src={videoLibrary}
                    alt="media"
                    className="mediaIcon"
                  ></img>
                </div>
                <IonIcon
                  icon={!local ? cloud : folder}
                  className="media-platform-icon"
                  size="14"
                />
                <div className="mediaSectionText">
                  <div>
                    <Typography variant="body2" className="mediaName">
                      {media.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body2" className="mediaMetadata">
                      {readableBytes(media.size)}
                    </Typography>
                  </div>
                </div>
              </IonButton>
            </IonCol>
            <IonCol>
              {mediaDownloading ? (
                <Spinner className="mediaListSpinner" />
              ) : (
                <div></div>
              )}
            </IonCol>
          </IonRow>
        </div>
        <div className="mediaMore">
          <Tooltip title={toolTip} placement="top" enterTouchDelay={1} arrow>
            <img
              src={MoreHorizontal}
              alt="more"
              className={getCssClassName()}
              onClick={(e) => handleMoreClick(e)}
            ></img>
          </Tooltip>
          <MediaQuery queries={{ small: "(max-width: 599px)" }}>
            {(matches) =>
              matches.small ? showMenuOnMobile() : showMenuOnDesktop()
            }
          </MediaQuery>
        </div>

        <Alert
          displayAlert={showAlert}
          dismissAlert={setShowAlert}
          actionHandler={
            removeFromDevice
              ? handleRemoveFromDevice
              : downloadToDevice
              ? handleDownloadToDevice
              : handleDeleteMedia
          }
          actionLabel={
            removeFromDevice
              ? t("remove")
              : downloadToDevice
              ? t("download")
              : t("delete")
          }
          alertHeader={
            removeFromDevice
              ? t("remove-stream")
              : downloadToDevice
              ? t("download-media")
              : t("delete-media")
          }
          alertMessage={
            removeFromDevice
              ? t("remove-media-alert")
              : downloadToDevice
              ? t("download-media-alert")
              : t("delete-media-alert")
          }
        ></Alert>

        <Alert
          displayAlert={accessoryDisconnected}
          dismissAlert={setAccessoryDisconnected}
          actionHandler={handleOk}
          actionLabel="OK"
          alertHeader={t("stream-error")}
          alertMessage={t("stream-type-error")}
          hideCancelLabel={true}
        ></Alert>
      </div>
    </Tooltip>
  );
};

export default withTranslation()(MediaListItem);
