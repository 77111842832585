import React, { useState, useEffect } from "react";
import "./SceneList.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Scene, SceneAccessoryConfig } from "../../data/scene/Scenes";
import {
  controlSceneAndUpdateWall,
} from "../../data/scene/SceneActions";
import { Accessory } from "../../data/wall/Walls";
import Spinner from "../common/Spinner";
import SceneListHeader from "./SceneListHeader";
import SceneListContents from "./SceneListContents";

const mapStateToProps = (state: any, ownProps: any) => ({
  scenes: state.scenes.items,
  scenesLoading: state.scenes.loading,
  invalid: state.scenes.invalid,
  wall: ownProps.wall,
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  control_scene: (id: number, wallId: number) =>
    dispatch(controlSceneAndUpdateWall(id, wallId)),
  dispatchProps: ownProps,
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SceneList: React.FC<Props> = (props) => {
  const { scenes, scenesLoading, wall, dispatchProps, control_scene } = props;

  const [filteredScenes, setFilteredScenes] = useState([]);

  /*
  In the following useEffect(),
  1. We check if there are scenes and if yes get the wall accessory ids from the wall
  2. Then we filter the scenes using the above wall accessory ids and the scene accessory config ids
  */
  useEffect(() => {
    if (scenes && scenes.length > 0) {
      let accessories = wall?.accessories;
      let wallAccessoryIds: number[] = [];
      if (accessories) {
        accessories.map((acc: Accessory) => {
          wallAccessoryIds.push(acc.id);
          return acc;
        });
      }
      //To show scenes associated with this wall only
      let filteredScenes = scenes.filter((scene: Scene) => {
        let matchFound = false;
        if (scene.id !== wall?.default_scene_id) {
          scene.scene_accessories_config?.forEach(
            (sac: SceneAccessoryConfig) => {
              if (wallAccessoryIds.includes(+sac.accessory_id)) {
                matchFound = true;
              }
            }
          );
        }
        return matchFound;
      });
      setFilteredScenes(filteredScenes);
    }
  }, [scenes, wall]);

  //Control selected scene
  const controlScene = (sceneToControl: Scene) => {
    if (
      wall?.state === "ON" &&
      String(sceneToControl.id) === String(wall?.active_scene_id)
    ) {
      //User is trying to deactivate the active scene so set the wall OFF
      dispatchProps.onSceneControl(false);
    } else {
      control_scene(sceneToControl.id, wall.id);
    }
  };

  return scenesLoading ? (
    <Spinner />
  ) : (
    <div>
      <SceneListHeader
        sceneListSize={filteredScenes.length}
        wallid={wall?.id}
      />
      <SceneListContents
        sceneList={filteredScenes}
        wall={wall}
        controlScene={controlScene}
      />
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SceneList)
);
