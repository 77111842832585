import React, { useState, useEffect } from "react";
import "./VideoStream.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Accessory, AccessoryState } from "../../../data/wall/Walls";
import Typography from "../../common/Typography";
import {
  IonButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
} from "@ionic/react";
import { add } from "ionicons/icons";
import Media from "../../mediamanagement/Media";
import videoLibrary from "../../../assets/images/video_library.svg";
import { close } from "ionicons/icons";
import Alert from "../../common/Alert";

interface VideoStreamProps {
  accessories: Accessory[];
  onUpdate(stream: AccessoryState): void;
}

type Props = WithTranslation & VideoStreamProps;

const VideoStream: React.FC<Props> = (props) => {
  const { accessories, onUpdate, t } = props;
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [selectedLocal, setSelectedLocal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [streamImageUri, setStreamImageUri] = useState<string>();

  // Initial value setup (if stream values already set for the accessory)
  useEffect(() => {
    if (accessories[0].state?.media?.type === "VIDEO") {
      setStreamImageUri(accessories[0].state?.media?.config?.uri);
    }
    //Update the config with initial values
    updateConfig();
  }, [accessories]);

  // Update the config whenever one of the elements in the stream changes
  useEffect(() => {
    setStreamImageUri(selectedVideo?.url);
    updateConfig();
  }, [selectedVideo]);

  const updateConfig = () => {
    // Check if local or cloud url to be played
    const videoUrl = selectedLocal
      ? "file://" + selectedVideo?.id
      : selectedVideo?.url;
    const newConfig = {
      status: selectedVideo ? "ON" : "OFF",
      media: {
        type: selectedVideo ? "VIDEO" : "OFF",
        config: {
          uri: streamImageUri ? streamImageUri : videoUrl,
        },
      },
    };
    onUpdate(newConfig);
  };

  const handleSelectVideo = () => {
    setVideoModalOpen(true);
  };

  const handleSelectedMedia = (selectedMedia: any, local: boolean) => {
    setSelectedLocal(local);
    setSelectedVideo(selectedMedia);
    setVideoModalOpen(false);
  };

  const handleRemoveStream = () => {
    setSelectedVideo(null);
  };

  return (
    <div id="videoStreamMedia" className="streamContainer">
      <div className="streamSelectorBox">
        {selectedVideo ? (
          <div className="viewStreamContainer">
            <div className="closeButtonContainer">
              <IonFab
                onClick={() => setShowAlert(true)}
                vertical="top"
                horizontal="end"
              >
                <IonFabButton size="small">
                  <IonIcon icon={close} />
                </IonFabButton>
              </IonFab>
            </div>
            <div className="streamIconContainer">
              <IonButton
                color="transparent"
                className="createStream addStreamMarkPosition ion-no-padding"
                onClick={handleSelectVideo}
              >
                <img
                  src={videoLibrary}
                  alt="video"
                  className="streamImage"
                ></img>
              </IonButton>
            </div>
            <div className="streamMetaInfo">
              <div className="streamMetaName">
                <Typography
                  variant="body1"
                  color="white96"
                  className="streamName"
                >
                  {selectedVideo.name}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className="addStreamContainer">
            <div className="addMark">
              <IonButton
                color="transparent"
                className="createStream addStreamMarkPosition ion-no-padding"
                onClick={handleSelectVideo}
              >
                <IonIcon icon={add} className="addStreamIcon" />
              </IonButton>
            </div>
            <div className="addStreamText">
              <Typography
                variant="body2"
                color="white64"
                className="addVideoText"
              >
                {t("add-video")}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <IonModal
        cssClass="mediaModal"
        isOpen={videoModalOpen}
        onDidDismiss={() => setVideoModalOpen(false)}
      >
        <Media
          mediaType="video"
          closeModal={() => setVideoModalOpen(false)}
          onSelect={handleSelectedMedia}
          accessory={accessories[0]}
        />
      </IonModal>
      <Alert
        displayAlert={showAlert}
        dismissAlert={setShowAlert}
        actionHandler={handleRemoveStream}
        actionLabel="Remove"
        alertHeader={t("remove-stream")}
        alertMessage={t("remove-stream-alert")}
      ></Alert>
    </div>
  );
};

export default withTranslation()(VideoStream);
