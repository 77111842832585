import React, { useState } from "react";
import { API } from "aws-amplify";
import "./MediaFileUploader.scss";
import Dropzone from "react-dropzone-uploader";

import { IonButton, IonModal } from "@ionic/react";
import Typography from "../common/Typography";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import cloudUpload from "../../assets/images/cloud_upload.svg";
import Spinner from "../common/Spinner";
import Alert from "../common/Alert";

interface mediaFileUploaderProps {
  uploadMediaType: string;
  onComplete(status: number): void;
}

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => ({});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  mediaFileUploaderProps;

const MediaFileUploader: React.FC<Props> = (props) => {
  const { uploadMediaType, onComplete, t } = props;
  const [percentage, setPercentage] = useState(0);
  const [showMediaUploadProgress, setShowMediaUploadProgress] = useState(false);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [alertErrorHeader, setAlertErrorHeader] = useState("");
  const [alertErrorMessage, setAlertErrorMessage] = useState("");
  const maxFileSize = uploadMediaType === "video" ? 3221225472 : 10485760;
  const [showAlert, setShowAlert] = useState(false);

  // preview component
  const Preview = (props: any) => {
    return <div></div>;
  };

  const Input = (props: any) => {
    return (
      <IonButton
        mode="ios"
        className="ion-no-padding uploadButton"
        color="transparent"
      >
        <input
          style={{ opacity: 0, position: "absolute" }}
          type="file"
          accept={props.accept}
          autoComplete="off"
          tabIndex={-1}
          onChange={(e) => {
            props.getFilesFromEvent(e).then((chosenFiles: any) => {
              props.onFiles(chosenFiles);
            });
          }}
        />
        <div className="uploadSectionIcon">
          <img src={cloudUpload} alt="upload" className="uploadIcon"></img>
        </div>
        <div className="uploadSectionText">
          <Typography variant="body2" className="uplodFile">
            {t("upload-file")}
          </Typography>
        </div>
      </IonButton>
    );
  };

  const getFilesFromEvent = (e: any) => {
    const files = [];
    const fileList = e.target.files;
    // convert the FileList to a simple array of File objects
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];
      if (file.size > maxFileSize) {
        setShowFileSizeError(true);
      }
      files.push(file);
    }
    return Promise.resolve(files);
  };

  // specify upload params and url for your files
  const getUploadParams = async (props: any) => {
    let mediaUploadResponse = null;
    let canProceed = true;
    try {
      mediaUploadResponse = await API.post(
        "MagnwallMediaAPI",
        "/media/upload-request",
        {
          headers: {},
          body: {
            name: props.file.name,
            contentType: props.file.type,
          },
          response: true,
        }
      );
    } catch (error) {
      canProceed = false;
      setShowMediaUploadProgress(false);
      setAlertErrorHeader(t("server-alert"));
      setAlertErrorMessage(error.response.data);
      setShowAlert(true);
    }
    if (canProceed) {
      const { fields, url } = mediaUploadResponse?.data;
      const body = new FormData();
      Object.keys(fields).map((key) => body.append(key, fields[key]));
      body.append("file", props.file);
      return { url: url, body };
    } else {
      return { url: "" };
    }
  };

  // RESPONSE WILL COME HERE
  const handleChangeStatus = (props: any, status: any, event: any) => {
    if (status === "preparing") {
      if (!showFileSizeError) setShowMediaUploadProgress(true);
    }
    if (status === "error_file_size") {
      setAlertErrorHeader(t("media-size-error"));
      setAlertErrorMessage(t("media-size-error-alert"));
      setShowAlert(true);
    }
    if (status === "done") {
      //Close the upload progress screen
      setTimeout(() => {
        setShowMediaUploadProgress(false);
        setPercentage(0);
      }, 4000);
    } else {
      if (props.xhr) {
        // XHR - Upload Progress
        props.xhr.upload.onprogress = (e: any) => {
          const done = e.position || e.loaded;
          const total = e.totalSize || e.total;
          const perc = Math.floor((done / total) * 100);
          if (perc >= 100) {
            setTimeout(() => {
              setPercentage(perc);
              onComplete(new Date().getTime());
            }, 4000);
          } else {
            //console.log(`Percent ${perc}%`);
            //setStatus(`${perc}%`);
          }
          if (perc <= 99) {
            setPercentage(perc);
          }
        };
      }
    }
  };

  const handleFileSize = () => {};

  return (
    <div className="uploadSection">
      <Dropzone
        getUploadParams={getUploadParams}
        PreviewComponent={Preview}
        onSubmit={undefined}
        InputComponent={Input}
        accept={`${uploadMediaType}/*`}
        onChangeStatus={handleChangeStatus}
        getFilesFromEvent={getFilesFromEvent}
        minSizeBytes={0}
        maxSizeBytes={maxFileSize}
        disabled={(files) =>
          files.some((f) =>
            ["preparing", "getting_upload_params", "uploading"].includes(
              f.meta.status
            )
          )
        }
      />
      <IonModal
        cssClass="mediaUploadProgressModal"
        isOpen={showMediaUploadProgress}
        onDidDismiss={() => setShowMediaUploadProgress(false)}
      >
        <Spinner />
        <div className="uploadProgress">
          <Typography variant="body1" className="mediaUploadProgress">
            {`${percentage} %`}
          </Typography>
        </div>
      </IonModal>
      <Alert
        displayAlert={showAlert}
        dismissAlert={setShowAlert}
        actionHandler={handleFileSize}
        hideCancelLabel={true}
        actionLabel="Ok"
        alertHeader={alertErrorHeader}
        alertMessage={alertErrorMessage}
      ></Alert>
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MediaFileUploader)
);
