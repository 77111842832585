import React, { useState, useEffect } from "react";
import "./CreateEditAnimation.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { IonHeader, IonIcon, IonModal } from "@ionic/react";
import Typography from "../../common/Typography";
import Toolbar, { ToolbarContents } from "../../common/Toolbar";
import { Accessory } from "../../../data/wall/Walls";
import dummyImage from "../../../assets/images/dummy_image.svg";
import { squareOutline } from "ionicons/icons";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import PulseAnimation from "./PulseAnimation";
import { connect, ConnectedProps } from "react-redux";
import { controllAccessoryAndUpdateWall } from "../../../data/wall/WallActions";
import { AccessoryState, Wall } from "../../../data/wall/Walls";
import AnimationType from "./AnimationTypes";
import Button from "../../common/Button";
import { AnimationTypesEnum } from "./AnimationTypes";
import ColorTransitionAnimation from "./ColorTransitionAnimation";
import TwinkleAnimation from "./TwinkleAnimation";

interface CreateEditAnimationProps {
  accessories: Accessory[];
  closeModal(): void;
}

const mapState = (state: any) => ({
  walls: state.walls.items,
});

const mapDispatch = (dispatch: any) => ({
  controlAccesssory: (
    wallId: number,
    accessoryIds: number[],
    state: AccessoryState
  ) => {
    dispatch(controllAccessoryAndUpdateWall(wallId, accessoryIds, state));
  },
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithTranslation & PropsFromRedux & CreateEditAnimationProps;

const CreateEditAnimation: React.FC<Props> = (props) => {
  const { accessories, closeModal, controlAccesssory, walls, t } = props;
  const [error] = useState(false);
  const [popupTitle] = useState("");
  const [animationTypeModalOpen, setAnimationTypeModalOpen] = useState(false);
  const [
    selectedAnimationType,
    setSelectedAnimationType,
  ] = useState<AnimationTypesEnum>();
  const [animation, setAnimation] = useState<AccessoryState>(
    accessories[0].state!
  );

  var wall = walls.find((wall: Wall) => wall.id === accessories[0].wall_id);

  function getAccessoryIds(accessories: Accessory[]) {
    var accessoryIds: number[] = [];
    accessories.forEach((accessory: Accessory) => {
      accessoryIds.push(accessory.id);
    });
    return accessoryIds;
  }

  const handleAnimationSave = () => {
    console.log("Saving animation");
    controlAccesssory(wall.id, getAccessoryIds(accessories), animation!);
    closeModal();
  };

  const handleUpdate = (animation: AccessoryState) => {
    setAnimation(animation);
  };

  useEffect(() => {}, []);

  const toolbarContents: ToolbarContents = {
    left: {
      on_click: () => closeModal(),
      label: t("cancel"),
      show_icon: false,
    },
    middle: {
      label: popupTitle,
      show_image: false,
    },
    right: {
      on_click: () => handleAnimationSave(),
      label: t("done"),
      show_icon: false,
    },
  };

  const openAnimationTypeModal = () => {
    setAnimationTypeModalOpen(true);
  };

  const handleAnimationTypeChange = (
    selected_animation_type: AnimationTypesEnum
  ) => {
    setAnimationTypeModalOpen(false);
    setSelectedAnimationType(selected_animation_type);
  };

  return (
    <div id="createEditAnimation">
      <IonHeader translucent className="ion-no-border">
        <Toolbar contents={toolbarContents} />
      </IonHeader>
      <div className="modalContent">
        <div className="container">
          <div className="errorRow">
            {error ? (
              <Typography variant="caption1" color="critical">
                {t("animation-error")}
              </Typography>
            ) : (
              <div></div>
            )}
          </div>
          <div className="animationHeader">
            <div className="animationHeaderTxt">
              <Typography variant="largeTitle" color="white96">
                {t("animation-heading")}
              </Typography>
            </div>
            <div className="animationHeaderIcon">
              {accessories[0].type.image_url ? (
                <img
                  src={accessories[0].type.image_url}
                  alt={accessories[0].name}
                />
              ) : accessories[0].type.category === "MOTOR" ? (
                <IonIcon
                  size="large"
                  icon={squareOutline}
                  className="accessoryIcon"
                />
              ) : (
                <img src={dummyImage} alt={accessories[0].name} />
              )}
            </div>
          </div>
          <Button
            className="animationTypeButton"
            color="elevation03"
            onClick={openAnimationTypeModal}
          >
            <div className="animationType">
              <div className="animationTypeTxt">
                {selectedAnimationType ? (
                  <Typography variant="body1" color="white96">
                    {t("animation-type-" + selectedAnimationType)}
                  </Typography>
                ) : (
                  <Typography variant="body1" color="white96">
                    {t("select-animation-type")}
                  </Typography>
                )}
              </div>
              <div className="animationTypeArrowDiv">
                <ArrowForwardIos className="animationTypeArrow" />
              </div>
            </div>
          </Button>
        </div>
        {selectedAnimationType ? (
          selectedAnimationType === AnimationTypesEnum.PULSE ? (
            <PulseAnimation accessories={accessories} onUpdate={handleUpdate} />
          ) : selectedAnimationType === AnimationTypesEnum.COLOR_TRANSITION ? (
            <ColorTransitionAnimation
              accessories={accessories}
              onUpdate={handleUpdate}
            />
          ) : selectedAnimationType === AnimationTypesEnum.TWINKLE ? (
            <TwinkleAnimation
              accessories={accessories}
              onUpdate={handleUpdate}
            />
          ) : (
            <div></div>
          )
        ) : (
          <div className="animationMsg">
            <Typography variant="body1" color="white32">
              {t("animation-info")}
            </Typography>
          </div>
        )}

        <IonModal
          cssClass="animationTypeModal"
          isOpen={animationTypeModalOpen}
          onDidDismiss={() => setAnimationTypeModalOpen(false)}
        >
          <AnimationType
            closeModal={() => setAnimationTypeModalOpen(false)}
            onAnimationTypeChange={handleAnimationTypeChange}
          />
        </IonModal>
      </div>
    </div>
  );
};

export default withTranslation()(connector(CreateEditAnimation));
