import { getWallColor } from "../../utils/ColorUtil";
import {
  GET_WALLS_REQUEST,
  GET_WALLS_FAILURE,
  GET_WALLS_SUCCESS,
  GET_WALL_REQUEST,
  GET_WALL_FAILURE,
  GET_WALL_SUCCESS,
  CONTROL_WALL_REQUEST,
  CONTROL_WALL_FAILURE,
  CONTROL_WALL_SUCCESS,
  CONTROL_ACCESSORY_REQUEST,
  CONTROL_ACCESSORY_FAILURE,
  CONTROL_ACCESSORY_SUCCESS,
  WALL_LOADING_ON,
  GROUP_ACCESSORY_REQUEST,
  GROUP_ACCESSORY_FAILURE,
  GROUP_ACCESSORY_SUCCESS,
} from "./WallActions";

export interface AccessoryType {
  id: number;
  name: string;
  image_url: string;
  category: string;
}

export interface Config {
  hue?: number;
  saturation?: number;
  brightness?: number;
  animationTransitionTime?: number;
  animationColors?: string[];
  animationDensity?: number;
  animationSpeed?: number;
  uri?: string;
}

export interface AccessoryStateConfig {
  type: string;
  config?: Config;
}

export interface AccessoryState {
  status: string;
  media?: AccessoryStateConfig;
}

export interface Accessory {
  id: number;
  name: string;
  type: AccessoryType;
  easyvis_output_id: string;
  customer_id: string;
  wall_id: number;
  state?: AccessoryState;
  group_id?: string;
  easyvis_device_id: string;
}

export interface Wall {
  id: number;
  name: string;
  customer_id: string;
  accessories: Accessory[];
  state?: string;
  default_scene_id: number;
  active_scene_id: number;
  wall_color?: string;
  partial_state?: boolean;
}

interface State {
  loading: boolean;
  items: Wall[];
  invalid: { [wallId: number]: boolean };
}

const initialStateWalls: State = {
  loading: false, // marks that the walls are currently loading
  items: [],
  invalid: {},
};

export const walls = (state: State = initialStateWalls, action: any) => {
  switch (action.type) {
    case GET_WALLS_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GET_WALLS_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GET_WALLS_SUCCESS:
      var walls: Wall[] = action.response.data;
      // enhance devices with UI state
      walls.sort(function (a, b) {
        return a.id - b.id;
      });
      walls = walls.map((w: Wall, index: number) => {
        w.wall_color = getWallColor(index);
        w.partial_state = false;
        let totalAccCount = w.accessories.length;
        let connectedAccList = w.accessories.filter(
          (a) => a.state?.status !== "DISCONNECTED"
        );
        let totalConnectedAcc = connectedAccList.length;
        let totalDisconnectedAcc = totalAccCount - totalConnectedAcc;
        if (totalConnectedAcc === 0) {
          w.state = "DISCONNECTED";
        } else if (totalConnectedAcc > 0 && totalDisconnectedAcc > 0) {
          w.partial_state = true;
        }
        return w;
      });
      return Object.assign({}, state, {
        items: walls,
        loading: false,
        invalid: {},
      });
    case GET_WALL_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GET_WALL_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GET_WALL_SUCCESS:
      var newWall: Wall = action.response.data;
      // replace existing wall if possible
      return Object.assign({}, state, {
        items: state.items.map((w) => {
          if (newWall.id === w.id) {
            newWall.wall_color = w.wall_color;
            return newWall;
          }
          return w;
        }),
        invalid: { [newWall.id]: false },
        loading: false,
      });
    case CONTROL_WALL_REQUEST:
      return Object.assign({}, state, { loading: true });
    case CONTROL_WALL_FAILURE:
      return Object.assign({}, state, { loading: false });
    case CONTROL_WALL_SUCCESS:
      const wallID = action.wallId;
      return Object.assign({}, state, {
        items: state.items.map((w) => {
          if (wallID === w.id && w.state === "OFF") {
            const newWall = Object.assign({}, w, {
              active_scene_id: null,
            });
            return newWall;
          }
          return w;
        }),
        invalid: Object.assign({}, state.invalid, { [wallID]: true }),
        loading: false,
      });
    case CONTROL_ACCESSORY_REQUEST:
      // Update the accessory state for the wall (this is done to get an instant feedback in the UI).
      // The state of the accessory might be overwritten by a server call later
      const wallId = action.wallId;
      const accessoryId = action.accessoryId;
      const accesssoryNewState = action.state;
      const accessoryIds = String(accessoryId).split(",");
      return Object.assign({}, state, {
        items: state.items.map((w) => {
          if (wallId === w.id) {
            const newWall = Object.assign({}, w, {
              accessories: w.accessories.map((a) => {
                if (accessoryIds.includes(String(a.id))) {
                  const newObj = Object.assign({}, a, {
                    state: accesssoryNewState,
                  });
                  return newObj;
                } else {
                  return a;
                }
              }),
            });
            return newWall;
          }
          return w;
        }),
      });
    case CONTROL_ACCESSORY_FAILURE:
      return Object.assign({}, state, { loading: false });
    case CONTROL_ACCESSORY_SUCCESS:
      const wall_Id = action.wallId;
      const accId = action.accIds;
      const accNewState = action.state;
      const accIds = String(accId).split(",");
      return Object.assign({}, state, {
        items: state.items.map((w) => {
          if (wall_Id === w.id) {
            const newWall = Object.assign({}, w, {
              active_scene_id: null,
              accessories: w.accessories.map((a) => {
                if (accIds.includes(String(a.id))) {
                  const newObj = Object.assign({}, a, {
                    state: accNewState,
                  });
                  return newObj;
                } else {
                  return a;
                }
              }),
            });
            return newWall;
          }
          return w;
        }),
        loading: false,
      });
    case GROUP_ACCESSORY_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GROUP_ACCESSORY_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GROUP_ACCESSORY_SUCCESS:
      return Object.assign({}, state, { loading: false });
    case WALL_LOADING_ON:
      return Object.assign({}, state, { loading: true });
    default:
      return state;
  }
};
