import React from "react";
import "./CreateEditGroupedListItem.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { IonItem, IonCheckbox, IonLabel } from "@ionic/react";
import { Accessory } from "../../../data/wall/Walls";
import Typography from "../../common/Typography";
import AccessoryListItemTypes from "../AccessoryListItemTypes";

interface CreateEditUngroupedListItemProps {
  accessory: Accessory;
  isChecked?: boolean;
  onSelectionchange(e: any, isGroup: boolean): void;
  position: number;
}

type Props = WithTranslation & CreateEditUngroupedListItemProps;

const CreateEditUngroupedListItem: React.FC<Props> = (props) => {
  const { accessory, isChecked, onSelectionchange, position } = props;

  //invoked whenever an accessory / group is selected or unselected
  const handleSelection = (e: any) => {
    onSelectionchange(e, false);
  };

  return (
    <div id="createEditGroupedListItem">
      <IonItem key={accessory.id} className="accessoryItem ion-no-padding">
        <div className="accessoryItemContainer">
          <IonLabel className="accessoryPosition">
            <Typography variant="body1" color="white32">
              {position + 1}
            </Typography>
          </IonLabel>
          <div className="accessoryDiv">
            <AccessoryListItemTypes accessories={[accessory]} />
          </div>
          <IonCheckbox
            slot="end"
            value={String(accessory.id)}
            checked={isChecked}
            onIonChange={(e) => handleSelection(e)}
            className="accessoryCheckbox"
          />
        </div>
      </IonItem>
    </div>
  );
};

export default withTranslation()(CreateEditUngroupedListItem);
