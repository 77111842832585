import React, { useState, useEffect } from "react";
import "./SceneListItem.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Scene } from "../../data/scene/Scenes";
import { IonCol, IonGrid, IonIcon } from "@ionic/react";
import Typography from "../common/Typography";
import { Wall } from "../../data/wall/Walls";
import MoreHorizontal from "../../assets/images/more_horiz-white.svg";
import { cronToTimeAndDay } from "../../utils/DateTime";
import { Link } from "react-router-dom";
import { play, pause } from "ionicons/icons";
import Button from "../common/Button";
import Media from "react-media";

interface SceneListItemProps {
  scene: Scene;
  wall: Wall;
  onClickControl(scene: Scene): void;
}

type Props = WithTranslation & SceneListItemProps;

const SceneListItem: React.FC<Props> = (props) => {
  const { scene, wall, onClickControl, t } = props;
  const isActiveScene =
    wall?.state === "ON" &&
    wall?.active_scene_id &&
    +wall?.active_scene_id === +scene.id
      ? true
      : false;
  const sceneStateBoxActive = isActiveScene
    ? "sceneStateBoxActive"
    : "sceneStateBoxInActive";

  const activeSceneIcon = isActiveScene ? pause : play;
  const sceneStateIconStyle = isActiveScene
    ? "activeSceneStateIcon"
    : "inactiveSceneStateIcon";
  const sceneNameColor = isActiveScene
    ? "activeSceneName"
    : "inactiveSceneName";
  const activeSceneNameColor = isActiveScene ? "primaryColor" : "white96";
  const [selectedTime, setSelectedTime] = useState("");
  const sceneAutomationScheduleDefault = {
    id: 0,
    action: "OFF",
    active: false,
    cron: "",
    last_execution: "",
    scene_id: 0,
  };
  const [sceneAutomationSchedule] = useState(sceneAutomationScheduleDefault);

  useEffect(() => {
    if (scene.scene_schedules && scene.scene_schedules.length > 0) {
      Object.assign(sceneAutomationSchedule, scene.scene_schedules[0]);
      displaySelectedTime(sceneAutomationSchedule.cron);
    } else {
      displaySelectedTime("");
    }
  }, [scene.scene_schedules, sceneAutomationSchedule]);

  function changeScene() {
    onClickControl(scene);
  }

  const displaySelectedTime = (cron: string) => {
    setSelectedTime(cronToTimeAndDay(cron, t));
  };

  function showSceneItemOnMobile() {
    return (
      <div className="scene sceneListItem sceneTitleRow sceneTitleRowHeight">
        <div className="sceneListItemCol">
          <div className="sceneStateBoxDiv">
            <Button
              color="transparent"
              className={`sceneStateBox ${sceneStateBoxActive}`}
              onClick={changeScene}
            >
              <div>
                <div>
                  <div className={`${sceneStateIconStyle}`}>
                    <IonIcon
                      className="sceneStateIconMobile"
                      icon={activeSceneIcon}
                    />
                  </div>
                </div>
              </div>
            </Button>
          </div>
          <div className="sceneActions">
            <Link to={`/wall/${wall?.id}/scene/${scene.id}`}>
              <img src={MoreHorizontal} alt="more" className="moreIcon"></img>
            </Link>
          </div>
        </div>
        <div className="sceneNameSchedule">
          <Button
            color="transparent"
            className="sceneNameScheduleButton"
            onClick={changeScene}
          >
            <div className="sceneContent">
              <div className="sceneName">
                <Typography
                  variant="heading1"
                  color={`${activeSceneNameColor}`}
                  className={`${sceneNameColor}`}
                >
                  {scene.name}
                </Typography>
              </div>
              <div className="sceneSchedule">
                {selectedTime.length > 0 ? (
                  <Typography variant="body2" color="white64">
                    {selectedTime.replace(/,/g, ", ")}
                  </Typography>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </Button>
        </div>
      </div>
    );
  }

  function showSceneItemOnDesktop() {
    return (
      <IonGrid className="ion-no-padding sceneGridNoScrolling">
        <div className="scene sceneListItem sceneTitleRow sceneTitleRowHeight">
          <div className="sceneStateBoxDiv">
            <Button
              color="transparent"
              className={`sceneStateBox ${sceneStateBoxActive}`}
              onClick={changeScene}
            >
              <div>
                <div>
                  <div className={`${sceneStateIconStyle}`}>
                    <IonIcon
                      className="sceneStateIcon"
                      icon={activeSceneIcon}
                    />
                  </div>
                </div>
              </div>
            </Button>
          </div>
          <div className="sceneNameSchedule">
            <Button
              color="transparent"
              className="sceneNameScheduleButton"
              onClick={changeScene}
            >
              <div className="sceneContent">
                <div className="sceneName">
                  <Typography
                    variant="heading1"
                    color={`${activeSceneNameColor}`}
                    className={`${sceneNameColor}`}
                  >
                    {scene.name}
                  </Typography>
                </div>
                <div className="sceneSchedule">
                  {selectedTime.length > 0 ? (
                    <Typography variant="body2" color="white64">
                      {selectedTime}
                    </Typography>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </Button>
          </div>
          <div className="sceneActionsDivider"></div>
          <div className="sceneActions">
            <Link to={`/wall/${wall?.id}/scene/${scene.id}`}>
              <img src={MoreHorizontal} alt="more" className="moreIcon"></img>
            </Link>
          </div>
        </div>
      </IonGrid>
    );
  }

  return (
    <IonCol size-sm="12" size-md="12" size-lg="6" className="ion-no-padding">
      <Media queries={{ small: "(max-width: 599px)" }}>
        {(matches) =>
          matches.small ? showSceneItemOnMobile() : showSceneItemOnDesktop()
        }
      </Media>
    </IonCol>
  );
};

export default withTranslation()(SceneListItem);
