import React, { useRef, useEffect } from "react";
import "./ColorWheel.scss";
import { IonLabel, IonGrid, IonRow, IonCol } from "@ionic/react";
import Slider from "../common/Slider";
import { Accessory, Config } from "../../data/wall/Walls";
import {
  convertRGBToHSB,
  convertRGBToHEX,
  convertDegreeToRGB,
  convertHSVToHEX,
} from "../../utils/ColorUtil";
import { throttle } from "lodash";
import Typography from "../common/Typography";

interface ColorProps {
  onChangeComplete(color: Config): void;
  color: Config;
  displayBrightness?: string;
  accessory?: Accessory;
  showColorWheel?: boolean;
}

const ColorWheel: React.FC<ColorProps> = (props) => {
  const {
    onChangeComplete,
    color,
    displayBrightness,
    accessory,
    showColorWheel,
  } = props;
  const onChangeCall = (color: Config) => onChangeComplete(color);
  const onChangeThrottle = throttle(onChangeCall, 500);
  const colorWheelRef: any = useRef(); //Outside div of Canvas to get the co-ordinates of canvas
  const canvasRef: any = useRef(null); //Canvas reference
  const canvasSelectionRef: any = useRef(null); //small white circle on canvas to show the selected the color
  const selectedColorRef: any = useRef(null); //To display the selected color in a small box

  //To hold current HSB values
  let hsb = {
    hue: color.hue,
    saturation: color.saturation,
    brightness:
      color.brightness! <= 1 ? color.brightness! * 100 : color.brightness,
  };
  //let colorBrightness = color.brightness <= 1 ? color.brightness * 100 : color.brightness;
  //Draw color wheel canvas
  const drawColorWheel = () => {
    const canvasObj = canvasRef.current!;
    const ctx = canvasObj.getContext("2d");
    let mousedown = false;

    let radius = canvasObj.width / 2;
    let toRad = (2 * Math.PI) / 360;
    let step = 1 / radius;

    ctx.clearRect(0, 0, canvasObj.width, canvasObj.height);

    let cx = radius;
    let cy = radius;
    for (var i = 0; i < 360; i += step) {
      var rad = i * toRad;
      var x = radius * Math.cos(rad),
        y = radius * Math.sin(rad);

      ctx.strokeStyle = "hsl(" + i + ", 100%, 50%)";
      ctx.beginPath();
      ctx.moveTo(radius, radius);
      ctx.lineTo(cx + x, cy + y);
      ctx.stroke();
    }

    // draw saturation gradient
    var grd = ctx.createRadialGradient(cx, cy, 0, cx, cx, radius);
    grd.addColorStop(0, "white");
    grd.addColorStop(1, "rgba(255, 255, 255, 0)");
    ctx.fillStyle = grd;
    ctx.beginPath();
    ctx.arc(cx, cy, radius, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();

    canvasObj.onmousemove = (e: any) => {
      if (!mousedown) return false;
      handleTheEvent(e);
      //onChangeThrottle({
        //hue: hsb.hue,
        //saturation: hsb.saturation,
        //brightness: hsb.brightness! / 100,
      //});
    };

    canvasObj.ontouchmove = (e: any) => {
      if (!mousedown) return false;
      handleTheEvent(e);
      //onChangeThrottle({
        //hue: hsb.hue,
        //saturation: hsb.saturation,
        //brightness: hsb.brightness! / 100,
      //});
    };

    canvasObj.onmousedown = (e: any) => {
      e.preventDefault();
      mousedown = true;
      handleTheEvent(e);
    };

    canvasObj.ontouchstart = (e: any) => {
      e.preventDefault();
      mousedown = true;
      handleTheEvent(e);
    };

    canvasObj.onmouseup = (e: any) => {
      mousedown = false;
      onChangeCall({
        hue: hsb.hue,
        saturation: hsb.saturation,
        brightness: hsb.brightness! / 100,
      });
    };

    canvasObj.ontouchend = (e: any) => {
      mousedown = false;
      onChangeCall({
        hue: hsb.hue,
        saturation: hsb.saturation,
        brightness: hsb.brightness! / 100,
      });
    };
  };

  //Following function is to get the current mouse position
  const getPosition = (el: any) => {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      y: rect.top + scrollTop,
      x: rect.left + scrollLeft,
      width: rect.width,
      height: rect.height,
    };
  };

  //Following function is to handle the mouse position on color wheel
  const handleTheEvent = (e: any) => {
    var el_pos = getPosition(canvasRef.current!);
    var m_pos = e.touches
      ? { x: e.touches[0].pageX, y: e.touches[0].pageY }
      : { x: e.pageX, y: e.pageY };
    var rad = el_pos.width / 2;

    var x = m_pos.x - el_pos.x - rad;
    var y = m_pos.y - el_pos.y - rad;
    var a = Math.round(((Math.atan2(y, x) * 180) / Math.PI) * 100) / 100;
    var d = Math.sqrt(x * x + y * y) / rad;

    if (d > 1) {
      x = x * (1 / d);
      y = y * (1 / d);
    }
    const TOP = ((y + rad) / (rad * 2)) * 100 + "%";
    const LEFT = ((x + rad) / (rad * 2)) * 100 + "%";

    canvasSelectionRef.current!.style.top = TOP;
    canvasSelectionRef.current!.style.left = LEFT;

    d = Math.min(d, 1);
    var v = Math.round(((1 - d) * 50 + 50) * 100) / 100;
    const RGB = convertDegreeToRGB(a, 100, v); //Get the RGB from canvas where the mouse or touch is event is release
    var hexColor = convertRGBToHEX(
      Math.round(RGB.red),
      Math.round(RGB.green),
      Math.round(RGB.blue)
    );

    canvasSelectionRef.current!.style.backgroundColor = hexColor; //Show the selected color on modal dialog
    selectedColorRef.current.style.backgroundColor = hexColor; //Show the selected color on modal dialog

    //Now get Hue and Saturation from the selected RGB color from wheel
    const HS = convertRGBToHSB(RGB.red, RGB.green, RGB.blue);
    hsb = { hue: HS.h, saturation: HS.s, brightness: hsb.brightness }; //Set the new HSB color
  };

  const presetColorOnWheel = () => {
    const canvasObj = canvasRef.current!;
    const canvasOffsetWidth =
      canvasObj.offsetWidth > 0 ? canvasObj.offsetWidth : 96.015625;
    const radius = canvasOffsetWidth * hsb.saturation!;
    const radians = hsb.hue! * ((2 * Math.PI) / 360);
    let x = radius * Math.cos(radians);
    let y = radius * Math.sin(radians);
    var d = Math.sqrt(x * x + y * y) / canvasOffsetWidth;

    if (d > 1) {
      x = x * (1 / d);
      y = y * (1 / d);
    }
    const TOP = ((y + canvasOffsetWidth) / (canvasOffsetWidth * 2)) * 100 + "%";
    const LEFT =
      ((x + canvasOffsetWidth) / (canvasOffsetWidth * 2)) * 100 + "%";
    const PRESET_COLOR = convertHSVToHEX(hsb.hue, hsb.saturation, 1);

    canvasSelectionRef.current!.style.top = TOP;
    canvasSelectionRef.current!.style.left = LEFT;
    canvasSelectionRef.current!.style.backgroundColor = PRESET_COLOR;
  };

  //Following hook is executed to draw the color wheel
  useEffect(() => {
    if (showColorWheel) {
      drawColorWheel();
      setTimeout(() => {
        presetColorOnWheel();
      }, 400);
    }
  }, []); //[] means the drawColorWheel() will be executed only once

  //Callback function for slider component
  const changeBrightness = (brightnessVal: number) => {
    hsb.brightness = brightnessVal;
    selectedColorRef.current.style.backgroundColor = convertHSVToHEX(
      hsb.hue,
      hsb.saturation,
      hsb.brightness / 100
    ); //Show the selected color on modal dialog
    onChangeThrottle({
      hue: hsb.hue,
      saturation: hsb.saturation,
      brightness: hsb.brightness / 100,
    });
  };

  return (
    <div>
      {showColorWheel ? (
        <div ref={colorWheelRef} id="colorWheel" className="colorWheelCss">
          <canvas
            ref={canvasRef}
            className="colorCanvas"
            width="100"
            height="100"
          />
          <div
            ref={canvasSelectionRef}
            className="colorSelectionWheel"
            title="Move to select color"
          />
        </div>
      ) : (
        <div></div>
      )}

      <div
        id="colorBrightness"
        className={`${showColorWheel ? "" : "colorBrightnessDiv"}`}
      >
        <div>
          {accessory && accessory.type.image_url ? (
            <div key={accessory.id} className="imageContainer">
              <img
                key={accessory.id}
                src={accessory.type.image_url}
                alt={accessory.name}
              />
            </div>
          ) : accessory && accessory.type.category === "MOTOR" ? (
            <div key={accessory.id} className="numberCircle">
              <Typography variant="body1" color="white96">
                M
              </Typography>
            </div>
          ) : accessory ? (
            <div key={accessory.id} className="numberCircle">
              <Typography variant="body1" color="white96">
                D
              </Typography>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="colorBrightnessRangeLabel">
                <IonLabel>
                  <Typography
                    variant="heading1"
                    color="white96"
                    className="brightnessLabelColor"
                  >
                    {displayBrightness}
                  </Typography>
                </IonLabel>
              </div>
            </IonCol>
            <IonCol>
              <div className="colorBrightnessRangeLabelPercent">
                <IonLabel>
                  <Typography variant="title3" color="white64">
                    {Math.floor(hsb.brightness!)}%
                  </Typography>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="colorBrightnessRange">
          <Slider
            min={0}
            max={100}
            value={hsb.brightness!}
            onChange={(e) => changeBrightness(e)}
            className="range"
            debounce={500}
            sliderColor={
              showColorWheel
                ? convertHSVToHEX(hsb.hue, color.saturation, 1)
                : "#FFF"
            }
          />
        </div>
      </div>
      <div id="selectedColor">
        <div ref={selectedColorRef} className="selectedColorVal" />
      </div>
    </div>
  );
};

export default ColorWheel;
