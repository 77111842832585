import React, {ReactNode} from "react";
import "./Button.scss";
import {IonButton } from "@ionic/react";

interface ButtonProps extends React.HTMLAttributes<HTMLIonButtonElement> {
  variant?: String;
  children: ReactNode;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { variant, children, ...attributes} = props;
  const finalVariant = variant || 'primary';
  return (
    <IonButton className={`${finalVariant}`} {...attributes}>
      {children}
    </IonButton>
  );
}

export default Button;
