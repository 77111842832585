import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonPage,
  IonCard,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
} from "@ionic/react";
import "./More.scss";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import { version } from "../../../package.json";
import { withTranslation, WithTranslation } from "react-i18next";
import Typography from "../../components/common/Typography";
import MailTo from "../../components/common/MailTo";
const mapStateToProps = (state: any) => ({
  customer: state.user.customer,
});

type Props = WithTranslation & ReturnType<typeof mapStateToProps>;

const More: React.FC<Props> = (props) => {
  const { customer } = props;
  let history = useHistory();
  const [userAccountEmail, setUserAccountEmail] = useState("");

  useEffect(() => {
    if (userAccountEmail === "") {
      getAccountInfo();
    }
  }, [userAccountEmail]);

  // get user email
  const getAccountInfo = async () => {
    await Auth.currentAuthenticatedUser().then((data) => {
      setUserAccountEmail(data["attributes"]["email"]);
    });
  };

  async function Logout() {
    await Auth.signOut().then(() => history.replace("/"));
  }

  /* function Mailto({ ...props }) {
    return (
      <a
        className="moreReportProblem"
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:${props.email}?subject=${
          encodeURIComponent(props.subject) || ""
        }`}
      >
        {props.children}
      </a>
    );
  } */

  const refresh = (e: CustomEvent) => {
    getAccountInfo();
    e.detail.complete();
  };

  return (
    <IonPage id="MoreView">
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonGrid>
          <IonRow>
            <IonCol
              size-sm="12"
              size-md="6"
              offset-md="3"
              size-lg="6"
              offset-lg="3"
              size-xl="4"
              offset-xl="4"
              className="moreContainer"
            >
              <IonCard className="moreCard">
                <div className="icon">
                  <SentimentSatisfiedIcon
                    fontSize="small"
                    className="moreCardIonThumbnailIcon"
                  />
                </div>
                <div className="labels">
                  <Typography variant="caption1" color="white64">
                    {props.t("more-account")}
                  </Typography>
                  <Typography variant="body1">
                    {customer ? customer.name : "No customer account"} (
                    {userAccountEmail})
                  </Typography>
                </div>
              </IonCard>

              <Typography
                variant="heading1"
                color="white96"
                className="moreNeedHelp"
              >
                {props.t("more-need-help")}?
              </Typography>

              <Typography
                variant="body1"
                color="white64"
                className="moreNeedHelpText"
              >
                {props.t("more-need-help-text")}
              </Typography>

              <MailTo email="info@magnwall.com" subject="Report your problem">
                {props.t("more-report-problem")}
              </MailTo>

              <IonButton
                className="moreLogoutButton"
                expand="full"
                onClick={Logout}
              >
                {props.t("logout")}
              </IonButton>

              <div className="ion-text-center">
                <Typography variant="body1" color="white32">
                  {props.t("more-version")}: {version}
                </Typography>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(connect(mapStateToProps, null)(More));
