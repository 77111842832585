import React, { useEffect } from "react";
import "./MediaFilesList.scss";
//import { getPhotos } from "../../utils/photos-api-client";
//import { EventPhoto } from "../../../../../services/common/schemas/photos-api";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getMedias,
  deleteMedia,
  removeFromDevice,
  downloadToDevice,
  getMediasForLocal,
} from "../../data/mediamanagement/MediaActions";
import Spinner from "../common/Spinner";
import MediaListItem from "./medialistitem/MediaListItem";
import { Media } from "../../data/mediamanagement/Medias";
import { IonIcon, useIonViewWillEnter } from "@ionic/react";
import cloud from "../../assets/images/cloud.svg";
import folder from "../../assets/images/folder.svg";
import Typography from "../common/Typography";
import { Accessory } from "../../data/wall/Walls";

interface mediaFilesListProps {
  refreshTime: number;
  mediaToDisplay: string;
  onSelectMedia(selectedMedia: Media, local: boolean): void;
  accessory: Accessory;
}

const mapStateToProps = (state: any) => ({
  medias: state.medias.items,
  mediaLoading: state.medias.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMedias: (devices: string[]) => dispatch(getMedias(devices)),
  getMediasForLocal: (devices: string[]) =>
    dispatch(getMediasForLocal(devices)),

  deleteMedia: (mediaId: string, deviceId: string) =>
    dispatch(deleteMedia(mediaId, deviceId)),
  removeFromDevice: (mediaId: string, deviceId: string) =>
    dispatch(removeFromDevice(mediaId, deviceId)),
  downloadToDevice: (mediaId: string, deviceId: string) => {
    dispatch(downloadToDevice(mediaId, deviceId));
  },
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  mediaFilesListProps;

const MediaFilesList: React.FC<Props> = (props) => {
  const {
    t,
    medias,
    mediaLoading,
    getMedias,
    getMediasForLocal,
    refreshTime,
    mediaToDisplay,
    deleteMedia,
    removeFromDevice,
    downloadToDevice,
    onSelectMedia,
    accessory,
  } = props;

  useIonViewWillEnter(() => {
    getMedias([accessory.easyvis_device_id]);
  });
  // Just run at first time to laod all data
  useEffect(() => {
    console.log("Loading media data");
    getMedias([accessory.easyvis_device_id]);
    const abortController = new AbortController();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      getMediasForLocal([accessory.easyvis_device_id]);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  // array which stores medias on device
  const mediaOnDevice = [];
  for (let i = 0; i < medias.length; i++) {
    let media: Media = medias[i];

    if (media.on_local_easyvis_device && media.on_local_easyvis_device.length) {
      let len = media.on_local_easyvis_device.length;

      for (let j = 0; j < len; j++) {
        if (
          media.on_local_easyvis_device[j].device_id ===
            accessory.easyvis_device_id &&
          (media.on_local_easyvis_device[j].state === "downloaded" ||
            media.on_local_easyvis_device[j].state === "downloading")
        ) {
          mediaOnDevice.push(media);
        }
      }
    }
  }

  // array which stores medias on cloud
  const mediaOnCloud = [];
  for (let i = 0; i < medias.length; i++) {
    let media: Media = medias[i];
    let len = media.on_local_easyvis_device.length;

    if (len < 1) {
      mediaOnCloud.push(media);
    }
  }

  const handleDeleteSelected = (selectedMediaId: string, deviceId: string) => {
    //console.log(`Selected media to delete is ${selectedMediaId}`);
    deleteMedia(selectedMediaId, deviceId);
  };

  const handleSelectedMedia = (selectedMedia: Media, local: boolean) => {
    onSelectMedia(selectedMedia, local);
  };

  const handleRemoveFromDeviceSelected = (
    selectedMediaId: string,
    deviceId: string
  ) => {
    removeFromDevice(selectedMediaId, deviceId);
  };

  const handleDownloadSelected = (
    selectedMediaId: string,
    deviceId: string
  ) => {
    downloadToDevice(selectedMediaId, deviceId);
  };

  return (
    <div>
      {mediaLoading ? (
        <Spinner style={{ height: 50 }} />
      ) : (
        <div>
          {mediaOnDevice.length > 0 ? (
            <div>
              <div className="mediaHeading">
                <IonIcon icon={folder} className="media-platform-icon" />
                <Typography variant="body2" color="white64">
                  {t("media-from-device")}
                </Typography>
              </div>
              {mediaOnDevice.map((media: Media) => {
                var mediaListItem = media.content_type.startsWith(
                  mediaToDisplay
                ) ? (
                  <MediaListItem
                    key={media.id}
                    media={media}
                    local={true}
                    onSelect={handleSelectedMedia}
                    onDelete={handleDeleteSelected}
                    onRemoveFromDevice={handleRemoveFromDeviceSelected}
                    onDownloadToDevice={handleDownloadSelected}
                    accessory={accessory}
                  />
                ) : (
                  <div key={media.id}></div>
                );
                return mediaListItem;
              })}
            </div>
          ) : (
            <div></div>
          )}
          {mediaOnCloud.length > 0 ? (
            <div>
              <div className="mediaHeading">
                <IonIcon icon={cloud} className="media-platform-icon" />
                <Typography variant="body2" color="white64">
                  {t("media-from-cloud")}
                </Typography>
              </div>
              {mediaOnCloud.map((media: Media) => {
                var mediaListItem = media.content_type.startsWith(
                  mediaToDisplay
                ) ? (
                  <MediaListItem
                    key={media.id}
                    media={media}
                    local={false}
                    onSelect={handleSelectedMedia}
                    onDelete={handleDeleteSelected}
                    onRemoveFromDevice={handleRemoveFromDeviceSelected}
                    onDownloadToDevice={handleDownloadSelected}
                    accessory={accessory}
                  />
                ) : (
                  <div key={media.id}></div>
                );
                return mediaListItem;
              })}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MediaFilesList)
);
