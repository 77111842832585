import React, { ReactNode } from "react";
import "./Toolbar.scss";
import { IonToolbar, IonButtons, IonButton, IonTitle } from "@ionic/react";
import Typography from "./Typography";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Button from "./Button";

interface SlotContents {
  on_click?: any;
  label?: string;
  show_icon?: boolean;
  router_link?: string;
  show_image?: boolean;
  show_html?: boolean;
  html_content?: ReactNode;
  image_details?: {
    src: string;
    alt: string;
  };
}
export interface ToolbarContents {
  left?: SlotContents;
  middle?: SlotContents;
  right?: SlotContents;
}

const RightEmptySlot = () => {
  return (
    <IonButtons slot="end" className="end">
      <Button color="white96" className="toolbarButtons">
        <Typography variant="body1" className="toolbarLinks">
          &nbsp;
        </Typography>
      </Button>
    </IonButtons>
  );
};

const EndSlots = (props: any) => {
  const arrow =
    props.direction === "back" ? (
      <ArrowBackIos className="arrows" />
    ) : (
      <ArrowForwardIos className="arrows" />
    );
  const slotPos = props.direction === "back" ? "start" : "end";
  return (
    <IonButtons slot={slotPos} className={slotPos}>
      {props.show_icon ? (
        props.on_click ? (
          <Button
            className="toolbarButtons"
            color="white96"
            onClick={props.on_click}
          >
            {arrow}
          </Button>
        ) : (
          <IonButton
            className="toolbarButtons"
            color="white96"
            routerLink={props.router_link}
            routerDirection="back"
          >
            {arrow}
          </IonButton>
        )
      ) : (
        <Button
          color="white96"
          onClick={props.on_click}
          className="toolbarButtons"
        >
          <Typography variant="body1" className="toolbarLinks">
            {props.label}
          </Typography>
        </Button>
      )}
    </IonButtons>
  );
};

const LeftSlot = (props: any) => {
  return <EndSlots {...props} direction="back" />;
};

const RightSlot = (props: any) => {
  return <EndSlots {...props} direction="forward" />;
};

const MiddleSlot = (props: any) => {
  return props.show_image ? (
    <IonTitle className="ionTitle">
      <img
        src={props.image_details.src}
        alt={props.image_details.alt}
        className="logo"
      />
    </IonTitle>
  ) : props.show_html ? (
    <div>{props.html_content}</div>
  ) : (
    <IonTitle className="ionTitle">
      <Typography variant="heading1" color="white96" className="toolbarTitle">
        {props.label}
      </Typography>
    </IonTitle>
  );
};

interface ToolbarProps extends React.HTMLAttributes<HTMLIonToolbarElement> {
  variant?: String;
  contents: ToolbarContents;
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { variant, contents } = props;
  const finalVariant = variant || "toolbar";

  return (
    <IonToolbar className={`${finalVariant}`}>
      {contents.left ? <LeftSlot {...contents.left} /> : <div></div>}
      {contents.middle ? <MiddleSlot {...contents.middle} /> : <div></div>}
      {contents.right ? <RightSlot {...contents.right} /> : <RightEmptySlot />}
    </IonToolbar>
  );
};

export default Toolbar;
