import React from "react";
import "./SceneListContents.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import { IonRow, IonCol, IonIcon, IonButton } from "@ionic/react";
import { Scene } from "../../data/scene/Scenes";
import SceneListItem from "../scenelistitem/SceneListItem";
import { Wall } from "../../data/wall/Walls";
import { add } from "ionicons/icons";
import Typography from "../common/Typography";
import Media from "react-media";

interface SceneListContentProps {
  sceneList: any;
  wall: Wall;
  controlScene(scene: Scene): void;
}
type Props = WithTranslation & SceneListContentProps;

const SceneListContents: React.FC<Props> = (props) => {
  const { sceneList, wall, controlScene, t } = props;

  return sceneList.length > 0 ? (
    <Media queries={{ small: "(max-width: 599px)" }}>
      {(matches) =>
        matches.small ? (
          <div className="scrollingSceneList">
            {sceneList.map((scene: Scene) => (
              <SceneListItem
                key={scene.id}
                scene={scene}
                wall={wall}
                onClickControl={controlScene}
              />
            ))}
          </div>
        ) : (
          <IonRow className="sceneRow ion-no-padding">
            {sceneList.map((scene: Scene) => (
              <SceneListItem
                key={scene.id}
                scene={scene}
                wall={wall}
                onClickControl={controlScene}
              />
            ))}
          </IonRow>
        )
      }
    </Media>
  ) : (
    <div className="noSceneRow">
      <div className="addmark">
        <IonButton
          color="transparent"
          routerLink={`/wall/${wall?.id}/scene`}
          className="createFirstSceneButton addmarkPosition ion-no-padding"
        >
          <IonIcon icon={add} className="addIcon" />
        </IonButton>
      </div>
      <div className="firstSceneButtonCol">
        <IonButton
          color="transparent"
          routerLink={`/wall/${wall?.id}/scene`}
          className="createFirstSceneButton firstSceneButton"
        >
          <Typography variant="heading1" className="createNoSceneTitle">
            {t("create-first-scene")}
          </Typography>
        </IonButton>
      </div>
    </div>
  );
};

export default withTranslation()(SceneListContents);
