import {
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_SUCCESS,
} from "./UserActions";

export interface Customer {
  id: number;
  name: string;
  magnwall_internal_id: number;
  address: string;
  city: string;
  postal_code: string;
  country: string;
}

interface State {
  loading: boolean;
  customer?: Customer;
}

const initialStateUser: State = {
  loading: false,
};

export const user = (state: State = initialStateUser, action: any) => {
  switch (action.type) {
    case GET_CUSTOMER_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GET_CUSTOMER_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GET_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        customer: action.response.data,
        loading: false,
      });
    default:
      return state;
  }
};
