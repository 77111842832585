import React from "react";
import "./CreateSceneInput.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import Typography from "../../components/common/Typography";
import { IonRow, IonCol, IonInput } from "@ionic/react";

interface CreateSceneInputProps {
  sceneName: string;
  error: string;
  setSceneName(sceneName: string): void;
}
type Props = WithTranslation & CreateSceneInputProps;

const CreateSceneInput: React.FC<Props> = (props) => {
  const { sceneName, error, setSceneName, t } = props;

  //In the name input if enter key is pressed then move the focus away from this field
  const handleEnterKey = (e: any) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };
  return (
    <div id="createSceneAccessories">
      <IonRow>
        <IonCol
          offset-sm="0"
          size-sm="12"
          offset-md="1"
          offset-lg="2"
          size-md="8"
        >
          <IonInput
            key="name"
            type="text"
            name="name"
            value={sceneName}
            placeholder={t("new-scene-placeholder")}
            className="sceneName"
            onIonChange={(e) => setSceneName(e.detail.value!)}
            onKeyDown={(e) => handleEnterKey(e)}
            clearInput
          ></IonInput>
        </IonCol>
      </IonRow>
      {error ? (
        <IonRow>
          <IonCol
            offset-sm="0"
            size-sm="12"
            offset-md="1"
            offset-lg="2"
            size-md="8"
          >
            <Typography variant="caption1" color="critical">
              {t(error)}
            </Typography>
          </IonCol>
        </IonRow>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default withTranslation()(CreateSceneInput);
