import React, { useState, useEffect } from "react";
import "./PulseAnimation.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Accessory, AccessoryState } from "../../../data/wall/Walls";
import { convertHSVToHEX, convertHEXToHSB } from "../../../utils/ColorUtil";
import Typography from "../../common/Typography";
import ColorPicker from "../../colorpicker/ColorPicker";
import { IonInput } from "@ionic/react";

export interface Color {
  hue: number;
  saturation: number;
  brightness: number;
}

interface PulseAnimationProps {
  accessories: Accessory[];
  onUpdate(animation: AccessoryState): void;
}

type Props = WithTranslation & PulseAnimationProps;

const PulseAnimation: React.FC<Props> = (props) => {
  const { accessories, onUpdate, t } = props;

  const [color, setColor] = useState<Color>({
    hue: 58,
    saturation: 1,
    brightness: 1,
  });
  const [transitionTime, setTransitionTime] = useState<number>(2);

  // Initial value setup (if animation values already set for the accessory)
  useEffect(() => {
    if (
      accessories[0].state?.media?.config?.animationColors &&
      accessories[0].state?.media?.config?.animationColors.length > 0
    ) {
      var colorInput = convertHEXToHSB(
        accessories[0].state.media?.config.animationColors[0]
      );
      console.log(
        "Setting initial color for animation: (%s, %s, %s)",
        colorInput.h,
        colorInput.s,
        colorInput.b
      );
      handleColorChange(colorInput.h!, colorInput.s, colorInput.b);
    }
    if (accessories[0].state?.media?.config?.animationTransitionTime) {
      console.log(
        "Setting initial transition time for animation: %s",
        accessories[0].state?.media?.config?.animationTransitionTime
      );
      setTransitionTime(
        accessories[0].state?.media?.config?.animationTransitionTime
      );
    }

    //Update the config with initial values
    updateConfig();
  }, [accessories]);

  // Update the config whenever one of the elements in the animation changes
  useEffect(() => {
    updateConfig();
  }, [color, transitionTime]);

  function handleColorChange(
    hue: number,
    saturation: number,
    brightness: number
  ) {
    setColor({
      ...color,
      hue: hue,
      saturation: saturation,
      brightness: brightness,
    });
  }

  const handleTransitionTimeChange = (transitionTime: number) => {
    setTransitionTime(transitionTime);
  };

  const updateConfig = () => {
    const newConfig = {
      status: "ON",
      media: {
        type: "ANIMATION_PULSE_BRIGHTNESS",
        config: {
          animationTransitionTime: transitionTime,
          animationColors: [
            convertHSVToHEX(color.hue, color.saturation, color.brightness),
          ],
        },
      },
    };
    onUpdate(newConfig);
  };

  const handleEnterKey = (e: any) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <div id="pulseAnimation" className="pulseContainer">
      <div id="colorAnimation">
        <div className="colorHeading">
          <Typography variant="heading1" color="white96">
            {t("animation-color")}
          </Typography>
        </div>
        <div className="colorPicker">
          <ColorPicker
            color={color}
            colorChangeCallback={handleColorChange}
            style="full"
          />
        </div>
        <div className="colorTxt">
          <Typography variant="caption1" color="white64">
            {t("animation-color-txt")}
          </Typography>
        </div>
      </div>
      <div id="animationDuration">
        <div className="intervalHeading">
          <Typography variant="heading1" color="white96">
            {t("animation-interval")}
          </Typography>
        </div>
        <div className="durationSelector">
          <div className="durationTitle">
            <Typography variant="body1" color="white96">
              {t("animation-duration")}
            </Typography>
          </div>
          <div className="durationNumber">
            <IonInput
              inputmode="decimal"
              type="number"
              min={"0"}
              className="durationField"
              placeholder={"2"}
              value={transitionTime}
              onIonChange={(e) => handleTransitionTimeChange(+e.detail.value!)}
              onKeyDown={(e) => handleEnterKey(e)}
              required
              pattern="\d*"
            ></IonInput>
          </div>
          <div className="durationms">
            <Typography variant="body1" color="white64">
              sec
            </Typography>
          </div>
        </div>
        <div className="durationTxt">
          <Typography variant="caption1" color="white64">
            {t("animation-duration-txt")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PulseAnimation);
