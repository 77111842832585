import React, { useState, useEffect } from "react";
import { IonList } from "@ionic/react";
import { Accessory } from "../../data/wall/Walls";
import "./AccessoryList.scss";
import AccessoryListItem from "./AccessoryListItem";

interface AccessoryListProps {
  accessories: Accessory[];
  showGrouping: boolean;
  showAnimation: boolean;
}

const AccessoryList: React.FC<AccessoryListProps> = (props) => {
  const { accessories, showGrouping, showAnimation } = props;
  const [groups, setGroups] = useState(new Set());
  const [unGroupedAccessories, setUnGroupedAccessories] = useState<Accessory[]>(
    []
  );
  const [accTypeCategories, setAccTypeCategories] = useState<any>();

  useEffect(() => {
    setUnGroupedAccessories([]);
    setGroups(new Set());

    var unGroupedAcc: Accessory[] = accessories.filter((accessory: Accessory) =>
      accessory.group_id ? false : true
    );
    setUnGroupedAccessories(unGroupedAcc);

    //Get the count of each accessory type category to restrict grouping
    var accTypes = accessories.map((item: Accessory) => item.type.category);
    const countOccurrences = (arr:any) => {
      const categoryMap:any = {};
      for (var i = 0; i < arr.length; i++) {
        categoryMap[arr[i]] = ~~categoryMap[arr[i]] + 1;
      }
      return categoryMap;
    };
    setAccTypeCategories(countOccurrences(accTypes));

    var groupedAcc: Accessory[] = accessories.filter((accessory: Accessory) =>
      accessory.group_id ? true : false
    );
    if (groupedAcc.length > 0) {
      groupedAcc.forEach((item: Accessory) => {
        if (!groups.has(item.group_id)) {
          groups.add(item.group_id);
        }
      });
      setGroups(groups);
    }
  }, [accessories]);

  return (
    <IonList className="ion-no-padding">
      {Array.from(groups).map((group_id: any, index: number) => {
        var groupedAcc: Accessory[] = accessories.filter(
          (accessory: Accessory) =>
            accessory.group_id === group_id ? true : false
        );
        return (
          <AccessoryListItem
            key={index}
            group_id={group_id}
            accessories={groupedAcc}
            position={index}
            showGrouping={showGrouping}
            showAnimation={showAnimation}
          />
        );
      })}
      {unGroupedAccessories.map((accessory: Accessory, index: number) => (
        <AccessoryListItem
          key={accessory.id}
          accessories={[accessory]}
          position={groups.size + index}
          showGrouping={
            showGrouping  && (+accTypeCategories[accessory.type.category] > 1)
          }
          showAnimation={showAnimation}
        />
      ))}
    </IonList>
  );
};

export default AccessoryList;
