import React from "react";
import { Accessory, AccessoryState } from "../../data/wall/Walls";
import "./AccessoryListItemAnimation.scss";
import { controllAccessoryAndUpdateWall } from "../../data/wall/WallActions";
import { connect, ConnectedProps } from "react-redux";
import Typography from "../common/Typography";
import { withTranslation, WithTranslation } from "react-i18next";
import { IonButton, IonIcon, IonItem } from "@ionic/react";
import { close } from "ionicons/icons";

interface AccessoryListItemProps {
  accessories: Accessory[];
  openAnimationModal(value: boolean): void;
}

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  controlAccesssory: (
    wallId: number,
    accessoryIds: number[],
    state: AccessoryState
  ) => {
    dispatch(controllAccessoryAndUpdateWall(wallId, accessoryIds, state));
  },
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithTranslation & PropsFromRedux & AccessoryListItemProps;

const AccessoryListItemAnimation: React.FC<Props> = (props) => {
  const { accessories, controlAccesssory, openAnimationModal, t } = props;

  function generateAnimationName() {
    let accessory = accessories[0]; // Assuming that all accessories are of same type
    if (accessory.state?.media?.type === "ANIMATION_PULSE_BRIGHTNESS")
      return t("animation-type-pulse");
    else if (accessory.state?.media?.type === "ANIMATION_PULSE_COLOR")
      return t("animation-type-color-transition");
    else if (accessory.state?.media?.type === "ANIMATION_TWINKLE")
      return t("animation-type-twinkle");
    else return t("animation-type-not-defined");
  }

  function getAccessoryIds(accessories: Accessory[]) {
    var accessoryIds: number[] = [];
    accessories.forEach((accessory: Accessory) => {
      accessoryIds.push(accessory.id);
    });
    return accessoryIds;
  }

  function handleEndAnimation() {
    console.log("Ending animation by turning accessory off");
    const newState = {
      status: "OFF",
      media: {
        type: "OFF",
        config: {
          hue: 255,
          saturation: 1,
          brightness: 0,
        },
      },
    };
    controlAccesssory(
      accessories[0].wall_id,
      getAccessoryIds(accessories),
      newState
    );
  }

  function handleAnimationChange() {
    console.log("Opening animation modal");
    openAnimationModal(true);
  }

  return (
    <div
      id="accessoryListItemAnimation"
      className="accessoryListColorAnimationContainer"
    >
      <IonItem
        lines="none"
        className="accessoryListItemAnimationContainer ion-no-padding"
      >
        <IonButton
          className="accessoryListItemAnimationEndAnimationText ion-no-padding"
          onClick={(e) => handleAnimationChange()}
          fill="clear"
        >
          <div className="animationText">
            <div className="animationTextHeading">
              <Typography variant="caption1" color="white64">
                {t("animation-text")}
              </Typography>
            </div>
            <div className="animationType">
              <Typography variant="body1" color="white96">
                {generateAnimationName()}
              </Typography>
            </div>
          </div>
        </IonButton>
        <IonButton
          onClick={(e) => handleEndAnimation()}
          className="accessoryListItemAnimationEndAnimationButton"
          fill="clear"
        >
          <IonIcon icon={close} className="endAnimationIcon" />
        </IonButton>
      </IonItem>
    </div>
  );
};

export default withTranslation()(connector(AccessoryListItemAnimation));
