import React, { useState, useEffect } from "react";
import "./CreateEditGroup.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonHeader,
  IonList,
} from "@ionic/react";
import Typography from "../../common/Typography";
import Toolbar, { ToolbarContents } from "../../common/Toolbar";
import { Accessory, Wall } from "../../../data/wall/Walls";

import CreateEditUngroupedListItem from "./CreateEditUngroupedListItem";
import CreateEditGroupedListItem from "./CreateEditGroupedListItem";

interface CreateEditGroupProps {
  wall: Wall;
  selectedAccessory: Accessory;
  isGroupSelected: boolean;
  onCancel(): void;
  onSave(accessoryIds: number[], groupId: any): void;
}

type Props = WithTranslation & CreateEditGroupProps;

const CreateEditGroup: React.FC<Props> = (props) => {
  const {
    wall,
    selectedAccessory,
    onSave,
    isGroupSelected,
    onCancel,
    t,
  } = props;
  const [groupedAccessories] = useState(new Map());
  const [unGroupedAccessories, setUnGroupedAccessories] = useState<Accessory[]>(
    []
  );
  const [checkedItems, setCheckedItems] = useState(new Map<number, boolean>());
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>();
  const [error, setError] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");

  //Save selected accessories in the group
  const handleDone = () => {
    var accessoryIds: number[] = [];
    checkedItems.forEach((key, value: any) => {
      if (key) {
        accessoryIds.push(value);
      }
    });
    if (accessoryIds.length > 1) {
      onSave(accessoryIds, selectedGroupId);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    groupedAccessories.clear();
    setUnGroupedAccessories([]);
    setPopupTitle(t("create-new-group"));
    wall.accessories.map((acc: Accessory) => {
      const GROUP_ID = acc.group_id;
      if (GROUP_ID) {
        if (groupedAccessories.has(GROUP_ID)) {
          groupedAccessories.get(GROUP_ID).push(acc);
        } else {
          groupedAccessories.set(GROUP_ID, [acc]);
        }
      }
      return acc;
    });

    var unGroupedAcc: Accessory[] = wall.accessories.filter(
      (accessory: Accessory) =>
        accessory.group_id
          ? false
          : accessory.type.category === selectedAccessory.type.category
    );
    setUnGroupedAccessories(unGroupedAcc);
    /*
    If the selected accessory from wall view page is not from group then set it so we can show it
    selected in UI
    */
    if (selectedAccessory && !isGroupSelected) {
      setCheckedItems(checkedItems.set(selectedAccessory.id, true));
    }

    //Get the group id from the accessory of selected group if any
    if (isGroupSelected) {
      setSelectedGroupId(selectedAccessory.group_id);
      setPopupTitle(t("add-remove-accessories"));
      //Set all accessories of selected group id as checked in UI
      var grouped_accessories: Accessory[] = groupedAccessories.get(
        selectedAccessory.group_id
      );
      grouped_accessories.map((accessory: Accessory) => {
        setCheckedItems(checkedItems.set(accessory.id, true));
      });
    } else if (groupedAccessories.size > 0) {
      setPopupTitle(t("group-with-other-accessories"));
    }
  }, []);

  //invoked whenever an accessory / group is selected or unselected
  const handleSelection = (e: any, isGroup: boolean) => {
    if (isGroup) {
      //Get the selected groups id here if no other group is already selected
      if (selectedGroupId === undefined) {
        setSelectedGroupId(e.detail.value);
      } else {
        if (selectedGroupId === e.detail.value && !e.detail.checked) {
          setSelectedGroupId(undefined);
        }
      }
      var grouped_accessories: Accessory[] = groupedAccessories.get(
        e.detail.value
      );
      grouped_accessories.forEach((a: Accessory) => {
        setCheckedItems(checkedItems.set(a.id, e.detail.checked));
      });
    } else {
      const item = e.detail.value;
      const isChecked = e.detail.checked;
      setCheckedItems(checkedItems.set(+item, isChecked));
    }
    setError(false);
  };

  const toolbarContents: ToolbarContents = {
    left: {
      on_click: () => onCancel(),
      label: t("cancel"),
      show_icon: false,
    },
    middle: {
      label: popupTitle,
      show_image: false,
    },
    right: {
      on_click: () => handleDone(),
      label: t("done"),
      show_icon: false,
    },
  };

  return (
    <div id="createEditGroup">
      <IonHeader translucent className="ion-no-border">
        <Toolbar contents={toolbarContents} />
      </IonHeader>
      <div className="modalContent">
        <IonGrid>
          {error ? (
            <Typography variant="caption1" color="critical">
              {t("group-accessory-error")}
            </Typography>
          ) : (
            <div></div>
          )}
          <IonRow>
            <IonCol>
              <Typography
                variant="heading2"
                color="white64"
                className="groupHeading"
              >
                {t("group-heading")}
              </Typography>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonList className="accessoryItemList ion-no-padding">
                {unGroupedAccessories.map(
                  (accessory: Accessory, index: number) => (
                    <CreateEditUngroupedListItem
                      key={accessory.id}
                      accessory={accessory}
                      isChecked={checkedItems.get(accessory.id)}
                      onSelectionchange={handleSelection}
                      position={index}
                    />
                  )
                )}
                {Array.from(groupedAccessories.keys()).map(
                  (key: any, index: number) => {
                    var grouped_accessories: Accessory[] = groupedAccessories.get(
                      key
                    );
                    if (
                      isGroupSelected &&
                      selectedGroupId &&
                      selectedGroupId === key
                    ) {
                      return grouped_accessories.map(
                        (accessory: Accessory, index: number) => {
                          return (
                            <CreateEditUngroupedListItem
                              key={accessory.id}
                              accessory={accessory}
                              isChecked={checkedItems.get(accessory.id)}
                              onSelectionchange={handleSelection}
                              position={unGroupedAccessories.length + index}
                            />
                          );
                        }
                      );
                    } else {
                      if (
                        selectedAccessory.type.category ===
                        grouped_accessories[0].type.category
                      ) {
                        return (
                          <CreateEditGroupedListItem
                            key={index}
                            group_id={key}
                            accessories={grouped_accessories}
                            isChecked={false}
                            onSelectionchange={handleSelection}
                            position={unGroupedAccessories.length}
                          />
                        );
                      }
                    }
                  }
                )}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default withTranslation()(CreateEditGroup);
