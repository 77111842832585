import React from "react";
import { Accessory, AccessoryState } from "../../data/wall/Walls";
import "./AccessoryListItemBrightness.scss";
import { Config } from "../../data/wall/Walls";
import { controllAccessoryAndUpdateWall } from "../../data/wall/WallActions";
import { connect, ConnectedProps } from "react-redux";
import Typography from "../common/Typography";
import Slider from "../common/Slider";
import { withTranslation, WithTranslation } from "react-i18next";
import Media from "react-media";
import ColorPicker from "../colorpicker/ColorPicker";
import { BRIGHTNESS_OFF_LIMIT } from "../../utils/ColorUtil";

interface AccessoryListItemProps {
  accessories: Accessory[];
}

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  controlAccesssory: (
    wallId: number,
    accessories: number[],
    state: AccessoryState
  ) => {
    dispatch(controllAccessoryAndUpdateWall(wallId, accessories, state));
  },
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithTranslation & PropsFromRedux & AccessoryListItemProps;

const AccessoryListItemBrightness: React.FC<Props> = (props) => {
  const { accessories, controlAccesssory, t } = props;
  const CONFIG_TYPE = "DIMMER";

  // Default predefined color
  let color: Config = {
    hue: 58,
    saturation: 1,
    brightness: 0,
  };

  let accessory = accessories[0]; // Using the first accessory by default
  let txtToDisplay =
    accessory.type.category === "BRIGHTNESS"
      ? t("text-brightness")
      : accessory.type.category === "MOTOR"
      ? t("text-speed")
      : "";
  if (accessory.state) {
    if (accessory.state.media?.config) {
      if (
        JSON.stringify(accessory.state.media?.config) !== JSON.stringify(color)
      ) {
        console.log("Updated the color for accessory");
        console.log(accessory.state?.media?.config);
        if (
          accessory.state?.media?.type === "COLOR" ||
          accessory.state?.media?.type === "OFF" ||
          accessory.state?.media?.type === "DIMMER"
        ) {
          color = accessory.state?.media?.config;
        }
      }
    }
  }

  function getAccessoryIds(accessories: Accessory[]) {
    var accessoryIds: number[] = [];
    accessories.forEach((accessory: Accessory) => {
      accessoryIds.push(accessory.id);
    });
    return accessoryIds;
  }

  function turnOff() {
    console.log("Turning off accessories %s", getAccessoryIds(accessories));
    const newState = {
      status: "OFF",
      media: {
        type: "OFF",
        config: color,
      },
    };
    controlAccesssory(
      accessories[0].wall_id,
      getAccessoryIds(accessories),
      newState
    );
  }

  function updateColor() {
    console.log(
      "Changeing color of accessories %s to %s",
      getAccessoryIds(accessories),
      JSON.stringify(color)
    );
    const newState = {
      status: "ON",
      media: {
        type: CONFIG_TYPE,
        config: color,
      },
    };
    controlAccesssory(
      accessories[0].wall_id,
      getAccessoryIds(accessories),
      newState
    );
  }

  function changeBrightness(e: any) {
    const brightness = e / 100;
    if (brightness !== color.brightness) {
      changeColor(color.hue!, color.saturation!, brightness);
    } else {
      console.log("No change necessary");
    }
  }

  function changeColor(hue: number, saturation: number, brightness: number) {
    //console.log("Change color from picker");
    color.hue = hue;
    color.saturation = saturation;
    color.brightness = brightness;
    if (brightness < BRIGHTNESS_OFF_LIMIT) {
      turnOff();
    } else {
      updateColor();
    }
  }

  return (
    <div
      id="accessoryListItemBrightness"
      className="accessoryListBrightnessContainer"
    >
      <Media queries={{ small: "(max-width: 599px)" }}>
        {(matches) =>
          matches.small ? (
            <div className="brightnessPickerDiv">
              <div className="brightnessPicker1">
                <ColorPicker
                  color={color}
                  colorChangeCallback={changeColor}
                  style="full"
                  displayBrightness={txtToDisplay}
                  accessory={accessory}
                  showBrightnessBox={true}
                />
              </div>
            </div>
          ) : (
            <div className="accessoryListBrightnessInternalContainer">
              <div className="accessoryListItemPlaceholder"></div>
              <div className="accessoryListItemBrightnessSlider">
                <div className="sliderIcon" />
                <div className="accessoryListItemSlider">
                  <Slider
                    min={0}
                    max={100}
                    value={color.brightness! * 100}
                    onChange={(e) => changeBrightness(e)}
                    debounce={500}
                    sliderColor="#FFFFFF"
                  ></Slider>
                </div>
              </div>
              <div className={`${color.brightness! < BRIGHTNESS_OFF_LIMIT ? "accessoryListItemBrightnessOff" : "accessoryListItemBrightnessPercentage"}`}>
                <Typography variant="body2">
                  {color.brightness! < BRIGHTNESS_OFF_LIMIT
                    ? t("off")
                    : Math.round(color.brightness! * 100) + "%"}
                </Typography>
              </div>
            </div>
          )
        }
      </Media>
    </div>
  );
};

export default withTranslation()(connector(AccessoryListItemBrightness));
