import { combineReducers } from "redux";
import { walls } from "./data/wall/Walls";
import { scenes } from "./data/scene/Scenes";
import { user } from "./data/user/User";
import { medias } from "./data/mediamanagement/Medias";

import { notifications } from "./components/notifications/Notifications";

const appReducers = combineReducers({
  walls,
  scenes,
  medias,
  user,
  notifications,
});

export default appReducers;
