import React from "react";
import { Route, Redirect } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/home/Home";
import WallView from "./pages/wall/WallView";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* App styling */
import "./app.scss";

/* Authenticator */
import { withAuthenticator } from "aws-amplify-react";
import SignInMagnWall from "./pages/auth/SignInMagnWall";
import RequireNewPasswordMagnWall from "./pages/auth/RequireNewPasswordMagnWall";
import Notifier from "./components/notifications/Notifier";
import More from "./pages/more/More";
import { apps, menu } from "ionicons/icons";
import ForgotPasswordMagnWall from "./pages/auth/ForgotPasswordMagnWall";
import CreateEditScene from "./pages/scene/CreateEditScene";
import ServiceWorkerWrapper from "./components/notifications/ServiceWorkerWrapper";
import Typography from "./components/common/Typography";

const App: React.FC = () => {
  return (
    <IonApp>
      <ServiceWorkerWrapper />
      <Notifier />
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/" render={() => <Redirect to="/home" />} exact />
          <Route path="/wall/:id" component={WallView} exact />
          <Route
            path="/wall/:wall_id/scene"
            component={CreateEditScene}
            exact
          />
          <Route
            path="/wall/:wall_id/scene/:id"
            component={CreateEditScene}
            exact
          />
          <Route
            path="/home"
            render={() => (
              <IonTabs>
                <IonRouterOutlet>
                  <Route path="/home" component={Home} exact />
                  <Route path="/more" component={More} exact />
                </IonRouterOutlet>
                <IonTabBar slot="bottom" className="ionTabBar">
                  <IonTabButton
                    layout="icon-top"
                    href="/home"
                    tab="home"
                    className="ionTabButton"
                  >
                    <IonIcon icon={apps} />
                    <IonLabel className="ionTabLabel">
                      <Typography variant="caption1" color="white64">
                        Walls
                      </Typography>
                    </IonLabel>
                  </IonTabButton>
                  <IonTabButton
                    layout="icon-top"
                    href="/more"
                    tab="more"
                    className="ionTabButton"
                  >
                    <IonIcon icon={menu} />
                    <IonLabel className="ionTabLabel">
                      <Typography variant="caption1" color="white64">
                        More
                      </Typography>
                    </IonLabel>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            )}
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default withAuthenticator(App, false, [
  <SignInMagnWall />,
  <RequireNewPasswordMagnWall />,
  <ForgotPasswordMagnWall />,
]);
