import WallListItem from "../../components/walllistitem/WallListItem";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getWalls } from "../../data/wall/WallActions";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonInput,
} from "@ionic/react";
import "./Home.scss";
import { Wall } from "../../data/wall/Walls";
import Typography from "../../components/common/Typography";
import MagnWallLogo from "../../assets/images/Magnwall_Logo.svg";
import { withTranslation, WithTranslation } from "react-i18next";
import Toolbar, { ToolbarContents } from "../../components/common/Toolbar";
import { getCustomer } from "../../data/user/UserActions";
import { Auth } from "aws-amplify";
import { getScenes } from "../../data/scene/SceneActions";
import Spinner from "../../components/common/Spinner";
import { useIonViewWillEnter } from "@ionic/react";

const mapStateToProps = (state: any) => ({
  walls: state.walls.items,
  wallLoading: state.walls.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getWalls: () => dispatch(getWalls()),
  getCustomer: (id: string) => dispatch(getCustomer(id)),
  getScenes: () => dispatch(getScenes()),
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Home: React.FC<Props> = (props) => {
  const {
    walls,
    wallLoading,
    getWalls,
    getScenes,
    getCustomer,
    t,
  } = props;
  const hiddenHomeField: any = useRef();
  const [refreshTime, setRefreshTime] = useState(new Date().getTime());
  useIonViewWillEnter(() => {
    setRefreshTime(new Date().getTime());
  });

  // Just run at first time to laod all data
  useEffect(() => {
    console.log("Loading initial data");
    getWalls();
    getScenes();
    getCustomerInfo();
    unlockScroll(); //to fix the screen lock issue on iOS
  }, [refreshTime]);

  //This is a hack to fix the screen lock issue on iOS
  const unlockScroll = () => {
    const hiddenFieldObj = hiddenHomeField.current!;
    hiddenFieldObj.focus();
    setTimeout(() => {
      hiddenFieldObj.blur();
    }, 1000);
  };

  // get customer info
  const getCustomerInfo = async () => {
    await Auth.currentAuthenticatedUser().then((data) => {
      const customerId = data["attributes"]["custom:customer_id"];
      if (customerId) {
        getCustomer(customerId);
      }
    });
  };

  const refresh = (e: CustomEvent) => {
    getWalls();
    e.detail.complete();
  };

  const toolbarContents: ToolbarContents = {
    middle: {
      show_image: true,
      image_details: {
        src: MagnWallLogo,
        alt: "MagnWall logo",
      },
    },
  };

  return (
    <IonPage id="homeView">
      {/* Remove header once we have the proper logout page */}
      <IonHeader translucent className="ion-no-border">
        <Toolbar contents={toolbarContents} />
      </IonHeader>
      <IonContent className="homeContent">
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonGrid className="homeContentData">
          <IonRow>
            <IonCol size-sm="12" size-md="3">
              <Typography
                className="wallsTitle"
                variant="title2"
                color="white96"
              >
                {t("home-myMagnwalls")}
              </Typography>
            </IonCol>
          </IonRow>
          <IonRow className="ion-no-padding wallCardRow ion-padding-start">
            {wallLoading ? (
              <Spinner className="wallSpinner" />
            ) : (
              walls.map((wall: Wall) => (
                <WallListItem key={wall.id} wall={wall} />
              ))
            )}
          </IonRow>
          <div className="ion-no-padding">
            <IonInput
              ref={hiddenHomeField}
              key="name"
              type="text"
              className="hiddenHomeField"
            />
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Home)
);
