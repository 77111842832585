import React, { useState } from "react";
import { IonItem, IonModal } from "@ionic/react";
import { Accessory } from "../../data/wall/Walls";
import "./AccessoryListItem.scss";
import { connect, ConnectedProps } from "react-redux";
import Typography from "../common/Typography";
import { withTranslation, WithTranslation } from "react-i18next";
import AccessoryListItemColor from "./AccessoryListItemColor";
import AccessoryListItemAnimation from "./AccessoryListItemAnimation";
import AccessoryMenu from "./AccessoryMenu";
import AccessoryListItemBrightness from "./AccessoryListItemBrightness";
import CreateEditAnimation from "./annimation/CreateEditAnimation";
import AccessoryListItemTypes from "./AccessoryListItemTypes";
import Media from "react-media";
import AccessoryListItemStream from "./AccessoryListItemStream";
import CreateEditStream from "./stream/CreateEditStream";

interface AccessoryListItemProps {
  accessories: Accessory[];
  position: number;
  showGrouping: boolean;
  showAnimation: boolean;
  group_id?: number;
}

const mapState = (state: any) => ({
  walls: state.walls.items,
});

const mapDispatch = (dispatch: any) => ({});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithTranslation & PropsFromRedux & AccessoryListItemProps;

const AccessoryListItem: React.FC<Props> = (props) => {
  const {
    accessories,
    group_id,
    position,
    showGrouping,
    showAnimation,
  } = props;

  const [animationModalOpen, setAnimationModalOpen] = useState(false);
  const [streamModalOpen, setStreamModalOpen] = useState(false);

  /**
   * Retrieves the correct accessory ionic componets depending on the accessory type
   * @param accessory Accessory for which the ionic component needs to be found
   */
  function findAccessoryItemType(accessories: Accessory[]) {
    let accessory = accessories[0]; // we assume that all accessories are of the same kind
    if (accessory.type.category === "RGB") {
      if (accessory.state?.media?.type.startsWith("ANIMATION")) {
        return (
          <AccessoryListItemAnimation
            accessories={accessories}
            openAnimationModal={setAnimationModalOpen}
          />
        );
      } else {
        return <AccessoryListItemColor accessories={accessories} />;
      }
    } else if (
      accessory.type.category === "BRIGHTNESS" ||
      accessory.type.category === "MOTOR"
    ) {
      return <AccessoryListItemBrightness accessories={accessories} />;
    } else if (accessory.type.category === "STREAM") {
      return (
        <AccessoryListItemStream
          accessories={accessories}
          openStreamModal={setStreamModalOpen}
        />
      );
    }
  }

  function createAccessoryItemMobile() {
    return (
      <div
        id="accessoryListItemMobile"
        className="accessoryListItemContainerMobile"
      >
        <div className="accessoryListItemId">
          <Typography variant="body1" color="white32">
            {position + 1}
          </Typography>
        </div>
        <div className="accessoryType">
          <AccessoryListItemTypes accessories={accessories} />
        </div>
        <div className="accessoryListItemContent">
          {findAccessoryItemType(accessories)}
          {showGrouping || showAnimation ? (
            <div className="accessoryListItemMenu">
              <AccessoryMenu
                accessories={accessories}
                group_id={group_id}
                setAnimationModalOpen={setAnimationModalOpen}
                showGrouping={showGrouping}
                showAnimation={showAnimation}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }

  function createAccessoryItemDesktop() {
    return (
      <div id="accessoryListItem" className="accessoryListItemContainer">
        <div className="accessoryListItemId">
          <Typography variant="body1" color="white32">
            {position + 1}
          </Typography>
        </div>
        <div className="accessoryType">
          <AccessoryListItemTypes accessories={accessories} />
        </div>
        <div className="accessoryListItemContent">
          {findAccessoryItemType(accessories)}
        </div>
        {showGrouping || showAnimation ? (
          <div className="accessoryListItemMenu">
            <AccessoryMenu
              accessories={accessories}
              group_id={group_id}
              setAnimationModalOpen={setAnimationModalOpen}
              showGrouping={showGrouping}
              showAnimation={showAnimation}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }

  return (
    <IonItem
      detail={false}
      lines="full"
      className="accessoryItemRow ion-no-padding"
    >
      <Media queries={{ small: "(max-width: 599px)" }}>
        {(matches) =>
          matches.small
            ? createAccessoryItemMobile()
            : createAccessoryItemDesktop()
        }
      </Media>
      <IonModal
        cssClass="animationModal"
        isOpen={animationModalOpen}
        onDidDismiss={() => setAnimationModalOpen(false)}
      >
        <CreateEditAnimation
          accessories={accessories}
          closeModal={() => setAnimationModalOpen(false)}
        />
      </IonModal>
      <IonModal
        cssClass="`streamModal`"
        isOpen={streamModalOpen}
        onDidDismiss={() => setStreamModalOpen(false)}
      >
        <CreateEditStream
          accessories={accessories}
          closeModal={() => setStreamModalOpen(false)}
        />
      </IonModal>
    </IonItem>
  );
};

export default withTranslation()(connector(AccessoryListItem));
