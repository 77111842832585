import React, { useState, useEffect } from "react";
import "./CreateSceneAutomation.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import { IonGrid, IonRow, IonCol, IonModal } from "@ionic/react";
import Typography from "../../components/common/Typography";
import ScheduleIcon from "../../assets/images/schedule-white.svg";
import SceneSchedule from "../sceneschedule/SceneSchedule";
import { Scene, SceneAutomationSchedule } from "../../data/scene/Scenes";
import { cronToTimeAndDay } from "../../utils/DateTime";
import Alert from "../common/Alert";
import Button from "../common/Button";

interface SceneAutomationProps {
  scene: Scene;
  onChangeAutomation(cron_expression: string): void;
}
type Props = WithTranslation & SceneAutomationProps;

const CreateSceneAutomation: React.FC<Props> = (props) => {
  const { scene, onChangeAutomation, t } = props;

  const [scheduleSceneModalOpen, setScheduleSceneModalOpen] = useState(false);
  const sceneAutomationScheduleDefault: SceneAutomationSchedule = {
    id: 0,
    action: "OFF",
    active: false,
    cron: "",
    last_execution: "",
    scene_id: 0,
  };
  const [sceneAutomationSchedule, setSceneAutomationSchedule] = useState(
    sceneAutomationScheduleDefault
  );
  const [selectedTime, setSelectedTime] = useState(`09:00 ${t("every-day")}`);
  const [enableScheduleSceneIcon, setEnableScheduleSceneIcon] = useState(
    "inActiveSceneScheduleIcon"
  );
  const [showAutomationAlert, setShowAutomationAlert] = useState(false);

  //Display the selected scene schedule
  const displaySelectedTime = (cron: string) => {
    setSelectedTime(cronToTimeAndDay(cron, t));
    setEnableScheduleSceneIcon("activeSceneScheduleIcon");
  };

  /*
    In the following useEffect() we are first checking if the scene already has any schedule associated
    If yes then we read its value and put it in an object
    If No then the sceneAutomationSchedule object will have default values for creating new
  */
  useEffect(() => {
    if (scene.scene_schedules && scene.scene_schedules.length > 0) {
      setSceneAutomationSchedule(scene.scene_schedules[0]);
      displaySelectedTime(sceneAutomationSchedule.cron);
    } else {
      setSceneAutomationSchedule(sceneAutomationScheduleDefault);
      displaySelectedTime(sceneAutomationSchedule.cron);
    }
  }, [scene.scene_schedules]);

  /*
    In the following useEffect() set the selected time whenever user is trying
    to set or update the scene schedule
  */
  useEffect(() => {
    displaySelectedTime(sceneAutomationSchedule.cron);
  }, [sceneAutomationSchedule]);

  //Create of update scene schedule
  const createOrUpdateSceneSchedule = (
    sceneSchedule: SceneAutomationSchedule
  ) => {
    setScheduleSceneModalOpen(false);
    Object.assign(sceneAutomationSchedule, sceneSchedule);
    setSceneAutomationSchedule(sceneAutomationSchedule);
    let cron = sceneAutomationSchedule.cron;
    displaySelectedTime(cron);
    onChangeAutomation(cron);
  };

  //Open the modal to create / update the scene schedule
  const createNewSceneScheduleModal = () => {
    setScheduleSceneModalOpen(true);
  };

  //Show the alert if the user is trying to delete the scene schedule
  const showDeleteSceneScheduleAlert = () => {
    setScheduleSceneModalOpen(false);
    setShowAutomationAlert(true);
  };

  //To delete the schedule of user clicks on delete in the alert dialog
  const deleteSceneSchedule = () => {
    Object.assign(sceneAutomationSchedule, sceneAutomationScheduleDefault);
    setSceneAutomationSchedule(sceneAutomationSchedule);
    setEnableScheduleSceneIcon("inActiveSceneScheduleIcon");
    onChangeAutomation("");
  };

  return (
    <div id="createSceneAutomation">
      <IonRow>
        <IonCol
          offset-sm="0"
          size-sm="8"
          offset-md="1"
          offset-lg="2"
          size-md="8"
        >
          <Typography
            variant="title3"
            color="white96"
            className="timeAutomation"
          >
            {t("time-automation")}
          </Typography>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol
          offset-sm="0"
          size-sm="12"
          offset-md="1"
          offset-lg="2"
          size-md="8"
        >
          <Typography
            variant="caption1"
            color="white64"
            className="timeAutomationDesc"
          >
            {t("time-automation-desc")}
          </Typography>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol
          offset-sm="0"
          size-sm="12"
          offset-md="1"
          offset-lg="2"
          size-md="8"
        >
          <IonGrid>
            <IonRow className="noSceneScheduleRow">
              <IonCol
                size="2"
                className="noSceneScheduleIcon"
                onClick={createNewSceneScheduleModal}
              >
                <img
                  src={ScheduleIcon}
                  alt="ScheduleScene"
                  className={`scheduleSceneIcon ${enableScheduleSceneIcon}`}
                ></img>
              </IonCol>
              <IonCol className="ion-no-padding">
                {sceneAutomationSchedule.active ? (
                  <Button
                    color="transparent"
                    onClick={createNewSceneScheduleModal}
                    className="createNewSceneScheduleTitle"
                  >
                    <Typography variant="heading1" color="white64">
                      {selectedTime.replace(/,/g, ", ")}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    color="transparent"
                    onClick={createNewSceneScheduleModal}
                    className="createNewSceneScheduleTitle"
                  >
                    <Typography variant="heading1" color="white64">
                      {t("schedule-scene")}
                    </Typography>
                  </Button>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
      <IonModal
        cssClass="scheduleSceneModal"
        isOpen={scheduleSceneModalOpen}
        onDidDismiss={() => setScheduleSceneModalOpen(false)}
      >
        <SceneSchedule
          sceneSchedule={sceneAutomationSchedule}
          onCreateOrUpdateSceneSchedule={createOrUpdateSceneSchedule}
          onCancelSceneSchedule={setScheduleSceneModalOpen}
          onDeleteSceneSchedule={showDeleteSceneScheduleAlert}
        />
      </IonModal>
      <Alert
        displayAlert={showAutomationAlert}
        dismissAlert={setShowAutomationAlert}
        actionHandler={deleteSceneSchedule}
        actionLabel="Delete"
        alertHeader={t("schedule-scene-delete-alert-header")}
        alertMessage={t("schedule-scene-delete-alert-message")}
      ></Alert>
    </div>
  );
};

export default withTranslation()(CreateSceneAutomation);
