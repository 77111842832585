import { CALL_API, apiCall } from "../../common/api";
import { AccessoryState, AccessoryStateConfig } from "./Walls";

export const GET_WALLS_REQUEST = "GET_WALLS_REQUEST";
export const GET_WALLS_FAILURE = "GET_WALLS_FAILURE";
export const GET_WALLS_SUCCESS = "GET_WALLS_SUCCESS";

export const getWalls = () => ({
  [CALL_API]: {
    types: [GET_WALLS_REQUEST, GET_WALLS_SUCCESS, GET_WALLS_FAILURE],
    apiName: "MagnwallAPI",
    path: "/wall",
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const GET_WALL_REQUEST = "GET_WALL_REQUEST";
export const GET_WALL_FAILURE = "GET_WALL_FAILURE";
export const GET_WALL_SUCCESS = "GET_WALL_SUCCESS";

export const getWall = (id: string) => ({
  [CALL_API]: {
    types: [GET_WALL_REQUEST, GET_WALL_SUCCESS, GET_WALL_FAILURE],
    apiName: "MagnwallAPI",
    path: "/wall/" + id,
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const CONTROL_WALL_REQUEST = "CONTROL_WALL_REQUEST";
export const CONTROL_WALL_FAILURE = "CONTROL_WALL_FAILURE";
export const CONTROL_WALL_SUCCESS = "CONTROL_WALL_SUCCESS";

const controlWallAPI = (id: string, action: String) => {
  let apiName = "MagnwallAPI";
  let path = "/wall/control?id=" + id + "&action=" + action;
  let myInit = {
    headers: {},
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const controlAndUpdateWall = (wallId: string, action: String) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: CONTROL_WALL_REQUEST });
  return (
    controlWallAPI(wallId, action).then((response) => {
      console.log("Controlling of the wall successful, fetching wall again");
      dispatch({ response, type: CONTROL_WALL_SUCCESS, wallId: wallId });
      dispatch(getWall(String(wallId)));
    }),
    (error: any) => {
      console.log("Error happened during control wall");
      dispatch({ error, type: CONTROL_WALL_FAILURE });
    }
  );
};

export const CONTROL_ACCESSORY_REQUEST = "CONTROL_ACCESSORY_REQUEST";
export const CONTROL_ACCESSORY_FAILURE = "CONTROL_ACCESSORY_FAILURE";
export const CONTROL_ACCESSORY_SUCCESS = "CONTROL_ACCESSORY_SUCCESS";

export const controllAccessoryAPI = (
  wallId: number,
  accessoryIds: number[],
  state: AccessoryStateConfig
) => {
  let apiName = "MagnwallAPI";
  let path = "/accessory/control";
  let myInit = {
    headers: {},
    body: {
      ids: accessoryIds,
      type: state.type,
      config: state.config,
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const controllAccessoryAndUpdateWall = (
  wallId: number,
  accessoryIds: number[],
  state: AccessoryState
) => (dispatch: any, getState: any) => {
  //var arrAccessoryIds = accessoryIds.split(",");
  dispatch({
    type: CONTROL_ACCESSORY_REQUEST,
    wallId: wallId,
    accessoryId: accessoryIds,
    state: state,
  });
  return (
    controllAccessoryAPI(wallId, accessoryIds, state.media!).then(
      (response) => {
        console.log(
          "Controlling of the accessory successful, fetching wall again. RESPONSE"
        );
        let newState = state;
        console.log(response);
        if (
          response.status === 202 &&
          (state.media?.type === "VIDEO" ||
            state.media?.type === "IMAGE" ||
            state.media?.type === "WEBSITE")
        ) {
          console.log(
            "Status 202 received. The TV/Tablet could be disconnected."
          );
          newState = {
            status: "DISCONNECTED",
            media: {
              type: state.media?.type + "_ERROR",
              config: { uri: state.media?.config?.uri },
            },
          };
        } else if (
          response.status === 202) {
          //Device is connected
          newState = {
            status: "DISCONNECTED",
            media: {
              type: state.media?.type + "",
              config: state?.media?.config,
            },
          };
        }
        else {
          //Device is connected
          newState = {
            status: state.status,
            media: {
              type: state.media?.type + "",
              config: state?.media?.config,
            },
          };
        }
        dispatch({
          response,
          type: CONTROL_ACCESSORY_SUCCESS,
          wallId: wallId,
          accIds: accessoryIds,
          state: newState,
        });
      }
    ),
    (error: any) => {
      console.log("Error happened during control accessory");
      console.log(error);
      dispatch({ error, type: CONTROL_ACCESSORY_FAILURE });
    }
  );
};

export const WALL_LOADING_ON = "WALL_LOADING_ON";

export function loadingWallOn() {
  return { type: WALL_LOADING_ON };
}

export const GROUP_ACCESSORY_REQUEST = "GROUP_ACCESSORY_REQUEST";
export const GROUP_ACCESSORY_FAILURE = "GROUP_ACCESSORY_FAILURE";
export const GROUP_ACCESSORY_SUCCESS = "GROUP_ACCESSORY_SUCCESS";

const getBodyParams = (accessoryIds: number[], groupId: string) => {
  if (groupId) {
    return { accessory_ids: accessoryIds, group_id: groupId };
  } else {
    return { accessory_ids: accessoryIds };
  }
};

export const groupAccessoriesAPI = (
  accessoryIds: number[],
  groupId: string
) => {
  let apiName = "MagnwallAPI";
  let path = "/accessory/group";
  let myInit = {
    headers: {},
    body: getBodyParams(accessoryIds, groupId),
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const groupAccessories = (
  wall_Id: number,
  accessoryIds: number[],
  groupId: string
) => (dispatch: any, getState: any) => {
  dispatch(loadingWallOn());
  dispatch({
    type: GROUP_ACCESSORY_REQUEST,
  });
  return (
    groupAccessoriesAPI(accessoryIds, groupId).then((response) => {
      console.log("Grouping of the accessory successful, fetching wall again");
      if (accessoryIds.length > 1) {
        /*
        Turn off animation only when grouping that means there are more than 1 accessoryIds
        In case of ungrouping we get only one accessoryIds above
        */
        let state = {
          status: "OFF",
          media: {
            type: "OFF",
            config: { hue: 58, brightness: 0, saturation: 1 },
          },
        };
        controllAccessoryAPI(wall_Id, accessoryIds, state.media).then(
          (response) => {
            dispatch({
              response,
              type: CONTROL_ACCESSORY_SUCCESS,
              wallId: wall_Id,
              state: state,
            });

            dispatch({
              response,
              type: GROUP_ACCESSORY_SUCCESS,
              wallId: wall_Id,
            });
            dispatch(getWall(String(wall_Id)));
          },
          (error: any) => {
            console.log("Error happened during grouping and control accessory");
            dispatch({ error, type: CONTROL_ACCESSORY_FAILURE });
          }
        );
      } else {
        dispatch({
          response,
          type: GROUP_ACCESSORY_SUCCESS,
          wallId: wall_Id,
        });
        dispatch(getWall(String(wall_Id)));
      }
    }),
    (error: any) => {
      console.log("Error happened during grouping accessory");
      dispatch({ error, type: GROUP_ACCESSORY_FAILURE });
    }
  );
};
