import { CALL_API, apiCall } from "../../common/api";

export const GET_MEDIAS_REQUEST = "GET_MEDIAS_REQUEST";
export const GET_MEDIAS_FAILURE = "GET_MEDIAS_FAILURE";
export const GET_MEDIAS_SUCCESS = "GET_MEDIAS_SUCCESS";
export const GET_MEDIAS_FOR_LOCAL_REQUEST = "GET_MEDIAS_FOR_LOCAL_REQUEST";

export const getMedias = (accIdList: string[]) => ({
  [CALL_API]: {
    types: [GET_MEDIAS_REQUEST, GET_MEDIAS_SUCCESS, GET_MEDIAS_FAILURE],
    apiName: "MagnwallMediaAPI",
    path: "/media?easyvis_device_ids=" + accIdList.join(","),
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const getMediasForLocal = (accIdList: string[]) => ({
  [CALL_API]: {
    types: [
      GET_MEDIAS_FOR_LOCAL_REQUEST,
      GET_MEDIAS_SUCCESS,
      GET_MEDIAS_FAILURE,
    ],
    apiName: "MagnwallMediaAPI",
    path: "/media?easyvis_device_ids=" + accIdList.join(","),
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const DELETE_MEDIA_REQUEST = "DELETE_MEDIA_REQUEST";
export const DELETE_MEDIA_FAILURE = "DELETE_MEDIA_FAILURE";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";

const deleteMediaAPI = (id: string) => {
  let apiName = "MagnwallMediaAPI";
  let path = "/media/" + id;
  let myInit = {
    headers: {},
    response: true,
  };
  return apiCall("delete", apiName, path, myInit);
};

export const deleteMedia = (id: string, deviceId: string) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: DELETE_MEDIA_REQUEST });
  return (
    deleteMediaAPI(id).then((response) => {
      console.log("Delete Media successful, fetching all media again");
      dispatch(getMedias([deviceId]));
      dispatch({ response, type: DELETE_MEDIA_SUCCESS, mediaId: id });
    }),
    (error: any) => {
      console.log("Error happened during delete Media");
      dispatch({ error, type: DELETE_MEDIA_FAILURE });
    }
  );
};

export const REMOVE_MEDIA_REQUEST = "REMOVE_MEDIA_REQUEST";
export const REMOVE_MEDIA_FAILURE = "REMOVE_MEDIA_FAILURE";
export const REMOVE_MEDIA_SUCCESS = "REMOVE_MEDIA_SUCCESS";

const removeFromDeviceApi = (mediaId: string, deviceId: string) => {
  let apiName = "MagnwallMediaAPI";
  let path = "/media/delete_from_device/" + deviceId + "/" + mediaId;
  let myInit = {
    headers: {},
    response: true,
  };
  return apiCall("delete", apiName, path, myInit);
};

export const removeFromDevice = (mediaId: string, deviceId: string) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: REMOVE_MEDIA_REQUEST });
  return (
    removeFromDeviceApi(mediaId, deviceId).then((response) => {
      console.log("Remove Media successful, fetching all media again");
      dispatch(getMedias([deviceId]));
      dispatch({ response, type: REMOVE_MEDIA_SUCCESS, mediaId: mediaId });
    }),
    (error: any) => {
      console.log("Error happened during remove Media");
      dispatch({ error, type: REMOVE_MEDIA_FAILURE });
    }
  );
};

export const DOWNLOAD_MEDIA_REQUEST = "DOWNLOAD_MEDIA_REQUEST";
export const DOWNLOAD_MEDIA_FAILURE = "DOWNLOAD_MEDIA_FAILURE";
export const DOWNLOAD_MEDIA_SUCCESS = "DOWNLOAD_MEDIA_SUCCESS";

const downloadToDeviceApi = (mediaId: string, deviceId: string) => {
  let apiName = "MagnwallMediaAPI";
  let path = "/media/download_to_device";
  let myInit = {
    headers: {},
    body: {
      easyvis_device_id: deviceId,
      media_id: mediaId,
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const downloadToDevice = (mediaId: string, deviceId: string) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: DOWNLOAD_MEDIA_REQUEST });

  return (
    downloadToDeviceApi(mediaId, deviceId).then((response) => {
      console.log(
        "Download Media successful, fetching all media again",
        response
      );
      dispatch({ response, type: DOWNLOAD_MEDIA_SUCCESS, mediaId: mediaId });
    }),
    (error: any) => {
      console.log("Error happened during download Media");
      dispatch({ error, type: DOWNLOAD_MEDIA_FAILURE });
    }
  );
};
