import { Auth } from "aws-amplify";

export const configDev = {
  aws_project_region: "eu-central-1",
  Auth: {
    identityPoolId: "eu-central-1:79993bc3-195d-4749-b635-9e11e1ddb535",
    region: "eu-central-1",
    userPoolId: "eu-central-1_mLgfz0WZV",
    userPoolWebClientId: "34ekpjrif8896f2vqeq586oq7f",
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "MagnwallAPI",
        endpoint:
          "https://0axrpcyw9i.execute-api.eu-central-1.amazonaws.com/dev",
        region: "eu-central-1",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getAccessToken()
              .getJwtToken(),
          };
        },
      },
      {
        name: "MagnwallMediaAPI",
        endpoint:
          "https://9ulsdtdf99.execute-api.eu-central-1.amazonaws.com/dev",
        region: "eu-central-1",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getAccessToken()
              .getJwtToken(),
          };
        },
      },
    ],
  },
};

export const configProd = {
  aws_project_region: "eu-central-1",
  Auth: {
    identityPoolId: "eu-central-1:3c30a70e-977b-4470-96c2-933d35be04dd",
    region: "eu-central-1",
    userPoolId: "eu-central-1_phqpjjV2s",
    userPoolWebClientId: "6tvnjm7jee2h6kn8n4nv4fh4dm",
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "MagnwallAPI",
        endpoint:
          "https://o0j71yf6ve.execute-api.eu-central-1.amazonaws.com/prod",
        region: "eu-central-1",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getAccessToken()
              .getJwtToken(),
          };
        },
      },
      {
        name: "MagnwallMediaAPI",
        endpoint:
          "https://0fgmo296j7.execute-api.eu-central-1.amazonaws.com/prod",
        region: "eu-central-1",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getAccessToken()
              .getJwtToken(),
          };
        },
      },
    ],
  },
};
