import { CALL_API, apiCall } from "../../common/api";
import { SceneReduced } from "./Scenes";
import { getWall, loadingWallOn } from "../wall/WallActions";

export const GET_SCENES_REQUEST = "GET_SCENES_REQUEST";
export const GET_SCENES_FAILURE = "GET_SCENES_FAILURE";
export const GET_SCENES_SUCCESS = "GET_SCENES_SUCCESS";

export const getScenes = () => ({
  [CALL_API]: {
    types: [GET_SCENES_REQUEST, GET_SCENES_SUCCESS, GET_SCENES_FAILURE],
    apiName: "MagnwallAPI",
    path: "/scene",
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const GET_SCENE_REQUEST = "GET_SCENE_REQUEST";
export const GET_SCENE_FAILURE = "GET_SCENE_FAILURE";
export const GET_SCENE_SUCCESS = "GET_SCENE_SUCCESS";

export const getScene = (id: string) => ({
  [CALL_API]: {
    types: [GET_SCENE_REQUEST, GET_SCENE_SUCCESS, GET_SCENE_FAILURE],
    apiName: "MagnwallAPI",
    path: "/scene/" + id,
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const CONTROL_SCENE_REQUEST = "CONTROL_SCENE_REQUEST";
export const CONTROL_SCENE_FAILURE = "CONTROL_SCENE_FAILURE";
export const CONTROL_SCENE_SUCCESS = "CONTROL_SCENE_SUCCESS";

const controlSceneAPI = (id: number) => {
  let apiName = "MagnwallAPI";
  let path = "/scene/control?scene_id=" + id;
  let myInit = {
    headers: {},
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const controlSceneAndUpdateWall = (sceneId: number, wallId: number) => (
  dispatch: any,
  getState: any
) => {
  /*
    This will set loading state of wall to true when controlling scene
  */
  dispatch(loadingWallOn());
  dispatch({ type: CONTROL_SCENE_REQUEST });
  return (
    controlSceneAPI(sceneId).then((response) => {
      console.log("Controlling of the scene successful, fetching wall again");
      dispatch({ response, type: CONTROL_SCENE_SUCCESS, sceneId: sceneId });
      dispatch(getWall(String(wallId)));
    }),
    (error: any) => {
      console.log("Error happened during control scene");
      dispatch({ error, type: CONTROL_SCENE_FAILURE });
    }
  );
};

export const CREATE_SCENE_REQUEST = "CREATE_SCENE_REQUEST";
export const CREATE_SCENE_FAILURE = "CREATE_SCENE_FAILURE";
export const CREATE_SCENE_SUCCESS = "CREATE_SCENE_SUCCESS";

const createSceneAPI = (sceneReduced: SceneReduced) => {
  let apiName = "MagnwallAPI";
  let path = "/scene";
  let myInit = {
    headers: {},
    body: {
      name: sceneReduced.name,
      scene_accessory: sceneReduced.scene_accessories_config,
      scene_schedules: sceneReduced.scene_schedules,
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const createScene = (sceneReduced: SceneReduced, wallId: number) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: CREATE_SCENE_REQUEST });
  return (
    createSceneAPI(sceneReduced).then((response) => {
      console.log("create scene successful");
      dispatch(controlSceneAndUpdateWall(response.data.id, wallId));
      dispatch(getScenes());
      dispatch({ response, type: CREATE_SCENE_SUCCESS });
    }),
    (error: any) => {
      console.log("Error happened during create scenes");
      dispatch({ error, type: CREATE_SCENE_FAILURE });
    }
  );
};

export const UPDATE_SCENE_REQUEST = "UPDATE_SCENE_REQUEST";
export const UPDATE_SCENE_FAILURE = "UPDATE_SCENE_FAILURE";
export const UPDATE_SCENE_SUCCESS = "UPDATE_SCENE_SUCCESS";

const updateSceneAPI = (sceneReduced: SceneReduced) => {
  let apiName = "MagnwallAPI";
  let path = "/scene/" + sceneReduced.id;
  let myInit = {
    headers: {},
    body: {
      name: sceneReduced.name,
      scene_accessory: sceneReduced.scene_accessories_config,
      scene_schedules: sceneReduced.scene_schedules,
    },
    response: true,
  };
  return apiCall("put", apiName, path, myInit);
};

export const updateScene = (sceneReduced: SceneReduced, wallId: number) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: UPDATE_SCENE_REQUEST });
  return (
    updateSceneAPI(sceneReduced).then((response) => {
      console.log("Update scene successful");
      dispatch(controlSceneAndUpdateWall(sceneReduced.id, wallId));
      dispatch({ response, type: UPDATE_SCENE_SUCCESS });
    }),
    (error: any) => {
      console.log("Error happened during update scenes");
      dispatch({ error, type: UPDATE_SCENE_FAILURE });
    }
  );
};

export const DELETE_SCENE_REQUEST = "DELETE_SCENE_REQUEST";
export const DELETE_SCENE_FAILURE = "DELETE_SCENE_FAILURE";
export const DELETE_SCENE_SUCCESS = "DELETE_SCENE_SUCCESS";

const deleteSceneAPI = (id: number) => {
  let apiName = "MagnwallAPI";
  let path = "/scene/" + id;
  let myInit = {
    headers: {},
    response: true,
  };
  return apiCall("delete", apiName, path, myInit);
};

export const deleteScene = (id: number) => (dispatch: any, getState: any) => {
  dispatch({ type: DELETE_SCENE_REQUEST });
  return (
    deleteSceneAPI(id).then((response) => {
      console.log("Delete scene successful, fetching scenes again");
      dispatch(getScenes());
      dispatch({ response, type: DELETE_SCENE_SUCCESS, sceneId: id });
    }),
    (error: any) => {
      console.log("Error happened during delete scenes");
      dispatch({ error, type: DELETE_SCENE_FAILURE });
    }
  );
};
