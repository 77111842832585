import React from "react";
import "./CreateSceneAccessories.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import { IonRow, IonCol } from "@ionic/react";
import Typography from "../../components/common/Typography";
import { Accessory } from "../../data/wall/Walls";
import AccessoryList from "../accessorylist/AccessoryList";

interface CreateSceneAccessoriesProps {
  accessories: Accessory[];
}
type Props = WithTranslation & CreateSceneAccessoriesProps;

const CreateSceneAccessories: React.FC<Props> = (props) => {
  const { accessories, t } = props;

  return (
    <div id="createSceneAccessories">
      <IonRow>
        <IonCol
          offset-sm="0"
          size-sm="12"
          offset-md="1"
          offset-lg="2"
          size-md="8"
        >
          <Typography
            variant="title3"
            color="white96"
            className="accessoriesTitle"
          >
            {t("accessories")}
          </Typography>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol
          offset-sm="0"
          size-sm="12"
          offset-md="1"
          offset-lg="2"
          size-md="8"
        >
          <Typography
            variant="caption1"
            color="white64"
            className="accessoriesTitleDesc"
          >
            {t("create-scene-accessories-desc")}
          </Typography>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol
          offset-sm="2"
          size-sm="8"
          size-md="10"
          size-lg="8"
          offset-md="1"
          offset-lg="2"
        >
          {accessories.length > 0 ? (
            <AccessoryList
              accessories={accessories}
              showGrouping={false}
              showAnimation={true}
            />
          ) : (
            <div>noAccessories</div>
          )}
        </IonCol>
      </IonRow>
    </div>
  );
};

export default withTranslation()(CreateSceneAccessories);
