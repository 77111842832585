import React, { useState, useEffect } from "react";
import { IonContent, IonHeader } from "@ionic/react";
import "./Media.scss";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import Toolbar from "../common/Toolbar";
import { ToolbarContents } from "../common/Toolbar";
import MediaFilesList from "./MediaFilesList";
import MediaFileUploader from "./MediaFileUploader";
import { Accessory } from "../../data/wall/Walls";

interface MediaProps {
  mediaType: string;
  closeModal(): void;
  onSelect(selectedMedia: any, local: boolean): void;
  accessory: Accessory;
}
const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => ({});

type Props = WithTranslation &
  MediaProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Media: React.FC<Props> = (props) => {
  const { mediaType, closeModal, onSelect, accessory, t } = props;
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [refreshTime, setRefreshTime] = useState(0);
  const [selectedMediaType /* setSelectedMediaType */] = useState(mediaType);

  //Handle the image change selection
  /*  const handleSelectedMediaType = (selectedValue: any) => {
    setSelectedMediaType(selectedValue);
  };

   const getToolbarHtmlContents = () => {
    return (
      <IonSegment
        className="ionSegment"
        value={selectedMediaType}
        onIonChange={(e) => handleSelectedMediaType(e.detail.value)}
      >
        <IonSegmentButton value="image">
          <Typography variant="body2" color="white96">
            Images
          </Typography>
        </IonSegmentButton>
        <IonSegmentButton value="video">
          <Typography variant="body2" color="white96">
            Videos
          </Typography>
        </IonSegmentButton>
      </IonSegment>
    );
  }; */

  const toolbarContents: ToolbarContents = {
    left: {
      on_click: () => closeModal(),
      label: t("cancel"),
      show_icon: false,
    },
    /* middle: {
      show_html: true,
      html_content: getToolbarHtmlContents(),
      show_image: false,
    }, */
  };

  const handleSelectMedia = (media: any, local: boolean) => {
    onSelect(media, local);
  };

  const refresh = (time: number) => {
    setRefreshTime(time);
  };

  useEffect(() => {
    setRefreshTime(new Date().getTime());
  }, [selectedMediaType]);

  useEffect(() => {
    console.log(`Refreshing media list: ${isRefreshed}`);
    setIsRefreshed(true);
  }, [refreshTime]);

  return (
    <div id="MediaView">
      <IonHeader className="ion-no-border ionHeader">
        <Toolbar variant="toolbar" contents={toolbarContents} />
      </IonHeader>
      <MediaFileUploader uploadMediaType={mediaType} onComplete={refresh} />
      <IonContent className="mediaIonContent">
        <div className="ionContent">
          <MediaFilesList
            mediaToDisplay={selectedMediaType}
            refreshTime={refreshTime}
            onSelectMedia={handleSelectMedia}
            accessory={accessory}
          />
        </div>
      </IonContent>
    </div>
  );
};

export default withTranslation()(connect(mapStateToProps, null)(Media));
